import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const ClinicCard = styled.div`
  display: flex;
  align-items: center;
  height: 452px;
  width: 324px;
  margin: 0px 16px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const InfoCard = styled.div`
  display: flex;
  align-items: left;
  height: 205px;
  width: 663px;
  margin: 0px 0px 24px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const AdminInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 223px;
  width: 663px;
  margin: 0px 0px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const LogoWrapper = styled.div``;

export const LogoCard = styled.div`
  height: 197px;
  width: 270px;
  padding: ${({ p }) => p || "27px 0px 27px 0px"};
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.white} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: 22px;
  opacity: 1;
`;
