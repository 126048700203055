import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Col, Row, Text } from "../../../../styles/Common";
import { ProfileImg, InfoCard, IconBackground } from "./styles";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import {
  ClinicIcon,
  EmailOpenedIcon,
  PhoneIcon,
  UserIcon,
} from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import {
  formatPhoneNumber,
  formattedDate,
} from "../../../../utilities/helpers";

const ModalUser = ({ showModal, handleCloseModal, actualInfo }) => {
  const navigate = useNavigate();
  return (
    <Modal open={showModal.show} onCancel={handleCloseModal} width={"407px"}>
      <Modal.Header />
      <Modal.Body
        margin="18px 33px 24px 33px"
        maxHeight={"651px"}
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" gap={"17px"}>
            {actualInfo?.user?.image ? (
              <ProfileImg
                key={actualInfo.user.image}
                background={`${
                  actualInfo?.user?.image
                }?${new Date().getTime()}`}
              />
            ) : (
              <ProfileImg>
                <UserIcon
                  stroke={theme.colors.white}
                  width={"40px"}
                  height={"40px"}
                />
              </ProfileImg>
            )}

            <Col align="left" width="150px">
              <Text
                size={theme.fonts.size.h4}
                weight={theme.fonts.weight.bold}
                color={theme.colors.green}
                style={{ textTransform: "capitalize" }}
              >
                {actualInfo.user.name} {actualInfo.user.lastname}
              </Text>
              <Text
                size={theme.fonts.size.xs}
                weight={theme.fonts.weight.light}
                color={theme.colors.gray700}
              >
                Creation date: {formattedDate(actualInfo.user.createdAt)}
              </Text>
            </Col>
          </Row>
          <Row width="100%" p="23px 0px 10px 0px">
            <InfoCard>
              <IconBackground>
                <ClinicIcon
                  stroke={theme.colors.gray50}
                  width={"30px"}
                  height={"30px"}
                />
              </IconBackground>
              <Col align="left" width="200px">
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.green}
                  style={{ textTransform: "capitalize" }}
                >
                  Clinic
                </Text>
                <Text
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.regular}
                  color={theme.colors.gray800}
                  style={{ "white-space": "nowrap" }}
                >
                  {actualInfo.clinic.name}
                </Text>
              </Col>
            </InfoCard>
          </Row>
          <Row width="100%" p="0px 0px 10px 0px">
            <InfoCard>
              <IconBackground>
                <EmailOpenedIcon
                  stroke={theme.colors.gray50}
                  width={"30px"}
                  height={"30px"}
                />
              </IconBackground>
              <Col align="left" width="200px">
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.green}
                >
                  Email
                </Text>
                <Text
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.regular}
                  color={theme.colors.gray800}
                >
                  {actualInfo.email}
                </Text>
              </Col>
            </InfoCard>
          </Row>
          <Row width="100%" p="0px 0px 32px 0px">
            <InfoCard>
              <IconBackground>
                <PhoneIcon
                  stroke={theme.colors.gray50}
                  width={"30px"}
                  height={"30px"}
                />
              </IconBackground>
              <Col align="left" width="100%">
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.green}
                >
                  Phone Number
                </Text>
                <Text
                  size={theme.fonts.size.h6}
                  weight={theme.fonts.weight.regular}
                  color={theme.colors.gray800}
                >
                  {actualInfo.user.callingCode}{" "}
                  {formatPhoneNumber(actualInfo.user.phone)}
                </Text>
              </Col>
            </InfoCard>
          </Row>
          <Row width="100%" p="0px 0px 16px 0px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={() => navigate("edit-user")}
              width="100%"
              background={theme.colors.green}
            >
              Edit User
            </Button>
          </Row>
          <Row width="100%">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              color={theme.colors.green}
              background={theme.colors.green100}
              border={theme.colors.green}
              onClick={() => console.log("delete")}
              width="100%"
            >
              Delete user
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalUser;
