import React from "react";
import { CustomModal } from "./styles";

import { ModalHeader } from "./Header";
import { ModalBody } from "./Body";
import { ModalFooter } from "./Footer";

export const Modal = ({
  children,
  open,
  onCancel,
  padding,
  align,
  width,
  radius,
  ...rest
}) => {
  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      padding={padding}
      align={align}
      width={width}
      radius={radius}
      footer={[]}
      {...rest}
    >
      {children}
    </CustomModal>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
