import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetQuotesByPatient = (patientId, options) => {
  const data = useQuery(
    ["QuotesByPatiend", patientId],
    () => axiosClient.get(`/quotes/patient/${patientId}`),
    options
  );

  return data || {};
};
export default useGetQuotesByPatient;
