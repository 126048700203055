import styled from "styled-components";
import { theme } from "../../styles/theme";

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 293px;
  width: 1004px;
  padding: 23px 29px 33px 29px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const LogoCard = styled.div`
  height: 107px;
  width: 107px;
  text-align: center;
  padding: ${({ p }) => p || "27px 0px 27px 0px"};
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray700} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  opacity: 1;
`;

export const ShadowCard = styled.div`
  display: flex;
  align-items: center;
  min-height: 89px;
  width: 294px;
  padding: 19px 16px 19px 16px;
  background: ${theme.colors.gray700} 0% 0% no-repeat padding-box;
  border-radius: 14px;
  border: 1px solid ${theme.colors.gray750};
  opacity: 1;
`;

export const QuotingCard = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0 0 0;
  padding: 15px;
  min-height: 286px;
  width: 1004px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 257px;
  width: 324px;
  padding: 25px;
  background: ${theme.colors.gray600} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
  align-items: center;
  padding: 0 14px 0 14px;
  width: 614px;
  height: 60px;
  background:  ${({ background }) =>
    background || theme.colors.gray600}; 0% 0% no-repeat padding-box;
  border-radius: 11px;
`;
