import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useGetOpeners = (clinicId) => {
  const data = useQuery(
    ["getOpeners", clinicId],
    () => clinicId && axiosClient.get(`/users/${clinicId}`)
  );
  return data;
};
export default useGetOpeners;
