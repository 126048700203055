import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

export const CustomInputPassword = styled(Input.Password)`
  font-family: ${theme.fonts.family};
  width: ${({ width }) => width || null};
  height: 50px;
  border: 1px solid ${({ border }) => border || theme.colors.gray500};
  background: ${({ background }) => background || theme.colors.white};
  border-radius: 25px;
  opacity: 1;

  && {
    &.ant-input-affix-wrapper {
      .ant-input {
        background: ${({ background }) => background || theme.colors.white};
        color: ${({ color }) => color || theme.colors.gray950};
        font-size: 16px;
        size: 16px;

        &::placeholder {
          color: ${theme.colors.gray600};
        }
      }

      &-focused {
        border: 1px solid ${theme.colors.gray400};
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${theme.colors.gray500};
      }

      .anticon.ant-input-password-icon {
        color: ${theme.colors.gray400};

        svg {
          width: 22px;
          height: 18px;
        }
      }

      .anticon.ant-input-password-icon {
        &:hover {
          color: ${theme.colors.gray400};
        }
      }
    }
  }
`;
