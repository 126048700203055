import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "packages",
  initialState: {
    selectedPackage: {},
  },
  reducers: {
    setSelectedPackage: (state, action) => {
      return {
        ...state,
        selectedPackage: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setSelectedPackage } = slice.actions;

export const setSelectedPackageInformation = (data) => async (dispatch) => {
  dispatch(setSelectedPackage(data));
};
