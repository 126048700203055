import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import common from "./common";
import clinics from "./clinics";
import users from "./users";
import patients from "./patients";
import quotations from "./quotations";
import packages from "./packages";

const reducer = combineReducers({
  common,
  clinics,
  users,
  patients,
  quotations,
  packages,
});

export const store = configureStore({
  reducer,
});
