import { useEffect, useMemo } from "react";
import { theme } from "../../../../styles/theme";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import { Text, Row, Col, Div } from "../../../../styles/Common";
import _ from "lodash";
import { DownloadIcon } from "../../../../assets/icons";
import { GraphTooltip } from "../GraphTooltip";
import { formatPriceToDollar } from "../../../../utilities/helpers";

export const Taxes = ({ taxesList, groupedTaxesList, selectedReportTime }) => {
  const summedData = useMemo(() => {
    return _.map(groupedTaxesList, (group, idx) => ({
      date:
        selectedReportTime.value === 7
          ? `D${idx + 1}`
          : selectedReportTime.value === 365
          ? `M${idx + 1}`
          : `W${idx + 1}`,
      tax: _.sumBy(group, "value"),
    }));
  }, [groupedTaxesList, selectedReportTime]);

  useEffect(() => {}, [groupedTaxesList, selectedReportTime]);

  return (
    <Div
      height="169px"
      width="494px"
      m="0 0 25px 0"
      radius="20px"
      background={theme.colors.gray950}
      direction="column"
    >
      <Row m="21px 21px 0 21px" justify="space-between" align="center">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          Taxes Report
        </Text>
        <Div
          radius="50%"
          background="rgba(255, 255, 255, 0.2)"
          height="45px"
          width="45px"
          justify="center"
          align="center"
        >
          <DownloadIcon />
        </Div>
      </Row>
      <Row m="0 21px" gap="37px" justify="space-between" align="center">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h2}
          color={theme.colors.green}
        >
          {formatPriceToDollar(_.sumBy(taxesList, "value"))}
        </Text>
        <BarChart
          width={250}
          height={100}
          data={summedData}
          margin={{ top: 0, right: 25, left: 20, bottom: 0 }}
          barGap={3}
        >
          <Tooltip
            cursor={{ fill: theme.colors.green100 }}
            content={<GraphTooltip />}
          />
          <Bar
            dataKey="tax"
            fill={theme.colors.green}
            name="Taxes"
            radius={8}
            barSize={30}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tick={{ fontSize: theme.fonts.size.sm, fill: theme.colors.white }}
          />
        </BarChart>
      </Row>
    </Div>
  );
};
