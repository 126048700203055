import toast from "react-hot-toast";
import { Col, Container, Row, Text } from "../../../../../styles/Common";
import { Modal } from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { Input } from "../../../../../components/Input";
import { theme } from "../../../../../styles/theme";
import { MoneyIcon } from "../../../../../assets/icons";

const ModalAddAmount = ({ showModalAmount, setShowModalAmount }) => {
  const handleClose = () => {
    setShowModalAmount(false);
  };

  return (
    <Container>
      <Modal open={showModalAmount} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={<MoneyIcon stroke={theme.colors.green} />}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          title={"Add Amount"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="15px 0 24px 0"
          maxHeight={454}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" m="0 0 24px 0" p="0 27px 0px 27px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                When you make a payment, it will not be possible to edit the
                quote. To continue add the amount
              </Text>
            </Row>
            <Row m="17px 0px 24px 0px">
              <Input
                label={"Amount"}
                id="amount"
                type="Number"
                prefix={"$"}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray400}
                background={theme.colors.white}
                color={theme.colors.gray600}
                width={"246px"}
              />
            </Row>
            <Row width="100%" justify="center" m="0 0 16px 0">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={() => console.log("add amount")}
                width="226px"
                background={theme.colors.green}
              >
                Add amount
              </Button>
            </Row>
            <Row width="100%" justify="center">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                color={theme.colors.green}
                background={theme.colors.green100}
                border={theme.colors.green}
                onClick={handleClose}
                width="226px"
              >
                Cancel
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ModalAddAmount;
