import styled from "styled-components";
import { theme } from "../../styles/theme";

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 116px;
  width: 1004px;
  padding: 20px 24px 20px 24px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
`;

export const SalesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 360px;
  width: 494px;
  padding: 30px 20px 25px 20px;
  margin: 0 0 24px 0;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px 0 14px;
  width: 442px;
  height: 41px;
  background:  ${({ background }) =>
    background || theme.colors.gray600}; 0% 0% no-repeat padding-box;
  border-radius: 11px;
`;
