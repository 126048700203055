import React from "react";
import { theme } from "../../../../../styles/theme";
import { Col, Row, Text } from "../../../../../styles/Common";
import { Input } from "../../../../../components/Input";
import { Select } from "../../../../../components/Select";
import { isSuperAdmin, userClinic } from "../../../../../utilities/helpers";

const FormInfoCard = ({
  handleChangeUser,
  errorForm,
  handleSelect,
  userForm,
  optionsClinics,
}) => {
  const superAdmin = isSuperAdmin();
  const { name: userClinicName } = userClinic();

  return (
    <Col m="31px 28px 0px 40px" width="100%">
      <Row align="center" m="0px 0px 12px 0px">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          More Information
        </Text>
      </Row>
      <Row align="center" m="0px 0px 15px 0px" justify="space-between">
        {superAdmin ? (
          <Select
            label={"Select Clinic*"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            color={theme.colors.gray400}
            width={"285px"}
            defaultValue=""
            value={userForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Clinic*"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            color={theme.colors.gray400}
            width={"285px"}
            value={userClinicName}
            disabled
          />
        )}
        <Input
          label={"Email*"}
          id="email"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"285px"}
          value={userForm.email}
          onChange={handleChangeUser}
          error={errorForm.email.error}
          helper={errorForm.email.error ? errorForm.email.message : ""}
          disabled
        />
      </Row>
      <Row align="center" m="0px 0px 15px 0px" justify="space-between">
        <Input
          label={"Phone number"}
          id="phone"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"285px"}
          value={userForm.phone}
          onChange={handleChangeUser}
          error={errorForm.phone.error}
          helper={errorForm.phone.error ? errorForm.phone.message : ""}
        />
        <Input
          label={"Address"}
          id="address"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"285px"}
          autoComplete="off"
          value={userForm.address}
          onChange={handleChangeUser}
          error={errorForm.address.error}
          helper={errorForm.address.error ? errorForm.address.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 33px 0px" gap="120px">
        <Input
          label={"Product's commission"}
          id="productCommission"
          suffix={"%"}
          type="Number"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"180px"}
          value={userForm.productCommission}
          onChange={handleChangeUser}
          error={errorForm.productCommission.error}
          helper={
            errorForm.productCommission.error
              ? errorForm.productCommission.message
              : ""
          }
        />
        <Input
          label={"Treatment's comission"}
          id="treatmentCommission"
          suffix={"%"}
          type="Number"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"180px"}
          autoComplete="off"
          value={userForm.treatmentCommission}
          onChange={handleChangeUser}
          error={errorForm.treatmentCommission.error}
          helper={
            errorForm.treatmentCommission.error
              ? errorForm.treatmentCommission.message
              : ""
          }
        />
      </Row>
    </Col>
  );
};

export default FormInfoCard;
