import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { Button } from "../../../components/Button";
import {
  ClinicIcon,
  ChevronLeftIcon,
  WebsiteIcon,
  IdIcon,
  PinIcon,
  PhoneIcon,
  TaxesIcon,
  UserIcon,
  EmailOpenedIcon,
  EditIcon,
} from "../../../assets/icons";
import { theme } from "../../../styles/theme";
import {
  AdminInfoCard,
  ClinicCard,
  InfoCard,
  LogoCard,
  LogoWrapper,
} from "./styles";
import { useSelector } from "react-redux";
import { formatPhoneNumber, formattedDate } from "../../../utilities/helpers";

export const Clinic = () => {
  const navigate = useNavigate();
  const { clinics } = useSelector((state) => state);

  const clinic = clinics?.selectedClinic?.clinic || {};
  const user = clinics?.selectedClinic?.user || {};

  useEffect(() => {
    if (!clinics?.selectedClinic?.clinic) {
      navigate("/clinics");
    }
  }, [clinics?.selectedClinic]);

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Row height="18px" align="center" m="0px 0px 37px 0px">
        <Link
          onClick={() => navigate(-1)}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ gap: "5px" }}
        >
          <ChevronLeftIcon stroke={theme.colors.green} />
          Go back
        </Link>
      </Row>

      <Row
        height="40px"
        align="center"
        m="0px 0px 37px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
          width="881px"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
            style={{ "white-space": "nowrap" }}
          >
            {clinic.name}
          </Text>
        </Div>
        <Col align="right">
          <Text
            weight={theme.fonts.weight.light}
            size={theme.fonts.size.default}
            color={theme.colors.gray400}
          >
            Creation Date
          </Text>
          <Text
            weight={theme.fonts.weight.light}
            size={theme.fonts.size.default}
            color={theme.colors.gray400}
          >
            {formattedDate(clinic.createdAt)}
          </Text>
        </Col>
      </Row>

      <Row align="center" gap="16px" m="0px 0px 28px 0px" justify="center">
        <ClinicCard>
          <Col m="0px 28px 0px 28px">
            <Row align="center" m="32px 0px 32px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Clinic Information
              </Text>
            </Row>
            <Row
              align="center"
              m="0px 0px 40px 0px"
              style={{ justifyContent: "center" }}
            >
              <LogoWrapper>
                {clinic.image ? (
                  <LogoCard background={clinic.image} />
                ) : (
                  <LogoCard p={"60px 0px 0px 0px"}>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray500}
                    >
                      {clinic.name}
                    </Text>
                  </LogoCard>
                )}
              </LogoWrapper>
            </Row>

            <Row align="center" m="0px 0px 3px 0px" gap={"6px"}>
              <ClinicIcon stroke={theme.colors.green} />
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.sm}
                color={theme.colors.green}
              >
                Clinic Name
              </Text>
            </Row>
            <Row align="center" m="0px 0px 16px 0px" width="270px">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h6}
                color={theme.colors.white}
                align={"left"}
                style={{ textTransform: "capitalize", "white-space": "nowrap" }}
              >
                {clinic.name}
              </Text>
            </Row>
            <Row align="center" m="0px 0px 3px 0px" gap={"6px"}>
              <WebsiteIcon stroke={theme.colors.green} />
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.sm}
                color={theme.colors.green}
              >
                URL Website
              </Text>
            </Row>
            <Row align="center" m="0px 0px 21px 0px" width="270px">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h6}
                color={theme.colors.white}
                align={"left"}
                style={{ textTransform: "capitalize", "white-space": "nowrap" }}
              >
                {clinic.website ? clinic.website : "----"}
              </Text>
            </Row>
          </Col>
        </ClinicCard>
        <Col width="100%">
          <InfoCard>
            <Row m="30px 30px 30px 30px" width="100%">
              <Col m="0px 0px 0px 0px" width="50%">
                <Row align="center" m="0px 0px 5px 0px" gap={"6px"}>
                  <IdIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    EIN
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 32px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                  >
                    {clinic.ein ? clinic.ein : "----"}
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 3px 0px" gap={"6px"}>
                  <PinIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Clinic Address
                  </Text>
                </Row>
                <Row align="center" m="0px 10px 21px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                    style={{ "white-space": "nowrap" }}
                  >
                    {clinic.address}
                  </Text>
                </Row>
              </Col>

              <Col m="0px 0px 0px 0px">
                <Row align="center" m="0px 0px 5px 0px" gap={"6px"}>
                  <PhoneIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Phone Number
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 32px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                  >
                    {formatPhoneNumber(clinic.phone)}
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 3px 0px" gap={"6px"}>
                  <TaxesIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Taxes
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 21px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                  >
                    {clinic.taxes} %
                  </Text>
                </Row>
              </Col>
            </Row>
          </InfoCard>
          <AdminInfoCard>
            <Row align="center" m="20px 0px 30px 30px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Admin Information
              </Text>
            </Row>
            <Row m="0px 30px 30px 30px" width="100%">
              <Col m="0px 0px 21px 0px" width="50%">
                <Row align="center" m="0px 0px 5px 0px" gap={"6px"}>
                  <UserIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Full Name
                  </Text>
                </Row>
                <Row align="center" m="0px 10px 32px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                    style={{
                      textTransform: "capitalize",
                      "white-space": "nowrap",
                    }}
                  >
                    {user.name} {user.lastName}
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 3px 0px" gap={"6px"}>
                  <EmailOpenedIcon
                    stroke={theme.colors.green}
                    width={"20px"}
                    height={"20px"}
                  />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Email
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 21px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                  >
                    {clinics?.selectedClinic.email}
                  </Text>
                </Row>
              </Col>

              <Col m="0px 0px 0px 0px">
                <Row align="center" m="0px 0px 5px 0px" gap={"6px"}>
                  <PhoneIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Phone Number
                  </Text>
                </Row>
                <Row align="center" m="0px 0px 32px 0px">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h6}
                    color={theme.colors.white}
                    align={"left"}
                  >
                    {formatPhoneNumber(user.phone)}
                  </Text>
                </Row>
              </Col>
            </Row>
          </AdminInfoCard>
        </Col>
      </Row>

      <Row align="center" m="0px 0px 37px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.white}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={() => console.log("delete")}
          width="190px"
          m="0 24px 0 0"
        >
          Delete Clinic
        </Button>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="204px"
          background={theme.colors.green}
          onClick={() => navigate("/clinics/edit-clinic")}
        >
          <Row align="center" m="0px 14px 0px 14px" gap={"6px"}>
            <EditIcon stroke={theme.colors.white} />
            Edit Information
          </Row>
        </Button>
      </Row>
    </Container>
  );
};
