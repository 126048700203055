import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useSendEmailResetPassword = () => {
  const mutation = useMutation((email) => {
    return axiosClient.get(`/reset-password?email=${email}`);
  });
  return mutation;
};
export default useSendEmailResetPassword;
