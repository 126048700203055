import React, { useState } from "react";
import { Text } from "../../styles/Common";
import { CustomSelect, StyledOption } from "./styles";
import { theme } from "../../styles/theme";
import { SearchIcon, ChevronDownIcon } from "../../assets/icons";

export const Select = ({
  label,
  options,
  defaultValue,
  placeholder,
  helper,
  noborder,
  paddingx,
  showSearch,
  notFoundContent,
  filterOption,
  optionFilterProp,
  optionLabelProp,
  error,
  disabled,
  onChange,
  onSearch,
  height,
  weightLabel,
  sizeLabel,
  allowClear,
  colorLabel,
  colorIcon,
  suffixIcon,
  ...rest
}) => {
  const [iconName, setIconName] = useState(
    <ChevronDownIcon stroke={colorIcon || theme.colors.gray400} />
  );

  const iconToShow = showSearch ? (
    <SearchIcon stroke={colorIcon || theme.colors.gray400} />
  ) : (
    <ChevronDownIcon stroke={colorIcon || theme.colors.gray400} />
  );

  const handleFocusOnSearch = () =>
    showSearch &&
    setIconName(<SearchIcon stroke={colorIcon || theme.colors.gray400} />);
  const handleBlurOnSearch = () =>
    showSearch &&
    setIconName(<ChevronDownIcon stroke={colorIcon || theme.colors.gray400} />);

  return (
    <div>
      {label && label !== "" && (
        <Text
          weight={weightLabel}
          size={sizeLabel || theme.fonts.size.sm}
          color={colorLabel || theme.colors.gray500}
          mt="0px"
          mb="7px"
          ml="6px"
          align="left"
        >
          {label}
        </Text>
      )}
      <CustomSelect
        options={options}
        status={error ? "error" : ""}
        defaultValue={defaultValue}
        placeholder={placeholder}
        suffixIcon={suffixIcon || iconToShow}
        noborder={noborder}
        paddingx={paddingx}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        disabled={disabled ? 1 : 0}
        onChange={onChange}
        onSearch={onSearch}
        height={height}
        onFocus={handleFocusOnSearch}
        onBlur={handleBlurOnSearch}
        allowClear={allowClear}
        dropdownStyle={{
          borderRadius: "14px",
        }}
        {...rest}
      />

      {helper && (
        <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
