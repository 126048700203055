import styled from "styled-components";
import { theme } from "../../styles/theme";

export const StatusCol = styled.div`
  display: flex;
  padding: 8px;
  width: 137px;
  height: 32px;
  background: ${({ background }) => background || theme.colors.gray100};
  background-position: center;
  justify-content: center;
  border-radius: 2px;
  color: ${theme.colors.white};
  font-size: ${theme.fonts.size.xs};
  font-weight: ${theme.fonts.weight.bold};
  text-transform: uppercase;
`;

export const LogoCard = styled.div`
  height: 348px;
  width: 488;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray700} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: 22px;
  opacity: 1;
  cursor: pointer;
`;
