import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { orderBy } from "lodash";
import { Row, Text, Div } from "../../../../styles/Common";
import { Drawer } from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Radio } from "../../../../components/Radio";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { CloseIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import {
  isAdmin,
  isAccountOwner,
  isSuperAdmin,
  userClinic,
} from "../../../../utilities/helpers";
import { validateProductTreatmentForm } from "../../../../utilities/validations";
import useCreateTreatment from "../../hooks/useCreateTreatment";
import useEditTreatment from "../../hooks/useEditTreatment";

const DrawerTreatment = ({
  showDrawer,
  closeDrawer,
  handleCloseDrawerSuccess,
  actualInfo,
  optionsClinics,
  optionsCategories,
}) => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const { name: userClinicName, id: userClinicId } = userClinic();

  const createTreatment = useCreateTreatment();
  const editTreatment = useEditTreatment();
  const initialErrors = {
    name: {
      error: false,
      message: "",
    },
    categoryId: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    staffPrice: {
      error: false,
      message: "",
    },
    cost: {
      error: false,
      message: "",
    },
    bonusType: {
      error: false,
      message: "",
    },
    bonusValue: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    sku: {
      error: false,
      message: "",
    },
  };

  const [forceUpdate, setForceUpdate] = useState(false);
  const [optionsCategoriesFiltered, setOptionsCategoriesFiltered] = useState(
    []
  );
  const [treatmentForm, setTreatmentForm] = useState({
    type: "treatment",
    clinic: actualInfo?.clinicId?.toString(),
    categoryId: actualInfo.categoryId?.toString(),
    name: actualInfo.name,
    price: actualInfo.price,
    staffPrice: actualInfo.staffPrice,
    cost: actualInfo.cost,
    bonusType: actualInfo.bonusType,
    bonusValue: actualInfo.bonusValue,
    isTaxIncluded: false,
    sku: "-",
  });
  const [errorForm, setErrorForm] = useState(initialErrors);

  useEffect(() => {
    setTreatmentForm({
      type: "treatment",
      clinic: actualInfo?.clinicId?.toString(),
      categoryId: actualInfo.categoryId?.toString(),
      name: actualInfo.name,
      price: actualInfo.price,
      staffPrice: actualInfo.staffPrice,
      cost: actualInfo.cost,
      bonusType: actualInfo.bonusType,
      bonusValue: actualInfo.bonusValue,
      isTaxIncluded: false,
      sku: "-",
    });
  }, [actualInfo]);

  useEffect(() => {
    if (
      optionsCategories &&
      (treatmentForm.clinic ||
        ((isAdmin() || isAccountOwner()) && userClinicId))
    ) {
      const clinicIdentificator =
        isAdmin() || isAccountOwner() ? userClinicId : treatmentForm.clinic;
      const filterCategory = optionsCategories.filter(
        (cat) =>
          cat.category_type === 1 &&
          Number(cat.clinic.id) === Number(clinicIdentificator)
      );

      setOptionsCategoriesFiltered(
        orderBy(filterCategory, "position", "asc").map((ele) => {
          return {
            value: ele.id,
            label: ele.name,
          };
        })
      );
    }
  }, [optionsCategories, treatmentForm.clinic]);

  const handleChangeTreatment = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newTreatmentForm = treatmentForm;

    newTreatmentForm[id] = value;

    setTreatmentForm(newTreatmentForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newTreatmentForm = treatmentForm;
    newTreatmentForm[id] = value;

    if (id === "clinic") {
      newTreatmentForm.categoryId = null;
    }

    if (id === "categoryId") {
      const clinicIdentificator =
        isAdmin() || isAccountOwner() ? userClinicId : treatmentForm.clinic;
      const filterCategory = optionsCategories.filter(
        (cat) =>
          cat.category_type === 1 &&
          Number(cat.clinic.id) === Number(clinicIdentificator)
      );
      const selectedCategory = filterCategory.find((cat) => cat.id === value);

      newTreatmentForm.bonusType =
        selectedCategory.bonus_type === 1 ? "dollar" : "percentage";
      newTreatmentForm.bonusValue = selectedCategory.bonus_value;
    }

    setTreatmentForm(newTreatmentForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setErrorForm(initialErrors);
  };

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    const newTreatmentForm = treatmentForm;
    if (value === "commission") {
      newTreatmentForm[name] = 0;
    }
    newTreatmentForm[name] = value;
    setTreatmentForm(newTreatmentForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCreateTreatment = () => {
    toast.remove();

    if (!treatmentForm["clinic"]) {
      const newTreatmentForm = treatmentForm;
      newTreatmentForm["clinic"] = userClinicId;
      setTreatmentForm(newTreatmentForm);
    }

    const validation = validateProductTreatmentForm.validate(treatmentForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      if (actualInfo.id) {
        editTreatment.reset();
        editTreatment.mutate(
          {
            id: actualInfo.id,
            type: "treatment",
            clinicId: treatmentForm?.clinic,
            categoryId: treatmentForm.categoryId,
            name: treatmentForm.name,
            price: Number(treatmentForm.price),
            staffPrice: Number(treatmentForm.staffPrice),
            cost: Number(treatmentForm.cost),
            bonusType: treatmentForm.bonusType,
            bonusValue: Number(treatmentForm.bonusValue),
            isTaxIncluded: treatmentForm.isTaxIncluded,
            sku: treatmentForm.sku,
          },
          {
            onSuccess: () => {
              toast.success("The information has been successfully saved");
              handleCloseDrawer();
              queryClient.invalidateQueries(["treatmentList"]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to edit a treatment"
              );
            },
          }
        );
      } else {
        createTreatment.reset();
        createTreatment.mutate(
          {
            type: "treatment",
            clinicId: treatmentForm?.clinic,
            categoryId: treatmentForm.categoryId,
            name: treatmentForm.name,
            price: Number(treatmentForm.price),
            staffPrice: Number(treatmentForm.staffPrice),
            cost: Number(treatmentForm.cost),
            bonusType: treatmentForm.bonusType,
            bonusValue: Number(treatmentForm.bonusValue),
            isTaxIncluded: treatmentForm.isTaxIncluded,
            sku: treatmentForm.sku,
          },
          {
            onSuccess: () => {
              handleCloseDrawerSuccess();
              queryClient.invalidateQueries(["treatmentList", 0, 10]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to create a patient"
              );
            },
          }
        );
      }
    }
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      actualInfo={actualInfo}
    >
      <Div
        style={{ borderLeft: `5px solid ${theme.colors.green}` }}
        p="0px 0px 0px 16px"
        weight="5px"
        height="20px"
        align="center"
        m="6px 0px 0px 0px"
      >
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          {actualInfo.id ? "Edit Treatment" : "New Treatment"}
        </Text>
      </Div>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          {actualInfo.id
            ? "Complete the following information to edit treatment."
            : "Complete the following information to create a new treatment."}
        </Text>
      </Row>
      <Row m="24px 0px 0px 0px">
        {superAdmin ? (
          <Select
            label={actualInfo.id ? "Clinic" : "Select Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            defaultValue=""
            value={treatmentForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            disabled={actualInfo.id ? true : false}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            value={userClinicName}
            disabled
          />
        )}
      </Row>
      <Row m="17px 0px 0px 0px">
        <Select
          label={"Category"}
          id="categoryId"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          defaultValue=""
          value={treatmentForm.categoryId}
          options={optionsCategoriesFiltered}
          onChange={(val) => handleSelect(val, "categoryId")}
          autoComplete="off"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          showSearch
          error={errorForm.categoryId.error}
          helper={
            errorForm.categoryId.error ? errorForm.categoryId.message : ""
          }
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Treatment Name"}
          id="name"
          value={treatmentForm.name}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangeTreatment}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Input
          label={"Price"}
          id="price"
          type="Number"
          prefix="$"
          value={treatmentForm.price}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"100%"}
          onChange={handleChangeTreatment}
          error={errorForm.price.error}
          helper={errorForm.price.error ? errorForm.price.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Input
          label={"Staff Price"}
          id="staffPrice"
          type="Number"
          prefix="$"
          value={treatmentForm.staffPrice}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeTreatment}
          error={errorForm.staffPrice.error}
          helper={
            errorForm.staffPrice.error ? errorForm.staffPrice.message : ""
          }
        />
        <Input
          label={"Treatment Cost"}
          id="cost"
          type="Number"
          prefix="$"
          value={treatmentForm.cost}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeTreatment}
          error={errorForm.cost.error}
          helper={errorForm.cost.error ? errorForm.cost.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Service Provider Bonus
        </Text>
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Radio.Group
          name="bonusType"
          value={treatmentForm.bonusType}
          key={treatmentForm.bonusType}
          onChange={handleRadio}
          gap={"5px"}
          direction="row"
          error={errorForm.bonusType.error}
          helper={errorForm.bonusType.error ? errorForm.bonusType.message : ""}
        >
          <Radio value="dollar" background={theme.colors.white} />
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            Dollar
          </Text>
          <Radio value="percentage" background={theme.colors.white} />
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            Percentage
          </Text>
          <Radio value="commission" background={theme.colors.white} />
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            Commission
          </Text>
        </Radio.Group>
      </Row>
      {treatmentForm.bonusType !== "commission" && (
        <Row m="17px 0px 0px 0px">
          <Input
            label={"Bonus"}
            id="bonusValue"
            type="Number"
            prefix={treatmentForm.bonusType === "dollar" && "$"}
            suffix={treatmentForm.bonusType === "percentage" && "%"}
            value={treatmentForm.bonusValue}
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            onChange={handleChangeTreatment}
            error={errorForm.bonusValue.error}
            helper={
              errorForm.bonusValue.error ? errorForm.bonusValue.message : ""
            }
          />
        </Row>
      )}
      <Row m="59px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="290px"
          background={theme.colors.green}
          onClick={handleCreateTreatment}
          loading={createTreatment.isLoading || editTreatment.isLoading}
        >
          {actualInfo.id ? "Save Information" : "Create Treatment"}
        </Button>
      </Row>
      {actualInfo.id ? (
        <Row m="0px 0px 16px 0px">
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.green}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={handleCloseDrawer}
            width="290px"
          >
            Cancel
          </Button>
        </Row>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default DrawerTreatment;
