import React from "react";
import { CustomTag } from "./styles";

export const Tag = ({
  children,
  background,
  bordered,
  closable,
  closeIcon,
  color,
  icon,
  onClose,
  weight,
  width,
  ...rest
}) => {
  return (
    <CustomTag
      background={background}
      bordered={bordered}
      closable={closable}
      closeIcon={closeIcon}
      color={color}
      icon={icon}
      onClose={onClose}
      weight={weight}
      width={width}
      {...rest}
    >
      {children}
    </CustomTag>
  );
};
