import React from "react";
import { CustomCard } from "./styles";

export const Card = ({ children, width, height, ...rest }) => {
  return (
    <CustomCard width={width} height={height} {...rest}>
      {children}
    </CustomCard>
  );
};
