import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetUsers = ({ page, pageSize, sortDirection, sortBy, searchBy }) => {
  let urlString = `/users?page=${page}&size=${pageSize}`;
  if (searchBy) {
    urlString += `&searchBy=${searchBy}`;
  }

  if (sortDirection && sortDirection.length > 1) {
    urlString += `&orderType=${sortDirection}`;
  }
  if (sortBy && sortBy.length > 1) {
    urlString += `&orderBy=${sortBy}`;
  }
  const data = useQuery(
    ["userList", page, pageSize, sortDirection, sortBy, searchBy],
    () => axiosClient.get(urlString)
  );

  return data || {};
};
export default useGetUsers;
