import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetQuotes = (
  { page, pageSize, sortDirection, sortBy, searchBy, clinicId },
  options
) => {
  let urlString = `/quotes?page=${page}&size=${pageSize}`;
  if (searchBy) {
    urlString += `&searchBy=${searchBy}`;
  }

  if (sortDirection && sortDirection.length > 1) {
    urlString += `&orderType=${sortDirection}`;
  }
  if (sortBy && sortBy.length > 1) {
    urlString += `&orderBy=${sortBy}`;
  }
  if (clinicId) {
    urlString += `&clinicId=${clinicId}`;
  }
  const data = useQuery(
    ["quoteList", page, pageSize, sortDirection, sortBy, searchBy, clinicId],

    () => axiosClient.get(urlString),
    options
  );

  return data || {};
};
export default useGetQuotes;
