import { useState } from "react";
import { Upload } from "antd";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { Password } from "../../../components/Password";
import { Link } from "../../../components/Link";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { AddPhotoIcon, ChevronLeftIcon } from "../../../assets/icons";
import success from "../../../assets/icons/checkuplist.svg";
import { theme } from "../../../styles/theme";
import useCreateClinic from "./hooks/useCreateClinic";
import { ClinicCard, InfoCard, LogoCard } from "./styles";
import {
  convertFileToBase64,
  createURLFromBase64,
} from "../../../utilities/helpers";
import { validateClinicForm } from "../../../utilities/validations";
import { SetUpClinic } from "./components/SetUpClinic";

export const CreateClinic = () => {
  const navigate = useNavigate();
  const createClinic = useCreateClinic();
  const [imageLogo, setImageLogo] = useState(null);
  const [prevImageLogo, setprevImageLogo] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [clinicForm, setclinicForm] = useState({
    clinicName: "",
    ein: null,
    address: "",
    clinicPhone: 0,
    taxes: null,
    website: "",
    ig: "",
    fb: "",
    adminName: "",
    lastname: "",
    adminPhone: 0,
    image: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [quoteImg, setQuoteImg] = useState(null);
  const [prevQuoteImage, setPrevQuoteImage] = useState(null);
  const [setUpClinicForm, setSetUpClinicForm] = useState({
    quoteImage: null,
    brandColor: "#2CBBAF",
    expirationDays: "",
    customerNaming: "",
    consultationFeeName: "",
    consultationFeeType: "",
    consultationFeeValue: null,
    consultationFeeOnbyDefault: false,
    itemizedPricingOnbyDefault: false,
  });

  const handleUploadLogo = async (dataLogo) => {
    const file = dataLogo.file;

    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      toast.error("The image must be .png or .jpg file");
    } else {
      const urlBase64 = await toast.promise(convertFileToBase64(file), {
        loading: "Uploading...",
        success: "Upload completed",
        error: "Upload failed, try again",
      });

      const newClinicForm = {
        ...clinicForm,
        image: `data:image/png;base64,${urlBase64}`,
      };

      setclinicForm(newClinicForm);
      setImageLogo(urlBase64);
      setprevImageLogo(createURLFromBase64(urlBase64, "image/jpeg"));
    }
  };

  const [errorForm, setErrorForm] = useState({
    clinicName: {
      error: false,
      message: "",
    },
    ein: {
      error: false,
      message: "",
    },
    address: {
      error: false,
      message: "",
    },
    clinicPhone: {
      error: false,
      message: "",
    },
    taxes: {
      error: false,
      message: "",
    },
    website: {
      error: false,
      message: "",
    },
    ig: {
      error: false,
      message: "",
    },
    fb: {
      error: false,
      message: "",
    },
    adminName: {
      error: false,
      message: "",
    },
    lastname: {
      error: false,
      message: "",
    },
    adminPhone: {
      error: false,
      message: "",
    },
    image: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
    repeatPassword: {
      error: false,
      message: "",
    },
  });

  const handleChangeClinic = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newClinicForm = clinicForm;

    newClinicForm[id] = value;

    setclinicForm(newClinicForm);
    setForceUpdate(!forceUpdate);
  };

  const isValidColor = (color) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== "";
  };

  const handleCreateClinic = () => {
    toast.remove();
    const validation = validateClinicForm.validate(clinicForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else if (!isValidColor(setUpClinicForm?.brandColor)) {
      setForceUpdate(!forceUpdate);
    } else {
      createClinic.reset();
      createClinic.mutate(
        {
          clinic: {
            name: clinicForm.clinicName,
            ein: clinicForm.ein,
            address: clinicForm.address,
            phone: clinicForm.clinicPhone,
            taxes: clinicForm.taxes,
            image: `data:image/png;base64,${imageLogo}`,
            website: clinicForm.website,
            ig: clinicForm.ig,
            fb: clinicForm.fb,
            quoteImage: `data:image/png;base64,${quoteImg}`,
            brandColor: setUpClinicForm.brandColor,
            expirationDays: Number(setUpClinicForm.expirationDays),
            customerNaming: setUpClinicForm.customerNaming,
            consultationFeeName: setUpClinicForm.consultationFeeName,
            consultationFeeType: setUpClinicForm.consultationFeeType,
            consultationFeeValue: Number(setUpClinicForm.consultationFeeValue),
            consultationFeeOnbyDefault:
              setUpClinicForm.consultationFeeOnbyDefault,
            itemizedPricingOnbyDefault:
              setUpClinicForm.itemizedPricingOnbyDefault,
          },
          user: {
            name: clinicForm.adminName,
            lastname: clinicForm.lastname,
            phone: clinicForm.adminPhone,
          },
          email: clinicForm.email,
          password: clinicForm.password,
        },
        {
          onSuccess: () => {
            setShowModal(true);
          },
          onError: (err) => {
            toast.error(err.response.data.error.message);
          },
        }
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate(-1);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Modal open={showModal} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={success}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 83px 0px 83px"}
          title={"New Clinic has been created"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="15px 0 24px 0"
          maxHeight={200}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0 27px 0px 27px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elitr, set
                diam
              </Text>
            </Row>
            <Row width="100%" p="30px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleClose}
                width="100%"
                background={theme.colors.green}
              >
                View all clinics
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Row height="18px" align="center" m="0px 0px 37px 0px">
        <Link
          onClick={() => navigate(-1)}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ gap: "5px" }}
        >
          <ChevronLeftIcon stroke={theme.colors.green} />
          Go back
        </Link>
      </Row>

      <Row height="40px" align="center" m="0px 0px 37px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Create Clinic
          </Text>
        </Div>
      </Row>

      <Row align="center" m="0px 0px 28px 0px" justify="center">
        <ClinicCard>
          <Col m="0px 28px 0px 28px" width="100%">
            <Row align="center" m="0px 0px 42px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Clinic Information
              </Text>
            </Row>
            <Row
              align="center"
              m="0px 0px 37px 0px"
              style={{ justifyContent: "center" }}
            >
              <Upload
                multiple={false}
                showUploadList={false}
                customRequest={handleUploadLogo}
              >
                {imageLogo ? (
                  <LogoCard background={prevImageLogo} />
                ) : (
                  <LogoCard>
                    <AddPhotoIcon stroke={theme.colors.gray500} />
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray500}
                    >
                      Add Logo
                    </Text>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.xs}
                      color={theme.colors.gray500}
                    >
                      Size: W 177 px - H 130 px
                    </Text>
                    {errorForm.image.error ? (
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.red}
                      >
                        Image Required
                      </Text>
                    ) : (
                      <></>
                    )}
                  </LogoCard>
                )}
              </Upload>
            </Row>
            <Row align="center" m="0px 0px 15px 0px">
              <Input
                label={"Clinic Name*"}
                id="clinicName"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                onChange={handleChangeClinic}
                error={errorForm.clinicName.error}
                helper={
                  errorForm.clinicName.error ? errorForm.clinicName.message : ""
                }
              />
            </Row>
            <Row align="center" m="0px 0px 15px 0px">
              <Input
                label={"URL Website"}
                id="website"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                onChange={handleChangeClinic}
                error={errorForm.website.error}
                helper={
                  errorForm.website.error ? errorForm.website.message : ""
                }
              />
            </Row>
            <Row align="center" m="0px 0px 10px 0px">
              <Input
                label={"Address*"}
                id="address"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                onChange={handleChangeClinic}
                error={errorForm.address.error}
                helper={
                  errorForm.address.error ? errorForm.address.message : ""
                }
              />
            </Row>
          </Col>
        </ClinicCard>

        <InfoCard>
          <Col m="0px 28px 0px 40px" width="100%">
            <Row align="center" m="0px 0px 12px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                More Information
              </Text>
            </Row>
            <Row align="center" m="0px 0px 20px 0px" justify="space-between">
              <Input
                label={"Clinic Phone Number*"}
                id="clinicPhone"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                onChange={handleChangeClinic}
                error={errorForm.clinicPhone.error}
                helper={
                  errorForm.clinicPhone.error
                    ? errorForm.clinicPhone.message
                    : ""
                }
              />
              <Input
                label={"Taxes*"}
                id="taxes"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"111px"}
                suffix={"%"}
                onChange={handleChangeClinic}
                error={errorForm.taxes.error}
                helper={errorForm.taxes.error ? errorForm.taxes.message : ""}
              />
              <Input
                label={"EIN (Optional)"}
                id="ein"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"148px"}
                onChange={handleChangeClinic}
                error={errorForm.ein.error}
                helper={errorForm.ein.error ? errorForm.ein.message : ""}
              />
            </Row>
            <Row align="center" m="0px 0px 10px 0px" justify="space-between">
              <Input
                label={"Instagram (Optional)"}
                id="ig"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                onChange={handleChangeClinic}
                error={errorForm.clinicPhone.error}
                helper={
                  errorForm.clinicPhone.error
                    ? errorForm.clinicPhone.message
                    : ""
                }
              />
              <Input
                label={"Facebook (Optional)"}
                id="fb"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                onChange={handleChangeClinic}
                error={errorForm.ein.error}
                helper={errorForm.ein.error ? errorForm.ein.message : ""}
              />
            </Row>
            <Row align="center" m="0px 0px 8px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Admin Information
              </Text>
            </Row>
            <Row align="center" m="0px 0px 19px 0px">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray400}
              >
                You have to add admin information to your clinic's account.
              </Text>
            </Row>
            <Row align="center" m="0px 0px 15px 0px" justify="space-between">
              <Input
                label={"First Name*"}
                id="adminName"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                onChange={handleChangeClinic}
                error={errorForm.adminName.error}
                helper={
                  errorForm.adminName.error ? errorForm.adminName.message : ""
                }
              />
              <Input
                label={"Last Name*"}
                id="lastname"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                onChange={handleChangeClinic}
                error={errorForm.lastname.error}
                helper={
                  errorForm.lastname.error ? errorForm.lastname.message : ""
                }
              />
            </Row>
            <Row align="center" m="0px 0px 15px 0px" justify="space-between">
              <Input
                label={"Phone number*"}
                id="adminPhone"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                onChange={handleChangeClinic}
                error={errorForm.adminPhone.error}
                helper={
                  errorForm.adminPhone.error ? errorForm.adminPhone.message : ""
                }
              />
              <Input
                label={"Email*"}
                id="email"
                autoComplete="off"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                onChange={handleChangeClinic}
                error={errorForm.email.error}
                helper={errorForm.email.error ? errorForm.email.message : ""}
              />
            </Row>
            <Row align="center" m="0px 0px 15px 0px" justify="space-between">
              <Password
                label={"Password*"}
                id="password"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                autoComplete="new-password"
                onChange={handleChangeClinic}
                error={errorForm.password.error}
                helper={
                  errorForm.password.error ? errorForm.password.message : ""
                }
                colorIcon={theme.colors.gray400}
              />
              <Password
                label={"Repeat Password*"}
                id="repeatPassword"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                autoComplete="none"
                onChange={handleChangeClinic}
                error={errorForm.repeatPassword.error}
                helper={
                  errorForm.repeatPassword.error
                    ? errorForm.repeatPassword.message
                    : ""
                }
                colorIcon={theme.colors.gray400}
              />
            </Row>
          </Col>
        </InfoCard>
      </Row>

      <Row align="center" m="0px 0px 28px 0px" justify="left">
        <SetUpClinic
          setUpClinicForm={setUpClinicForm}
          setSetUpClinicForm={setSetUpClinicForm}
          quoteImg={quoteImg}
          setQuoteImg={setQuoteImg}
          prevQuoteImage={prevQuoteImage}
          setPrevQuoteImage={setPrevQuoteImage}
        />
      </Row>

      <Row align="center" m="0px 0px 37px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.white}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={() => navigate(-1)}
          width="190px"
          m="0 24px 0 0"
        >
          Cancel
        </Button>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          onClick={handleCreateClinic}
          width="190px"
          background={theme.colors.green}
          loading={createClinic.isLoading}
        >
          Create Clinic
        </Button>
      </Row>
    </Container>
  );
};
