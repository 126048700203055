import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useUpdateBonusByCategory = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put(
      "/product-treatment/update-bonus-bycategory",
      formData
    );
  });
  return mutation;
};
export default useUpdateBonusByCategory;
