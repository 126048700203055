import styled from "styled-components";
import { Switch } from "antd";
import { theme } from "../../styles/theme";

export const CustomSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${theme.colors.gray600};
    border: 1px solid ${theme.colors.gray500};
    &:hover {
      background-color: ${theme.colors.gray500}!important;
    }
  }

  .ant-switch-handle::before {
    background-color: ${theme.colors.green}; // Color del círculo que se mueve
  }

  background-color: ${({ background }) => background || theme.colors.gray800};
  border: 1px solid ${theme.colors.gray600};
`;
