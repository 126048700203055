import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useEditPackage = () => {
  const mutation = useMutation(({ formData, packageId }) => {
    return axiosClient.put(`/package/${packageId}`, formData);
  });
  return mutation;
};
export default useEditPackage;
