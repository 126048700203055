import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const UserCard = styled.div`
  display: flex;
  align-items: center;
  height: 590px;
  width: 324px;
  margin: 0px 16px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const InfoCard = styled.div`
  display: flex;
  align-items: center;
  height: 590px;
  width: 664px;
  margin: 0px 16px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;
``;
export const PhotoCard = styled.div`
  height: 158px;
  width: 158px;
  padding: 50px 0px 50px 0px;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray700} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: 100%;
  opacity: 1;
  cursor: pointer;
`;
