import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useChangePassword = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.post(`/change-password`, formData);
  });
  return mutation;
};
export default useChangePassword;
