import { useState } from "react";
import { Text, Row, Div, Col } from "../../../../styles/Common";
import { Button } from "../../../../components/Button";
import { Select } from "../../../../components/Select";
import { Link } from "../../../../components/Link";
import { TotalCard, TableRow } from "../../styles";
import { theme } from "../../../../styles/theme";
import { AddIcon, DecreaseIcon } from "../../../../assets/icons";
import { formatPriceToDollar } from "../../../../utilities/helpers";

const StaffQuote = ({
  superAdmin,
  selectedClinic,
  optionsClinics,
  handleSelect,
  actualInfo,
  quotation,
  setQuotation,
  setShowModal,
  addItem,
}) => {
  const updateQuotationRequest = (itemsChanged) => {
    const items = itemsChanged.items.map((item) => {
      return {
        id: Number(item.productTreatmentId),
        quantity: item.quantity,
        package: item.package,
      };
    });
    addItem.reset();
    addItem.mutate(
      { productsTreatments: items, isStaff: true },
      {
        onSuccess: (res) => {
          setQuotation(res.data.data);
        },
        onError: (err) => {
          quotation?.items.length > 0 &&
            toast.error(
              err.response.data.error.description ||
                "Error trying to update the quotation"
            );
        },
      }
    );
  };

  const handleUpdateQuantity = (index, action) => {
    const updatedQuotation = { ...quotation };
    const updatedItems = [...updatedQuotation.items];
    if (action === "increase") {
      updatedItems[index].quantity += 1;
    } else if (action === "decrease" && updatedItems[index].quantity > 1) {
      updatedItems[index].quantity -= 1;
    }

    updatedQuotation.items = updatedItems;
    updateQuotationRequest(updatedQuotation);
  };

  const groupedItems = _.groupBy(quotation?.items, "package");
  return (
    <>
      <Col m="0 23px 0 14px">
        <Row width="621px" justify="space-between" align="center" m="5px 0 0 0">
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h4}
            color={theme.colors.white}
          >
            Personalized quote
          </Text>
          {superAdmin && (
            <Select
              placeholder="Select Clinic"
              id="clinic"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray600}
              background={theme.colors.gray800}
              color={theme.colors.white}
              width={"200px"}
              defaultValue=""
              value={actualInfo?.clinicId?.toString()}
              options={optionsClinics}
              onChange={(val, option) => handleSelect(val, option, "clinic")}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            />
          )}
          {selectedClinic && (
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              color={theme.colors.green}
              background={theme.colors.green100}
              border={theme.colors.green}
              onClick={() => setShowModal(true)}
              width="155px"
            >
              <Div gap={"8px"} style={{ justifyContent: "center" }}>
                <AddIcon stroke={theme.colors.green} />
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.default}
                  color={theme.colors.green}
                >
                  Add Item
                </Text>
              </Div>
            </Button>
          )}
        </Row>
        <Row m="52px 0 0 0" justify="center">
          {quotation.items.length === 0 ? (
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={theme.colors.gray100}
            >
              {!selectedClinic
                ? "Select a clinic to get a quote"
                : "Add the necessary items to see the quote."}
            </Text>
          ) : (
            <Col>
              <TableRow background="transparent">
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "140px" }}
                >
                  Item Name
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "110px" }}
                >
                  Staff Price
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "100px" }}
                >
                  Quantity
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "66px" }}
                >
                  Tax
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "92px" }}
                >
                  Discount
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray400}
                  style={{ width: "125px" }}
                >
                  Total Price
                </Text>
              </TableRow>
              {Object.entries(groupedItems).map(
                ([packageName, items], packageIndex) => (
                  <Col key={packageIndex}>
                    {items.map((item, index) => (
                      <TableRow
                        key={index}
                        background={
                          index % 2 === 0 ? theme.colors.gray600 : "transparent"
                        }
                      >
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{ width: "130px", "white-space": "nowrap" }}
                        >
                          {item.name}
                        </Text>
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{ width: "100px" }}
                        >
                          {formatPriceToDollar(item.price)}
                        </Text>
                        <Row width="100px" justify="center" gap="5px">
                          <Link
                            onClick={() =>
                              handleUpdateQuantity(index, "decrease")
                            }
                          >
                            <DecreaseIcon
                              stroke={theme.colors.gray400}
                              width="15px"
                              height="15px"
                            />
                          </Link>
                          <Text
                            weight={theme.fonts.weight.medium}
                            size={theme.fonts.size.sm}
                            color={theme.colors.gray400}
                            style={{
                              border: `1px solid ${theme.colors.gray500}`,
                              "border-radius": "25px",
                              width: "40px",
                            }}
                          >
                            {item.quantity}
                          </Text>
                          <Link
                            onClick={() =>
                              handleUpdateQuantity(index, "increase")
                            }
                          >
                            <AddIcon
                              stroke={theme.colors.gray400}
                              width="15px"
                              height="15px"
                            />
                          </Link>
                        </Row>
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{ width: "66px" }}
                        >
                          {formatPriceToDollar(item.tax)}
                        </Text>
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{ width: "92px" }}
                        >
                          {formatPriceToDollar(item.discount)}
                        </Text>
                        <Text
                          weight={theme.fonts.weight.medium}
                          size={theme.fonts.size.sm}
                          color={theme.colors.gray400}
                          style={{ width: "100px" }}
                        >
                          {formatPriceToDollar(
                            item.price * item.quantity - item.discount
                          )}
                        </Text>
                      </TableRow>
                    ))}
                    <TableRow background={"transparent"}>
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green300}
                        style={{ width: "130px", "white-space": "nowrap" }}
                      >
                        TOTAL
                      </Text>
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                        style={{ width: "100px" }}
                      >
                        {formatPriceToDollar(_.sumBy(items, "price"))}
                      </Text>
                      <Row width="100px" justify="center" gap="5px">
                        <Text
                          weight={theme.fonts.weight.semibold}
                          size={theme.fonts.size.sm}
                          color={theme.colors.green}
                          style={{
                            border: `1px solid ${theme.colors.green}`,
                            "border-radius": "25px",
                            width: "130px",
                          }}
                        >
                          {_.sumBy(items, "quantity")}
                        </Text>
                      </Row>
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                        style={{ width: "66px" }}
                      >
                        {formatPriceToDollar(_.sumBy(items, "tax"))}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                        style={{ width: "92px" }}
                      >
                        {formatPriceToDollar(_.sumBy(items, "discount"))}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                        style={{ width: "100px" }}
                      >
                        {formatPriceToDollar(_.sumBy(items, "total"))}
                      </Text>
                    </TableRow>
                  </Col>
                )
              )}
            </Col>
          )}
        </Row>
      </Col>
      <TotalCard>
        <Row align="center" justify="space-between" m="0 0 24px 0">
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h4}
            color={theme.colors.white}
          >
            Amount due
          </Text>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            {"(USD)"}
          </Text>
        </Row>
        <Row align="center" justify="space-between" m="0 0 12px 0">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            Subtotal
          </Text>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            {quotation.items.length === 0
              ? formatPriceToDollar(0)
              : formatPriceToDollar(quotation.subtotal)}
          </Text>
        </Row>
        <Row align="center" justify="space-between" m="0 0 12px 0">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            Discount
          </Text>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            {quotation.items.length === 0
              ? formatPriceToDollar(0)
              : formatPriceToDollar(quotation.discount)}
          </Text>
        </Row>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
          m="0 0 19px 0"
        >
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            Total Tax
          </Text>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            {quotation.items.length === 0
              ? formatPriceToDollar(0)
              : formatPriceToDollar(quotation.taxes)}
          </Text>
        </Row>
        <Div
          height="1px"
          width="100%"
          background={theme.colors.gray500}
          m="0 0 10px 0"
        />
        <Row align="center" justify="space-between" m="0 0 10px 0">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.default}
            color={theme.colors.gray50}
          >
            Total
          </Text>
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h3}
            color={theme.colors.green}
          >
            {quotation.items.length === 0
              ? formatPriceToDollar(0)
              : formatPriceToDollar(quotation.total)}
          </Text>
        </Row>
      </TotalCard>
    </>
  );
};
export default StaffQuote;
