import { useQuery } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useGetClinicById = (clinincId) => {
  const data = useQuery(
    ["getClinicById", clinincId],
    () => clinincId && axiosClient.get(`/clinic/${clinincId}`)
  );
  return data;
};
export default useGetClinicById;
