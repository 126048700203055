import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Row, Text, Div } from "../../../../styles/Common";
import { Drawer } from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { CloseIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import { isSuperAdmin, userClinic } from "../../../../utilities/helpers";
import { validatePlanForm } from "../../../../utilities/validations";
import useCreatePlan from "../../hooks/useCreatePlan";
import useEditPlan from "../../hooks/useEditPlan";

const DrawerPlan = ({
  showDrawer,
  closeDrawer,
  handleCloseDrawerSuccess,
  actualInfo,
  optionsClinics,
}) => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const { name: userClinicName, id: userClinicId } = userClinic();

  const createPlan = useCreatePlan();
  const editPlan = useEditPlan();

  const initialErrors = {
    name: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
  };

  const [forceUpdate, setForceUpdate] = useState(false);

  const [planForm, setPlanForm] = useState({
    clinic: actualInfo?.clinicId?.toString(),
    name: actualInfo.name,
  });

  const [errorForm, setErrorForm] = useState(initialErrors);

  useEffect(() => {
    setPlanForm({
      clinic: actualInfo?.clinicId?.toString(),
      name: actualInfo.name,
    });
  }, [actualInfo]);

  const handleChangePlan = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newPlanForm = planForm;

    newPlanForm[id] = value;

    setPlanForm(newPlanForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newPlanForm = planForm;
    newPlanForm[id] = value;

    setPlanForm(newPlanForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setErrorForm(initialErrors);
  };

  const handleCreatePlan = () => {
    toast.remove();

    if (!planForm["clinic"]) {
      const newPlanForm = planForm;
      newPlanForm["clinic"] = userClinicId;
      setPlanForm(newPlanForm);
    }

    const validation = validatePlanForm.validate(planForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      if (actualInfo.id) {
        editPlan.reset();
        editPlan.mutate(
          {
            id: actualInfo.id,
            clinicId: planForm?.clinic,
            name: planForm.name,
          },
          {
            onSuccess: () => {
              toast.success("The information has been successfully saved");
              handleCloseDrawer();
              queryClient.invalidateQueries(["planList", 0, 10]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message || "Error trying to edit a plan"
              );
            },
          }
        );
      } else {
        createPlan.reset();
        createPlan.mutate(
          {
            clinicId: planForm?.clinic,
            name: planForm.name,
          },
          {
            onSuccess: () => {
              handleCloseDrawerSuccess();
              queryClient.invalidateQueries(["planList", 0, 10]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to create a patient"
              );
            },
          }
        );
      }
    }
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      actualInfo={actualInfo}
    >
      <Div
        style={{ borderLeft: `5px solid ${theme.colors.green}` }}
        p="0px 0px 0px 16px"
        weight="5px"
        height="20px"
        align="center"
        m="6px 0px 0px 0px"
      >
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          {actualInfo.id ? "Edit Plan" : "New Plan"}
        </Text>
      </Div>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          {actualInfo.id
            ? "Complete the following information to edit plan."
            : "Complete the following information to create a new plan."}
        </Text>
      </Row>
      <Row m="24px 0px 0px 0px">
        {superAdmin ? (
          <Select
            label={actualInfo.id ? "Clinic" : "Select Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            defaultValue=""
            value={planForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            disabled={actualInfo.id ? true : false}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            value={userClinicName}
            disabled
          />
        )}
      </Row>

      <Row m="17px 0px 0px 0px">
        <Input
          label={"Plan Name"}
          id="name"
          value={planForm.name}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangePlan}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row m="59px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="290px"
          background={theme.colors.green}
          onClick={handleCreatePlan}
          loading={createPlan.isLoading || editPlan.isLoading}
        >
          {actualInfo.id ? "Save Information" : "Create Plan"}
        </Button>
      </Row>
      {actualInfo.id ? (
        <Row m="0px 0px 16px 0px">
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.green}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={handleCloseDrawer}
            width="290px"
          >
            Cancel
          </Button>
        </Row>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default DrawerPlan;
