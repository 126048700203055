import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeletePatient = () => {
  const mutation = useMutation((patientId) => {
    return axiosClient.delete(`/patients/${patientId}`);
  });
  return mutation;
};
export default useDeletePatient;
