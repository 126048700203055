import moment from "moment";
import { theme } from "../../../../styles/theme";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { Text, Row, Col } from "../../../../styles/Common";
import { CalendarIcon } from "../../../../assets/icons";
import { DateRangePicker } from "../../../../components/DateRangePicker";

export const CustomDateModal = ({
  showModal,
  handleClose,
  customInitialDate,
  setCustomInitialDate,
  customFinalDate,
  setCustomFinalDate,
  handleChangeDate,
}) => {
  return (
    <Modal open={showModal} onCancel={handleClose} width={"407px"}>
      <Modal.Header
        icon={<CalendarIcon stroke={theme.colors.green} />}
        iconBorderColor={theme.colors.green}
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        title={"Report Time Custom"}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        maxHeight={300}
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row m={"10px 0 18px 0"}>
            <DateRangePicker
              label={"Range date"}
              id="dueDate"
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.green}
              border={theme.colors.gray500}
              background={theme.colors.white}
              color={theme.colors.gray500}
              width={"307px"}
              value={[customInitialDate, customFinalDate]}
              onChange={(value, date) => handleChangeDate(value, date)}
              /* error={saveQuoteErrorForm.dueDate.error}
            helper={
              saveQuoteErrorForm.dueDate.error
                ? saveQuoteErrorForm.dueDate.message
                : ""
            } */
            />
          </Row>
          <Row
            width="100%"
            p="30px 0 0 0"
            style={{ "justify-content": "center" }}
          >
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleClose}
              width="226px"
              background={theme.colors.green}
            >
              Confirm range
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};
