import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Row, Text, Div } from "../../../../styles/Common";
import { Drawer } from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { CloseIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import { isSuperAdmin, userClinic } from "../../../../utilities/helpers";
import { validatePatientForm } from "../../../../utilities/validations";
import useCreatePatient from "../../hooks/useCreatePatient";
import useEditPatient from "../../hooks/useEditPatient";
import { setSelectedPatientInformation } from "../../../../core/store/patients";

const DrawerPatient = ({
  showDrawer,
  closeDrawer,
  handleCloseDrawerSuccess,
  actualInfo,
  optionsClinics,
  optionsCodes,
}) => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const { name: userClinicName, id: userClinicId } = userClinic();
  const dispatch = useDispatch();

  const createPatient = useCreatePatient();
  const editPatient = useEditPatient();

  const initialErrors = {
    name: {
      error: false,
      message: "",
    },
    lastname: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
    phone: {
      error: false,
      message: "",
    },
    callingCode: {
      error: false,
      message: "",
    },
  };

  const [forceUpdate, setForceUpdate] = useState(false);
  const [patientForm, setPatientForm] = useState({
    name: actualInfo.name,
    lastname: actualInfo.lastname,
    clinic: actualInfo?.clinicId?.toString(),
    email: actualInfo.email,
    phone: actualInfo.phone,
    callingCode: actualInfo.callingCode,
  });
  const [errorForm, setErrorForm] = useState(initialErrors);

  useEffect(() => {
    setPatientForm({
      name: actualInfo.name,
      lastname: actualInfo.lastname,
      clinic: actualInfo?.clinicId?.toString(),
      email: actualInfo.email,
      phone: actualInfo.phone,
      callingCode: actualInfo.callingCode,
    });
  }, [actualInfo]);

  const handleChangePatient = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newPatientForm = patientForm;

    newPatientForm[id] = value;

    setPatientForm(newPatientForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newpatientForm = patientForm;
    newpatientForm[id] = value;

    setPatientForm(newpatientForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setErrorForm(initialErrors);
  };

  const handleCreatePatient = () => {
    toast.remove();

    if (!patientForm["clinic"]) {
      const newPatientForm = patientForm;
      newPatientForm["clinic"] = userClinicId;
      setPatientForm(newPatientForm);
    }
    const nameClinic = optionsClinics.find(
      (cl) => Number(cl.value) === Number(patientForm.clinic)
    );

    const validation = validatePatientForm.validate(patientForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      if (actualInfo.id) {
        editPatient.reset();
        editPatient.mutate(
          {
            id: actualInfo.id,
            name: patientForm.name,
            lastname: patientForm.lastname,
            clinicId: patientForm.clinic,
            email: patientForm.email,
            phone: patientForm.phone,
            callingCode: patientForm.callingCode,
          },
          {
            onSuccess: () => {
              dispatch(
                setSelectedPatientInformation({
                  id: actualInfo.id,
                  email: patientForm.email,
                  name: patientForm.name,
                  lastname: patientForm.lastname,
                  callingCode: patientForm.callingCode,
                  phone: patientForm.phone,
                  clinicId: patientForm.clinic,
                  clinicName: nameClinic.label,
                })
              );
              toast.success("The information has been successfully saved");
              handleCloseDrawer();
              queryClient.invalidateQueries(["patientList", 0, 10]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to edit a patient"
              );
            },
          }
        );
      } else {
        createPatient.reset();
        createPatient.mutate(
          {
            id: null,
            name: patientForm.name,
            lastname: patientForm.lastname,
            clinicId: patientForm.clinic,
            email: patientForm.email,
            phone: patientForm.phone,
            callingCode: patientForm.callingCode,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["patientList", 0, 10]);
              handleCloseDrawerSuccess();
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to create a patient"
              );
            },
          }
        );
      }
    }
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      actualInfo={actualInfo}
    >
      <Div
        style={{ borderLeft: `5px solid ${theme.colors.green}` }}
        p="0px 0px 0px 16px"
        weight="5px"
        height="20px"
        align="center"
        m="6px 0px 0px 0px"
      >
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          {actualInfo.id ? "Edit Patient" : "New Patient"}
        </Text>
      </Div>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          {actualInfo.id
            ? "Complete the following information to edit patient."
            : "Complete the following information to create a new patient."}
        </Text>
      </Row>
      <Row m="24px 0px 0px 0px">
        {superAdmin ? (
          <Select
            label={actualInfo.id ? "Clinic" : "Select Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            defaultValue=""
            value={patientForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            disabled={actualInfo.id ? true : false}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            value={userClinicName}
            disabled
          />
        )}
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"First Name"}
          id="name"
          value={patientForm.name}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangePatient}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Last Name"}
          id="lastname"
          value={patientForm.lastname}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangePatient}
          error={errorForm.lastname.error}
          helper={errorForm.lastname.error ? errorForm.lastname.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Email"}
          id="email"
          value={patientForm.email}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangePatient}
          error={errorForm.email.error}
          helper={errorForm.email.error ? errorForm.email.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Div direction={"column"}>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.sm}
            color={theme.colors.gray500}
            mt="0px"
            mb="7px"
            ml="6px"
            align="left"
          >
            {"Phone"}
          </Text>
          <Input.Group proportion="40% 60%">
            <Select
              id="callingCode"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              value={patientForm.callingCode}
              onChange={(val) => handleSelect(val, "callingCode")}
              options={optionsCodes}
              paddingx={16}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
            />
            <Input
              id="phone"
              type="number"
              value={patientForm.phone}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={"transparent"}
              color={theme.colors.gray600}
              width={"210px"}
              onChange={handleChangePatient}
              error={errorForm.phone.error}
              helper={errorForm.phone.error ? errorForm.phone.message : ""}
            />
          </Input.Group>
        </Div>
      </Row>
      <Row m="59px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="290px"
          background={theme.colors.green}
          onClick={handleCreatePatient}
          loading={createPatient.isLoading || editPatient.isLoading}
        >
          {actualInfo.id ? "Save Information" : "Create Patient"}
        </Button>
      </Row>
      {actualInfo.id ? (
        <Row m="0px 0px 16px 0px">
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            color={theme.colors.green}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={handleCloseDrawer}
            width="290px"
          >
            Cancel
          </Button>
        </Row>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default DrawerPatient;
