import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useCategory = (options) => {
  const getOptions = options || {};
  const data = useQuery(
    ["categories"],
    () => axiosClient.get(`/category`),
    getOptions
  );
  return data;
};
export default useCategory;
