import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import print from "print-js";
import { pdfjs } from "react-pdf";
import { Modal } from "../Modal";
import { PDFContainer, StyledDocument, StyledPage } from "./styles";
import { DownloadIcon, PrintIcon } from "../../assets/icons";
import { theme } from "../../styles/theme";
import { Row, Col } from "../../styles/Common";
import { Link } from "../Link";

const PDFModal = ({ pdfUrl, modalOpen, handleCloseModal }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfUrl;
    a.setAttribute("download", `Quotation.pdf`);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handlePrint = () => {
    print(pdfUrl);
  };

  return (
    <PDFContainer>
      <Modal
        open={modalOpen}
        onCancel={handleCloseModal}
        width={"80%"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Modal.Header />
        <Modal.Body
          padding={"5px 5px 5px 5px"}
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {pdfUrl ? (
            <>
              <StyledDocument
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <StyledPage
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </StyledDocument>
              <Row gap="10px">
                <Link>
                  <DownloadIcon
                    width={25}
                    stroke={theme.colors.green}
                    onClick={handleDownload}
                  />
                </Link>
                <Link>
                  <PrintIcon onClick={handlePrint} />
                </Link>
              </Row>
            </>
          ) : (
            <Col gap="10px">
              <Spin size="large" />
              Generating File
            </Col>
          )}
        </Modal.Body>
      </Modal>
    </PDFContainer>
  );
};

export default PDFModal;
