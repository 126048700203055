import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteQuote = () => {
  const mutation = useMutation((quoteId) => {
    return axiosClient.delete(`/quote/${quoteId}`);
  });
  return mutation;
};
export default useDeleteQuote;
