import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Row, Text, Div, Col } from "../../../../../styles/Common";
import { Drawer } from "../../../../../components/Drawer";
import { Button } from "../../../../../components/Button";
import Checkbox from "../../../../../components/Checkbox";
import { CloseIcon } from "../../../../../assets/icons";
import { theme } from "../../../../../styles/theme";
import useParameters from "../../../../../core/hooks/useParameters";

const DrawerPaymentMethod = ({
  showDrawer,
  closeDrawer,
  paymentMethods,
  setPaymentMethods,
  setPaymentError,
}) => {
  const getPaymentMethods = useParameters("payment_methods");
  const { data: paymentMethodsApi } = getPaymentMethods;
  const [paymentMethodOptions, setPaymentMethodOptions] = useState();
  useEffect(() => {
    if (paymentMethodsApi) {
      setPaymentMethodOptions(
        paymentMethodsApi?.data?.data?.users.map((ele, idx) => {
          return {
            value: idx,
            label: ele.name,
          };
        })
      );
    }
  }, [paymentMethodsApi]);

  const [tempPaymentMethods, setTempPaymentMethods] = useState();

  useEffect(() => {
    setTempPaymentMethods(paymentMethods);
  }, [paymentMethods]);

  const handleCheckboxChange = (label) => {
    setTempPaymentMethods((prevMethods) => {
      if (prevMethods.includes(label)) {
        return prevMethods.filter((method) => method !== label);
      } else {
        return [...prevMethods, label];
      }
    });
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setTempPaymentMethods(paymentMethods);
  };

  const handleSavePaymentMethods = () => {
    toast.success("The information has been successfully saved");
    setPaymentError(false);
    setPaymentMethods(tempPaymentMethods);
    closeDrawer();
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
    >
      <Row>
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
          m="6px 0px 0px 0px"
        />
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          Add payment method
        </Text>
      </Row>
      <Col m="25px 0px 0px 0px">
        {paymentMethodOptions?.map((method, idx) => (
          <Div
            key={idx}
            m="4px"
            p="20px"
            background={theme.colors.gray50}
            radius="13px"
            align="center"
            gap="10px"
          >
            <Checkbox
              key={method.value}
              value={method.value}
              checked={tempPaymentMethods?.includes(method.label)}
              onChange={() => handleCheckboxChange(method.label)}
            />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray600}
            >
              {method.label}
            </Text>
          </Div>
        ))}
      </Col>

      <Row m="47px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="290px"
          background={theme.colors.green}
          onClick={handleSavePaymentMethods}
        >
          Add Payment Methods
        </Button>
      </Row>
      <Row m="0px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.green}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={handleCloseDrawer}
          width="290px"
        >
          Cancel
        </Button>
      </Row>
    </Drawer>
  );
};

export default DrawerPaymentMethod;
