import styled from "styled-components";
import { theme } from "../../styles/theme";
import backImage from "../../assets/images/backgroud-password.png";

export const ResetPasswordRequestWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;
`;
