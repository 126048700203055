import { useMutation } from "react-query";
import { axiosClient } from "../../core/services/axiosInstance";

const useDeleteProductTreatment = () => {
  const mutation = useMutation((productTreatmentId) => {
    return axiosClient.delete(`/product-treatment/${productTreatmentId}`);
  });
  return mutation;
};
export default useDeleteProductTreatment;
