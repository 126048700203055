import React, { useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QuoteCard, StatusWrapper } from "./styles";
import { Col, Div, Row, Text } from "../../../../styles/Common";
import { Link } from "../../../../components/Link";
import {
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  OpenIcon,
} from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import Checkbox from "../../../../components/Checkbox";
import {
  downloadPDF,
  formatPriceToDollar,
  formattedDate,
} from "../../../../utilities/helpers";
import { setSelectedQuotationInformation } from "../../../../core/store/quotations";
import { ButtonViewQuote } from "../../../Quote History/styles";

const QuoteWrapper = ({ quoteInformation, patient }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const handleSelect = (e) => {
    const val = e.target.value;
    if (selected.includes(val)) {
      setSelected(selected.filter((quo) => quo !== val));
    } else {
      setSelected([...selected, val]);
    }
  };
  return (
    <QuoteCard>
      <Col>
        <Checkbox
          value={quoteInformation.id}
          label={""}
          checked={selected.includes(quoteInformation.id)}
          onChange={handleSelect}
        />
      </Col>
      <Col height="57px">
        <Div direction="column" gap="12px" align="center" justify="flex-start">
          <Text
            weight={theme.fonts.weight.extralight}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            Creation Date
          </Text>

          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.sm}
            color={theme.colors.white}
          >
            {formattedDate(quoteInformation.createdAt)}
          </Text>
        </Div>
      </Col>
      <Col height="57px">
        <Div direction="column" gap="2px" align="center" justify="flex-start">
          <Text
            weight={theme.fonts.weight.extralight}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            Status
          </Text>

          <StatusWrapper status={quoteInformation.state}>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.white}
            >
              {capitalize(quoteInformation.state)}
            </Text>
          </StatusWrapper>
        </Div>
      </Col>
      <Col height="57px">
        <Div direction="column" gap="12px" align="center">
          <Text
            weight={theme.fonts.weight.extralight}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            Expiration Date
          </Text>

          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.sm}
            color={theme.colors.white}
          >
            {formattedDate(quoteInformation.dueDate)}
          </Text>
        </Div>
      </Col>
      <Col height="57px">
        <Div direction="column" gap="12px" align="center">
          <Text
            weight={theme.fonts.weight.extralight}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            Taxes
          </Text>

          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.sm}
            color={theme.colors.white}
          >
            {quoteInformation.taxes ? "Yes" : "No"}
          </Text>
        </Div>
      </Col>
      <Col height="57px">
        <Div direction="column" gap="12px" align="center">
          <Text
            weight={theme.fonts.weight.extralight}
            size={theme.fonts.size.sm}
            color={theme.colors.gray50}
          >
            Total
          </Text>

          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.sm}
            color={theme.colors.green}
          >
            {`${formatPriceToDollar(quoteInformation.total)}`}
          </Text>
        </Div>
      </Col>
      <Col height="57px">
        <Row gap="8px" height="57px" align="center">
          <ButtonViewQuote
            onClick={() => {
              dispatch(
                setSelectedQuotationInformation({
                  id: quoteInformation?.id,
                  total: quoteInformation?.total,
                  dueDate: quoteInformation?.dueDate,
                  state: quoteInformation?.state,
                  createdAt: quoteInformation?.createdAt,
                  clinic: {
                    id: patient?.clinicId,
                    name: patient?.clinicName,
                  },
                  patient: {
                    id: patient?.id,
                    name: patient?.name,
                    lastname: patient?.lastname,
                  },
                })
              );
              navigate("/quote-history/edit-quotation");
            }}
          >
            <OpenIcon fill={theme.colors.gray100} />
            View Quote
          </ButtonViewQuote>
          {/* <Link
            onClick={() => downloadPDF(quoteInformation.id)}
            size={theme.fonts.size.sm}
            color={theme.colors.green}
            style={{ padding: 0 }}
            icon={<DownloadIcon stroke={theme.colors.white} />}
          /> */}
          <Link
            onClick={() => {}}
            size={theme.fonts.size.sm}
            color={theme.colors.green}
            style={{ padding: 0 }}
            icon={<DeleteIcon stroke={theme.colors.white} />}
          />
        </Row>
      </Col>
    </QuoteCard>
  );
};

export default QuoteWrapper;
