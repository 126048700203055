import { useEffect, useMemo, useState } from "react";
import { theme } from "../../../../styles/theme";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import { Text, Row, Col, Div } from "../../../../styles/Common";
import _, { sumBy } from "lodash";
import { formatPriceToDollar } from "../../../../utilities/helpers";
import { SalesCard } from "../../styles";
import useGetSalesByUser from "../../hooks/useGetSalesByUser";

export const RegularUser = ({
  selectedReportTime,
  groupItems,
  payloadOptions,
}) => {
  const getSalesByUser = useGetSalesByUser(payloadOptions);
  const { data: dataSalesByUser, isLoading: isLoadingSalesRegularUser } =
    getSalesByUser;
  const [salesRegularUser, setSalesRegularUser] = useState([]);
  const [groupedSalesRegularUser, setGroupedSalesRegularUser] = useState();

  useEffect(() => {
    if (!isLoadingSalesRegularUser) {
      const groupedSales = groupItems(dataSalesByUser?.data?.data);
      setSalesRegularUser(dataSalesByUser?.data?.data);
      setGroupedSalesRegularUser(groupedSales);
    }
  }, [dataSalesByUser, isLoadingSalesRegularUser]);

  const summedData = useMemo(() => {
    return _.map(groupedSalesRegularUser, (group, idx) => ({
      date:
        selectedReportTime.value === 7
          ? `Day ${idx + 1}`
          : selectedReportTime.value === 365
          ? `M${idx + 1}`
          : `Week ${idx + 1}`,
      sales: sumBy(group, "sales"),
      bonus: sumBy(group, "bonus"),
    }));
  }, [groupedSalesRegularUser, selectedReportTime]);

  useEffect(() => {}, [groupedSalesRegularUser, selectedReportTime]);

  const GraphTooltip = ({ label, payload }) => {
    return (
      <Div
        background={theme.colors.gray300}
        radius="15px"
        direction="column"
        p="10px"
        style={{ border: `1px solid ${theme.colors.gray50}` }}
      >
        <Text color={theme.colors.gray50} size={theme.fonts.size.default}>
          {`${label}`}
        </Text>
        <Text color={theme.colors.green} size={theme.fonts.size.default}>
          {`Sales: ${payload[0]?.value}`}
        </Text>
        <Text color={theme.colors.green} size={theme.fonts.size.default}>
          {`Bonus: ${formatPriceToDollar(payload[1]?.value)}`}
        </Text>
      </Div>
    );
  };

  return (
    <Div
      direction="row"
      width="1005px"
      height="max-content"
      m="40px 0"
      gap="16px"
    >
      <SalesCard>
        <Row width="100%" align="center" justify="space-between" p="0 15px">
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.h4}
            color={theme.colors.white}
          >
            My sales
          </Text>
        </Row>
        <Div
          height="235px"
          width="454px"
          m="25px 0"
          radius="20px"
          background={theme.colors.gray950}
          direction="column"
        >
          <Row m="21px 21px 0 21px" justify="space-between">
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h4}
              color={theme.colors.white}
            >
              {`${formatPriceToDollar(
                sumBy(salesRegularUser, "sales")
              )} in sales`}
            </Text>
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
              color={theme.colors.green}
            >
              {`${formatPriceToDollar(sumBy(salesRegularUser, "bonus"))} Bonus`}
            </Text>
          </Row>
          <BarChart
            width={400}
            height={150}
            data={summedData}
            margin={{ top: 10, right: 25, left: 20, bottom: 0 }}
            barGap={3}
          >
            <Tooltip
              cursor={{ fill: theme.colors.green100 }}
              content={<GraphTooltip />}
            />
            <Bar
              dataKey="sales"
              fill={theme.colors.gray800}
              name="Sales"
              radius={8}
              barSize={30}
            />
            <Bar
              dataKey="bonus"
              fill={theme.colors.green}
              name="Bonus"
              radius={8}
              barSize={32}
            />
            <XAxis
              dataKey="date"
              axisLine={false}
              tick={{ fontSize: theme.fonts.size.sm, fill: theme.colors.white }}
            />
          </BarChart>
        </Div>
        {groupedSalesRegularUser?.map((salesGroup, idx) => {
          const totalSales = sumBy(salesGroup, "sales");
          const totalBonus = sumBy(salesGroup, "bonus");
          return (
            <Div
              key={idx}
              height="57px"
              width="454px"
              m="0 0 10px 0"
              p="0 21px 0 26px"
              radius="12px"
              background="rgba(141, 141, 141, 0.18)"
            >
              <Row width="100%" align="center" justify="space-between">
                <Text
                  weight={theme.fonts.weight.light}
                  size={theme.fonts.size.h5}
                  color={theme.colors.gray400}
                  style={{ width: "100px", "text-align": "left" }}
                >
                  {selectedReportTime.value === 7
                    ? `Day ${idx + 1}`
                    : selectedReportTime.value === 365
                    ? `Month ${idx + 1}`
                    : `Week ${idx + 1}`}
                </Text>
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.h5}
                  color={theme.colors.gray400}
                >
                  {`${formatPriceToDollar(totalSales)} in sales`}
                </Text>
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.h5}
                  color={theme.colors.gray400}
                  style={{ width: "100px", "text-align": "right" }}
                >
                  {formatPriceToDollar(totalBonus)} Bonus
                </Text>
              </Row>
            </Div>
          );
        })}
      </SalesCard>
    </Div>
  );
};
