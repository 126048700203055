import styled from "styled-components";
import { theme } from "../../styles/theme";

export const statusColor = (status) => {
  switch (status) {
    case "expired":
      return theme.colors.red;
    case "inactive":
      return theme.colors.yellow;
    case "active":
      return theme.colors.green;
    default:
      return theme.colors.gray50;
  }
};

export const StatusMark = styled.div`
  width: 11px;
  height: 5px;

  background: ${({ status }) => statusColor(status)};
  border-radius: 5px;
`;

export const WrapperFilter = styled.div`
  display: flex;
  width: 38px;
  height: 22px;
  gap: 8px;
  border-radius: 6px;
  background: ${({ background }) => background || theme.colors.white};
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

export const LabelIndicative = styled.div`
  display: flex;
  width: 64px;
  height: 22px;
  background: ${({ background }) => background};
  border: 4px solid;
  border-color: ${({ borde }) => borde};

  @media (max-width: 550px) {
    width: 44px;
    height: 12px;
  }
`;

export const KeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "165px"};
  //radius: 10px;
  border: 1px;
  padding: 16px;
  gap: 9px;
  background: ${theme.colors.gray100};
  justify-content: ${({ justify }) => justify || "center"};
  align-items: ${({ align }) => align || "center"};

  @media (max-width: 550px) {
    width: 145px;
    height: 65px;
  }
`;

export const ProfileImg = styled.div`
  height: 107px;
  width: 107px;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray700} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: contain;
  border-radius: 100%;
  object-fit: "cover";
  //opacity: 1;
`;

export const InfoCard = styled.div`
  display: flex;
  flex-direction: row;
  height: 89px;
  width: 294px;
  padding: 19px 16px 16px 16px;
  background: ${`${theme.colors.gray50} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: contain;
  border-radius: 14px;
  object-fit: "cover";
  //text-align: left;
  // gap: 11px;
  // opacity: 1;
`;

export const IconBackground = styled.div`
  height: 52px;
  width: 52px;
  padding: 11px 11px 11px 11px;
  background: ${`${theme.colors.green500} 0% 0% no-repeat padding-box`};
  border-radius: 100%;
  opacity: 1;
`;
