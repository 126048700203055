import React from "react";
import { CustomDrawer } from "./styles";
import { theme } from "../../styles/theme";

export const Drawer = ({ closelabel, closeIcon, children, ...rest }) => {
  return (
    <CustomDrawer closelabel={closelabel} closeIcon={closeIcon} {...rest}>
      {children}
    </CustomDrawer>
  );
};
