import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { DeleteIcon } from "../../../../assets/icons";
import useDeletePackage from "../../hooks/useDeletePackage";

const ModalDelete = ({ showModal, handleCloseModal, selectedPackageId }) => {
  const queryClient = useQueryClient();
  const deletePackage = useDeletePackage();

  const handleDeleteProduct = () => {
    deletePackage.reset();
    deletePackage.mutate(selectedPackageId, {
      onSuccess: () => {
        handleCloseModal();
        toast.success("Package deleted successfully.");
        queryClient.invalidateQueries(["packagestList", 0, 10]);
      },
      onError: (err) => {
        toast.error(
          err.response.data.error.message ||
            "Error trying to delete the package"
        );
      },
    });
  };
  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"407px"}>
      <Modal.Header
        icon={
          <DeleteIcon
            stroke={theme.colors.red}
            width={"40px"}
            height={"40px"}
          />
        }
        iconBorderColor={theme.colors.red}
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        padding={"20px 63px 0px 63px"}
        title={"Delete package"}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" p="0 27px 0px 27px">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              {
                "Are you sure you want to delete this package? Remember that this action cannot be undone"
              }
            </Text>
          </Row>
          <Row width="100%" p="30px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleDeleteProduct}
              width="100%"
              background={theme.colors.green}
              loading={deletePackage.isLoading}
            >
              {"Yes, Delete"}
            </Button>
          </Row>
          <Row width="100%" p="16px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleCloseModal}
              width="100%"
              background={theme.colors.green100}
              color={theme.colors.green}
              border={theme.colors.green}
            >
              No, Cancel
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDelete;
