import { useQuery } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useGetPackageById = (packageId) => {
  const data = useQuery(
    ["getPackageById", packageId],
    () => packageId && axiosClient.get(`/packages/${packageId}`),
    { enabled: packageId !== undefined && packageId !== null }
  );
  return data;
};
export default useGetPackageById;
