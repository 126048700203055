import styled, { css } from "styled-components";
import { DatePicker } from "antd";
import { theme } from "../../styles/theme";

export const CustomRangePicker = styled(DatePicker.RangePicker)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || "50px"};
  border: 1px solid ${({ border }) => border || theme.colors.gray500};
  border-radius: ${({ radius }) => radius || "25px"};
  background: ${({ background }) => background || theme.colors.white};
  color: ${({ color }) => color || theme.colors.gray950};
  padding: 0 16px;
  opacity: 1;

  &:hover {
    background: ${({ background }) =>
      background || theme.colors.gray800} !important;
  }

  &:focus {
  }

  ant-input-placeholder {
    color: ${({ placeholderColor }) =>
      placeholderColor || theme.colors.gray950}!important;
  }

  &.ant-picker {
    &.has-helper {
      background: ${({ background }) => background || theme.colors.white};
      &:hover {
        background: ${({ background }) =>
          background || theme.colors.white} !important;
      }
    }
  }

  .ant-picker-input {
    input {
      color: ${({ color }) => color || theme.colors.gray950};
    }
  }
`;
