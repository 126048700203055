import React from "react";

import { TableSkeleton } from "./Skeleton";
import { TableFilter } from "./Filter";

import { Wrapper, CustomTable } from "./styles";

const Table = ({ columns, data, sizerLabels, ...rest }) => {
  return (
    <Wrapper>
      <CustomTable
        columns={columns}
        dataSource={data}
        sizerLabels={sizerLabels}
        {...rest}
      />
    </Wrapper>
  );
};

Table.defaultProps = {
  sizerLabels: ["Show", "Results"],
};

Table.Skeleton = TableSkeleton;
Table.Filter = TableFilter;

export default Table;
