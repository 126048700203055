import Joi from "joi";
import tlds from "../../node_modules/@sideway/address/lib/tlds";
import dayjs from "dayjs";

const validatePassword = {
  password: Joi.string()
    .required()
    .pattern(
      new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,16}$/
      )
    ),
};

export const validateEmailRequired = {
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
};

export const validateLoginForm = Joi.object({
  ...validateEmailRequired,
  ...validatePassword,
});

export const validateResetPassword = Joi.object({
  password: validatePassword.password,
  passwordConfirm: validatePassword.password,
});

export const validateUserForm = Joi.object({
  name: Joi.string().min(3).max(100).required(),
  lastname: Joi.string().min(3).max(100).required(),
  clinic: Joi.number().required(),
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
  role: Joi.number().required(),
  image: Joi.string().allow(null, ""),
  phone: Joi.number().min(1000000000).max(9999999999).allow(null, ""),
  address: Joi.string().min(3).max(100).allow(null, ""),
  productCommission: Joi.number().allow(""),
  treatmentCommission: Joi.number().allow(""),
  password: Joi.string()
    .required()
    .pattern(
      new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,16}$/
      )
    ),
  repeatPassword: Joi.string().required().valid(Joi.ref("password")).messages({
    "any.only": "Passwords must match",
  }),
});

export const validateEditUserForm = Joi.object({
  name: Joi.string().min(3).max(100).required(),
  lastname: Joi.string().min(3).max(100).required(),
  clinic: Joi.number().required(),
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
  role: Joi.number().required(),
  image: Joi.string().allow(null, ""),
  phone: Joi.number().min(1000000000).max(9999999999).allow(null, ""),
  address: Joi.string().min(3).max(100).allow(null, ""),
  productCommission: Joi.number().allow(""),
  treatmentCommission: Joi.number().allow(""),
});

export const validateClinicForm = Joi.object({
  clinicName: Joi.string().min(3).max(100).required(),
  ein: Joi.string().allow(null, ""),
  address: Joi.string().min(3).max(100).required(),
  clinicPhone: Joi.number().min(1000000000).max(9999999999).required(),
  taxes: Joi.number().required(),
  website: Joi.string().allow(null, ""),
  ig: Joi.string().allow(null, ""),
  fb: Joi.string().allow(null, ""),
  adminName: Joi.string().min(3).max(100).required(),
  lastname: Joi.string().min(3).max(100).required(),
  adminPhone: Joi.number().min(1000000000).max(9999999999).required(),
  image: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .required(),
  password: Joi.string()
    .required()
    .pattern(
      new RegExp(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,16}$/
      )
    ),
  repeatPassword: Joi.string().required().valid(Joi.ref("password")).messages({
    "any.only": "Passwords must match",
  }),
});

export const validateEditClinicForm = Joi.object({
  clinicName: Joi.string().min(3).max(100).required(),
  ein: Joi.string().allow(null, ""),
  address: Joi.string().min(3).max(100).required(),
  clinicPhone: Joi.number().min(1000000000).max(9999999999).required(),
  taxes: Joi.number().required(),
  website: Joi.string().allow(null, ""),
  image: Joi.string().required(),
  ig: Joi.string().allow(null, ""),
  fb: Joi.string().allow(null, ""),
});

export const validateClinicLogo = Joi.object({
  image: Joi.string().required(),
});

export const validateEditProfileForm = Joi.object({
  name: Joi.string().min(3).max(100).required(),
  lastname: Joi.string().min(3).max(100).required(),
  image: Joi.string().allow(null, ""),
  phone: Joi.number().min(1000000000).max(9999999999).required(),
  address: Joi.string().min(3).max(100).required(),
});

export const validatePatientForm = Joi.object({
  name: Joi.string().min(3).max(100).required(),
  lastname: Joi.string().min(3).max(100).required(),
  clinic: Joi.number().required(),
  email: Joi.string()
    .email({ tlds: { allow: tlds } })
    .allow(null, ""),
  phone: Joi.number().min(1000000000).max(9999999999).required(),
  callingCode: Joi.string().required(),
});

export const validateCategoryForm = Joi.object({
  name: Joi.string().min(3).max(100).required(),
  type: Joi.number().required(),
  clinic: Joi.number().required(),
  bonus: Joi.number().required(),
  bonusType: Joi.number().required(),
});

export const validateProductTreatmentForm = Joi.object({
  type: Joi.string().required(),
  clinic: Joi.number().required(),
  categoryId: Joi.number().required(),
  name: Joi.string().min(3).max(100).required(),
  price: Joi.number().required(),
  staffPrice: Joi.number().allow(null).allow(""),
  cost: Joi.number().required(),
  bonusType: Joi.string().required(),
  bonusValue: Joi.number().when("bonusType", {
    is: "commission",
    then: Joi.allow(null, ""),
    otherwise: Joi.required(),
  }),
  isTaxIncluded: Joi.boolean().required(),
  sku: Joi.string().allow(""),
});

export const validatePlanForm = Joi.object({
  clinic: Joi.number().required(),
  name: Joi.string().min(3).max(100).required(),
});
export const validateEditPassword = Joi.object({
  OldPassword: validatePassword.password,
  NewPassword: validatePassword.password,
  ConfirmationPassword: validatePassword.password,
});

export const validateChangePassword = Joi.object({
  currentPassword: validatePassword.password,
  newPassword: validatePassword.password,
});

export const validateItemQuotingForm = Joi.object({
  treatmentsCategories: Joi.array().items(Joi.object()),
  productsCategories: Joi.array().items(Joi.object()),
  products: Joi.array().items(Joi.object()),
  treatments: Joi.array().items(Joi.object()),
  packageName: Joi.string().allow("").allow(null),
  custom: Joi.string().when("packageName", {
    is: "Custom",
    then: Joi.required(),
    otherwise: Joi.allow(""),
  }),
}).custom((value, helpers) => {
  if (
    (!value.products || value.products.length === 0) &&
    (!value.treatments || value.treatments.length === 0)
  ) {
    return helpers.error("any.required");
  }
  return value;
});

export const validateOpenItemQuotingForm = Joi.object({
  type: Joi.string().required(),
  name: Joi.string().required(),
  tax: Joi.number().when("type", {
    is: 2,
    then: Joi.required(),
    otherwise: Joi.allow("", null),
  }),
  isTaxIncluded: Joi.bool().required(),
  price: Joi.number().required(),
  package: Joi.string().allow(""),
  custom: Joi.string().when("packageName", {
    is: "Custom",
    then: Joi.required(),
    otherwise: Joi.allow(""),
  }),
});

export const validateStaffItemQuotingForm = Joi.object({
  treatmentsCategories: Joi.array().items(Joi.object()),
  productsCategories: Joi.array().items(Joi.object()),
  products: Joi.array().items(Joi.object()),
  treatments: Joi.array().items(Joi.object()),
  packageName: Joi.string().allow(""),
});

const customDateValidator = (value, helpers) => {
  const date = dayjs(value);
  if (date.isValid()) {
    return value;
  } else {
    return helpers.error("date.invalid");
  }
};
export const validateQuotingForm = Joi.object({
  patient: Joi.number().required(),
  dueDate: Joi.custom(customDateValidator, "custom date validation").required(),
  items: Joi.array().min(1).required(),
});

export const validatePackageForm = Joi.object({
  dueDate: Joi.custom(customDateValidator, "custom date validation").required(),
  packageName: Joi.string().min(1).required(),
  items: Joi.array().min(1).required(),
});
