import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { FolderPlusIcon, DeleteIcon } from "../../../../assets/icons";
import useDeletePlan from "../../hooks/useDeletePlan";

const ModalPlan = ({
  showModal,
  handleCloseModal,
  selectedPlanId,
  isDeliting,
}) => {
  const queryClient = useQueryClient();
  const deletePlan = useDeletePlan();

  const handleDeletePlan = (planId) => {
    deletePlan.reset();
    deletePlan.mutate(planId, {
      onSuccess: () => {
        handleCloseModal();
        toast.success("Plan deleted successfully.");
        queryClient.invalidateQueries(["planList", 0, 10]);
      },
      onError: (err) => {
        toast.error(
          err.response.data.error.message || "Error trying to delete the plan"
        );
      },
    });
  };
  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"407px"}>
      <Modal.Header
        icon={
          !isDeliting ? (
            <FolderPlusIcon width={"40px"} height={"40px"} />
          ) : (
            <DeleteIcon
              stroke={theme.colors.red}
              width={"40px"}
              height={"40px"}
            />
          )
        }
        iconBorderColor={!isDeliting ? theme.colors.green : theme.colors.red}
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        padding={"20px 63px 0px 63px"}
        title={!isDeliting ? "New plan has been created" : "Delete plan"}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" p="0 27px 0px 27px">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              {!isDeliting
                ? "The plan has been created successfully"
                : "Are you sure you want to delete this plan? Remember that this action cannot be undone"}
            </Text>
          </Row>
          <Row width="100%" p="30px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={
                !isDeliting
                  ? handleCloseModal
                  : () => handleDeletePlan(selectedPlanId)
              }
              width="100%"
              background={theme.colors.green}
              loading={!isDeliting ? null : deletePlan.isLoading}
            >
              {!isDeliting ? "View all plans" : "Yes, Delete"}
            </Button>
          </Row>
          {isDeliting && (
            <Row width="100%" p="16px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleCloseModal}
                width="100%"
                background={theme.colors.green100}
                color={theme.colors.green}
                border={theme.colors.green}
              >
                No, Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPlan;
