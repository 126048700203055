import { theme } from "../../../../styles/theme";
import { Text, Row, Col, Div } from "../../../../styles/Common";
import _ from "lodash";
import { DownloadIcon, CrownIcon } from "../../../../assets/icons";
import { formatPriceToDollar } from "../../../../utilities/helpers";
import { Button } from "../../../../components/Button";
import { TableRow } from "../../styles";

export const TopSellers = ({ topSellersList }) => {
  return (
    <Div
      width="494px"
      m="0 0 25px 0"
      p="24px"
      radius="25px"
      background={theme.colors.gray800}
      direction="column"
    >
      <Row m="0 0 32px 4px" justify="space-between" align="center">
        <Div
          radius="50%"
          background="rgba(255, 255, 255, 0.2)"
          height="45px"
          width="45px"
          justify="center"
          align="center"
        >
          <CrownIcon stroke={theme.colors.white} />
        </Div>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          Top ten sellers
        </Text>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.green}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={() => console.log("details")}
          width="144px"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.default}
            color={theme.colors.green}
          >
            Details
          </Text>
        </Button>
      </Row>
      <Row m="0 0 12px 4px" align="left">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
          style={{ width: "153px", padding: "0 0 0 14px" }}
        >
          Name
        </Text>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
          style={{ width: "79px", padding: "0 0 0 14px" }}
        >
          Sales
        </Text>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
          style={{ width: "105px", padding: "0 0 0 14px" }}
        >
          Amount
        </Text>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
          style={{ width: "105px", padding: "0 0 0 14px" }}
        >
          Bonus
        </Text>
      </Row>
      {topSellersList?.map((seller, index) => (
        <TableRow
          key={index}
          background={index % 2 === 0 ? theme.colors.gray600 : "transparent"}
        >
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.sm}
            color={theme.colors.gray400}
            style={{
              width: "145px",
              "white-space": "nowrap",
            }}
          >
            {seller.vendorName}
          </Text>
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.sm}
            color={theme.colors.gray400}
            style={{ width: "79px", "text-align": "-webkit-center" }}
          >
            {seller.sales}
          </Text>

          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.sm}
            color={theme.colors.gray400}
            style={{ width: "105px", "text-align": "-webkit-center" }}
          >
            {formatPriceToDollar(seller.totalAmount)}
          </Text>
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.sm}
            color={theme.colors.gray400}
            style={{ width: "105px", "text-align": "-webkit-center" }}
          >
            {formatPriceToDollar(seller.totalBonus)}
          </Text>
        </TableRow>
      ))}
    </Div>
  );
};
