import styled from "styled-components";
import { Drawer } from "antd";
import { theme } from "../../styles/theme";

export const CustomDrawer = styled(Drawer)`
  border-radius: 30px 0px 0px 30px;

  &&& .ant-drawer {
    background: ${theme.colors.white} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000017;
    opacity: 1;

    &-mask {
      backdrop-filter: blur(4px);
    }

    &-header {
      padding-top: 25px;
      border: none;
      align-self: end;
    }

    &-body {
      padding: 0 37px 0 37px;
    }

    &-content {
      background-color: red;
    }

    &-content-wrapper {
      overflow: hidden;
    }

    &-close {
      display: flex;
      align-items: center;
      gap: 8px;
      font-family: ${({ theme }) => theme.fonts.family};
      font-weight: ${({ theme }) => theme.fonts.weight.medium};

      &:hover {
        color: ${({ theme }) => theme.colors.white};
      }

      &:before {
        content: "${(props) => props.closelabel}";
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
