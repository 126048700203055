import { useQuery } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useGetQuoteById = (quoteId) => {
  const data = useQuery(
    ["getQuotateById", quoteId],
    () => quoteId && axiosClient.get(`/quote/${quoteId}`)
  );
  return data;
};
export default useGetQuoteById;
