import React, { useState } from "react";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  LoginWrapper,
  PhotoWrapper,
  PhotoInWrapper,
  BlurWrapper,
  WaveWrapper,
} from "./styles";
import { Row, Col, Text, Div, Span } from "../../styles/Common";
import { Card } from "./components/Card";
import { BlurCard } from "./components/BlurredCard";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Password } from "../../components/Password";
import { theme } from "../../styles/theme";
import { validateLoginForm } from "../../utilities/validations";
import logo from "../../assets/images/dividermLog.svg";
import photo from "../../assets/images/login-img.png";
import logintext from "../../assets/images/login-text.svg";
import loginwave from "../../assets/images/login-wave-path.svg";
import useAuth from "./hooks/useAuth";
import { setLocalUser, setRefresh } from "../../core/services/axiosInstance";
import { Link } from "../../components/Link";
import { emailLength, setLocalValue } from "../../utilities/helpers";
import { setFirstTime } from "../../core/store/common";
import { HeartIcon, CopyrightIcon } from "../../assets/icons";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useAuth();
  const { isLoading } = authentication;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [errorForm, setErrorForm] = useState({
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
  });

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newLoginForm = loginForm;
    newLoginForm[id] = value;
    setLoginForm(newLoginForm);
    setForceUpdate(!forceUpdate);
  };

  const handleLogin = () => {
    toast.remove();
    const validation = validateLoginForm.validate(loginForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      authentication.reset();
      authentication.mutate(
        { email: loginForm.email, password: loginForm.password },
        {
          onSuccess: (res) => {
            setLocalUser(res.data.data.token);
            setRefresh(res.data.data.refreshToken);

            const urlToken = res.data.data.token
              ? decode(res.data.data.token).logoUrl
              : null;

            setLocalValue("urlLogo", urlToken);
            navigate("/home");
            dispatch(setFirstTime(true));
          },
          onError: (err) => {
            toast.error(err.response.data.error.message);
          },
        }
      );
    }
  };

  const handleReset = () => {
    navigate("/reset-password-request");
  };

  return (
    <>
      <LoginWrapper>
        <Row width="1082px" height="100%">
          <Col
            width="100%"
            height="100vh"
            align="end"
            justify="center"
            style={{ position: "relative" }}
          >
            <PhotoWrapper>
              <img
                alt="photo"
                src={photo}
                width="528px"
                height="657px"
                style={{ borderRadius: "0 99px" }}
              />
            </PhotoWrapper>
            <WaveWrapper>
              <Div
                align={"left"}
                style={{ position: "absolute", bottom: "36px", left: "-35px" }}
              >
                <img
                  alt="logintext"
                  src={loginwave}
                  width="120px"
                  height="650px"
                />
              </Div>
            </WaveWrapper>
            <BlurWrapper>
              <Div
                align={"left"}
                style={{ position: "absolute", bottom: "153px", left: "-55px" }}
              >
                <BlurCard>
                  <img
                    alt="logintext"
                    src={logintext}
                    width="170px"
                    height="33px"
                  />
                  <Text
                    weight={theme.fonts.weight.extralight}
                    size={theme.fonts.size.h1}
                    color={theme.colors.white}
                  >
                    Medical Technology
                  </Text>
                </BlurCard>
              </Div>
            </BlurWrapper>
            <PhotoInWrapper>
              <Div
                align={"center"}
                gap={"15px"}
                style={{ position: "absolute", bottom: "24px", left: "308px" }}
              >
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.h5}
                  color={theme.colors.white}
                >
                  Natural Beauty
                </Text>
                <HeartIcon />
              </Div>
            </PhotoInWrapper>

            <Card>
              <Row width="100%" justify="center" m="43px 0 0 0">
                <img alt="logo" src={logo} width="250px" height="75px" />
              </Row>
              <Row
                width="100%"
                m="11px 0 23px 0"
                justify="center"
                p="0 40px 0 40px"
              >
                <Text
                  weight={theme.fonts.weight.light}
                  size={theme.fonts.size.h5}
                  color={theme.colors.gray950}
                  align={"center"}
                >
                  Our Vision is{" "}
                  <Span
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    to empower individuals
                  </Span>{" "}
                  to feel confident and beautiful in their own skin
                </Text>
              </Row>
              <Row width="100%" m="5px 0 0 0">
                <Input
                  label={"Email"}
                  id="email"
                  maxLength={emailLength}
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  onChange={handleChange}
                  error={errorForm.email.error}
                  helper={errorForm.email.error ? errorForm.email.message : ""}
                  width="100%"
                  border={theme.colors.gray400}
                />
              </Row>
              <Row width="100%" m="16px 0 0 0">
                <Password
                  label={"Password"}
                  id="password"
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  border={theme.colors.gray400}
                  width="100%"
                  onChange={handleChange}
                  error={errorForm.password.error}
                  helper={
                    errorForm.password.error ? errorForm.password.message : ""
                  }
                  onPressEnter={handleLogin}
                  autoComplete="none"
                />
              </Row>
              <Row width="100%" m="8px 0 0 0" justify="end">
                <Link
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.bold}
                  color={theme.colors.green}
                  onClick={handleReset}
                >
                  Forgot Password
                </Link>
              </Row>
              <Row width="100%" m="22px 0 0 0">
                <Button onClick={handleLogin} width="100%" loading={isLoading}>
                  Log in
                </Button>
              </Row>

              <Row width="100%" m="54px 0 20px 0" justify="center">
                <Div gap={"8px"} align={"center"}>
                  <CopyrightIcon />
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray500}
                  >
                    2024. All Rights Reserved
                  </Text>
                </Div>
              </Row>
            </Card>
          </Col>
        </Row>
      </LoginWrapper>
    </>
  );
};
