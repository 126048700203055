import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetCostsProfits = ({ clinicId, days, startDate, endDate }) => {
  let urlString = `/quote/cost-gain/byperiod?clinicId=${clinicId}`;
  if (days) {
    urlString += `&days=${days}`;
  }

  if (!days) {
    urlString += `&startDate=${startDate}&endDate=${endDate}`;
  }

  const data = useQuery(
    ["costsProfitsByPeriod", clinicId, days, startDate, endDate],
    () => axiosClient.get(urlString)
  );

  return data || {};
};
export default useGetCostsProfits;
