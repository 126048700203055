import styled from "styled-components";

export const CustomCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 827px;
  height: 573px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(19px);
  border-radius: 50px;
  opacity: 1;
  padding: 43px 81px 27px 353px;
`;
