import styled, { css } from "styled-components";
import { DatePicker } from "antd";
import { theme } from "../../styles/theme";

export const CustomDatePicker = styled(DatePicker)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || "50px"};
  border: 1px solid ${({ border }) => border || theme.colors.gray500};
  border-radius: ${({ radius }) => radius || "25px"};
  background: ${({ background }) => background || theme.colors.white};
  color: ${({ color }) => color || theme.colors.gray950};
  padding: 0 16px;
  opacity: 1;

  &:hover {
    ${({ helper }) =>
      !helper &&
      css`
        background: transparent !important;
      `}
  }

  &.ant-picker {
    &.has-helper {
      background: ${({ background }) => background || theme.colors.white};
      &:hover {
        background: ${({ background }) =>
          background || theme.colors.white} !important;
      }
    }
  }

  &.ant-picker-disabled {
    border-color: ${({ disabledBorderColor }) =>
      disabledBorderColor || theme.colors.gray500};
    background: ${({ disabledBackground }) =>
      disabledBackground || theme.colors.gray600};
    .ant-picker-suffix {
      color: ${({ disabledIconColor }) =>
        disabledIconColor || theme.colors.gray400};
    }

    && input {
      color: ${({ disabledTextColor }) =>
        disabledTextColor || theme.colors.gray50};
    }
  }

  .ant-picker-input {
    input {
      color: ${({ color }) => color || theme.colors.gray950};
    }
  }

  .ant-picker-suffix {
    color: ${({ color }) => color || theme.colors.gray950};
  }
`;
