import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeletePlan = () => {
  const mutation = useMutation((planId) => {
    return axiosClient.delete(`/treatment-plan/${planId}`);
  });
  return mutation;
};
export default useDeletePlan;
