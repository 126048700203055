import React, { useState, useEffect } from "react";
import { ImgCard, SetUpCard } from "../styles";
import { Col, Row, Text, Div } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { Upload } from "antd";
import toast from "react-hot-toast";
import { Input } from "../../../../components/Input";
import { Radio } from "../../../../components/Radio";
import { Switch } from "../../../../components/Switch";
import {
  createURLFromBase64,
  convertFileToBase64,
} from "../../../../utilities/helpers";
import { AddPhotoIcon, QuestionIcon } from "../../../../assets/icons";

export const SetUpClinic = ({
  setUpClinicForm,
  setSetUpClinicForm,
  quoteImg,
  setQuoteImg,
  prevQuoteImage,
  setPrevQuoteImage,
}) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [errorColor, setErrorColor] = useState(false);
  const [showFrequentAdditionalDiscount, setShowFrequentAdditionalDiscount] =
    useState(true);

  const handleUploadLogo = async (dataLogo) => {
    const file = dataLogo.file;
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      toast.error("The image must be .png or .jpg file");
    } else {
      const urlBase64 = await toast.promise(convertFileToBase64(file), {
        loading: "Uploading...",
        success: "Upload completed",
        error: "Upload failed, try again",
      });

      const newClinicForm = {
        ...setUpClinicForm,
        quoteImage: `data:image/png;base64,${urlBase64}`,
      };

      setSetUpClinicForm(newClinicForm);
      setQuoteImg(urlBase64);
      setPrevQuoteImage(createURLFromBase64(urlBase64, "image/jpeg"));
    }
  };

  const isValidColor = (color) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== "";
  };
  const handleChange = (event) => {
    const { value, id } = event.target;

    if (id === "brandColor") {
      if (!isValidColor(value)) {
        setErrorColor(true);
      } else {
        setErrorColor(false);
      }
    }

    const newSetUp = setUpClinicForm;
    newSetUp[id] = value;
    setSetUpClinicForm(newSetUp);
    setForceUpdate(!forceUpdate);
  };

  const handleSwitch = (checked, id) => {
    const newSetUp = setUpClinicForm;
    if (id === "freqAdditional") {
      setShowFrequentAdditionalDiscount(checked);
      newSetUp["consultationFeeName"] = null;
      newSetUp["consultationFeeType"] = null;
      newSetUp["consultationFeeValue"] = null;
    }

    newSetUp[id] = checked;
    setSetUpClinicForm(newSetUp);
    setForceUpdate(!forceUpdate);
  };

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newSetUp = setUpClinicForm;
    newSetUp[name] = value;
    setSetUpClinicForm(newSetUp);
    setForceUpdate(!forceUpdate);
  };

  return (
    <SetUpCard>
      <Text
        weight={theme.fonts.weight.medium}
        size={theme.fonts.size.h4}
        color={theme.colors.white}
        align="left"
      >
        Set Up Information
      </Text>
      <Row m="24px 0px 24px 0px" gap="60px">
        <Col>
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h5}
            color={theme.colors.white}
            align="left"
            mb="18px"
          >
            Quote Design
          </Text>
          <Upload
            multiple={false}
            showUploadList={false}
            customRequest={handleUploadLogo}
          >
            {quoteImg ? (
              <ImgCard background={prevQuoteImage} />
            ) : (
              <ImgCard>
                <AddPhotoIcon stroke={theme.colors.gray500} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                  mb="10px"
                  mt="10px"
                >
                  Custom Image
                </Text>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.xs}
                  color={theme.colors.gray500}
                >
                  Size: W 130 px - H 177 px
                </Text>
              </ImgCard>
            )}
          </Upload>
          <Row gap="8px" align="center" justify="center" m="10px 0 24px 0">
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={theme.colors.green}
            >
              Whats This
            </Text>
            <QuestionIcon fill={theme.colors.green} />
          </Row>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            Web brand color code
          </Text>
          <Row gap="8px" align="center" justify="center" m="10px 0">
            <Div
              radius="7px"
              background={setUpClinicForm.brandColor}
              width="36px"
              height="36px"
              style={{ border: `1.2px solid ${theme.colors.gray500}` }}
            />
            <Input
              id="brandColor"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"157px"}
              onChange={handleChange}
              value={setUpClinicForm.brandColor}
              error={errorColor}
            />
          </Row>
          {errorColor && (
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={theme.colors.red}
              align="left"
            >
              Invalid code
            </Text>
          )}
        </Col>

        <Col width="650px" justify="start">
          <Row gap="90px">
            <Input
              label="Expiration Days"
              id="expirationDays"
              type="number"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              suffix={"Days"}
              width={"205px"}
              value={setUpClinicForm.expirationDays}
              onChange={handleChange}
            />
            <Col gap="18px">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
                align="left"
              >
                Customer Naming
              </Text>
              <Radio.Group
                name="customerNaming"
                onChange={handleRadio}
                value={setUpClinicForm.customerNaming}
                gap={"32px"}
                direction="row"
              >
                <Row gap="8px">
                  <Radio value={"patient"} />
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray100}
                  >
                    Patient
                  </Text>
                </Row>
                <Row gap="8px">
                  <Radio value={"client"} />
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray100}
                  >
                    Client
                  </Text>
                </Row>
              </Radio.Group>
            </Col>
          </Row>

          <Div
            width="100%"
            height="1px"
            background={theme.colors.gray500}
            m="25px 0"
          />

          <Row gap="14px" align="center">
            <Switch
              background={theme.colors.gray700}
              checked={showFrequentAdditionalDiscount}
              onChange={(checked) => handleSwitch(checked, "freqAdditional")}
            />
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Frequent Additional Discount
            </Text>
          </Row>
          {showFrequentAdditionalDiscount && (
            <>
              <Row justify="space-between" align="center" m="14px 0 0 0">
                <Input
                  label="Discount Name"
                  id="consultationFeeName"
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  border={theme.colors.gray500}
                  background={theme.colors.gray600}
                  color={theme.colors.gray400}
                  width={"212px"}
                  value={setUpClinicForm.consultationFeeName}
                  onChange={handleChange}
                />
                <Col gap="18px">
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray500}
                    align="left"
                  >
                    Discount Type
                  </Text>
                  <Radio.Group
                    name="consultationFeeType"
                    value={setUpClinicForm.consultationFeeType}
                    onChange={handleRadio}
                    gap={"3px"}
                    direction="column"
                  >
                    <Row gap="8px">
                      <Radio value={"percentage"} />
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.default}
                        color={theme.colors.gray100}
                      >
                        Percentage
                      </Text>
                    </Row>
                    <Row gap="8px">
                      <Radio value={"dollar"} />
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.default}
                        color={theme.colors.gray100}
                      >
                        Amount
                      </Text>
                    </Row>
                  </Radio.Group>
                </Col>
                <Input
                  label="Amount"
                  id="consultationFeeValue"
                  type="number"
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  border={theme.colors.gray500}
                  background={theme.colors.gray600}
                  color={theme.colors.gray400}
                  width={"157px"}
                  value={setUpClinicForm.consultationFeeValue}
                  onChange={handleChange}
                />
              </Row>
              <Row gap="14px" align="center" m="24px 0 0 0">
                <Switch
                  background={theme.colors.gray700}
                  checked={setUpClinicForm.consultationFeeOnbyDefault}
                  onChange={(checked) =>
                    handleSwitch(checked, "consultationFeeOnbyDefault")
                  }
                />
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.h5}
                  color={theme.colors.green}
                >
                  Frequent Additional Discount option toggled on by default
                </Text>
              </Row>
            </>
          )}
          <Div
            width="100%"
            height="1px"
            background={theme.colors.gray500}
            m="25px 0 18px 0"
          />

          <Row gap="14px" align="center">
            <Switch
              background={theme.colors.gray700}
              checked={setUpClinicForm.itemizedPricingOnbyDefault}
              onChange={(checked) =>
                handleSwitch(checked, "itemizedPricingOnbyDefault")
              }
            />
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.h5}
              color={theme.colors.green}
            >
              Show itemized pricing in Quote as Default
            </Text>
          </Row>
          <Row gap="8px" align="center" justify="left" m="10px 0 24px 0">
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.default}
              color={theme.colors.green}
            >
              Whats This
            </Text>
            <QuestionIcon fill={theme.colors.green} />
          </Row>
        </Col>
      </Row>
    </SetUpCard>
  );
};
