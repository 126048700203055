import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useCreateTreatment = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.post("/product-treatment", formData);
  });
  return mutation;
};
export default useCreateTreatment;
