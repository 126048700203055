import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteUser = () => {
  const mutation = useMutation((userId) => {
    return axiosClient.delete(`/user/${userId}`);
  });
  return mutation;
};
export default useDeleteUser;
