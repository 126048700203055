import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const NoDiscountCard = styled.div`
  flex-direction: column;
  display: flex;
  gap: 24px;
  align-items: top;
  min-height: 612px;
  width: 409px;
  padding: 25px 30px 25px 30px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;
