import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Col, Row, Text } from "../../styles/Common";
import { ResetPasswordRequestWrapper } from "./styles";
import { Card } from "../../components/Card";
import { Modal } from "../../components/Modal";
import { theme } from "../../styles/theme";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import useSendEmailResetPassword from "./hooks/useSendEmailResetPassword";
import { validateEmailRequired } from "../../utilities/validations";
import { EmailOpenedIcon } from "../../assets/icons";

export const ResetPasswordRequest = () => {
  const navigate = useNavigate();
  const resetPassword = useSendEmailResetPassword();
  const { isLoading } = resetPassword;
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorValidation, setErrorValidation] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleSend = () => {
    setShowModal(false);
    toast.remove();
    const validation = validateEmailRequired.email.validate(email, {
      abortEarly: false,
    });

    if (validation.error) {
      setError(true);
      setErrorValidation("Invalid email");
      setForceUpdate(!forceUpdate);
    } else {
      resetPassword.reset();
      resetPassword.mutate(email, {
        onSuccess: () => {
          setShowModal(true);
        },
        onError: (err) => {
          toast.error(err.response.data.error.message);
        },
      });
    }
  };

  const handleLogin = () => {
    navigate("/");
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <>
      <Modal open={showModal} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={
            <EmailOpenedIcon
              stroke={theme.colors.red}
              width={"50px"}
              height={"50px"}
            />
          }
          bordercolor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"26px 0 16px 0"}
          title={"Check your email"}
        />
        <Modal.Body
          margin="5px 0 39px 0"
          height={425}
          width={417}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0 20px 0px 20px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                Click the link we sent to your email, reset your passwword and
                regain acces to your account.
              </Text>
            </Row>
            <Row width="100%" p="24px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleSend}
                width="100%"
                background={theme.colors.green}
                loading={isLoading}
              >
                Resend Link
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <ResetPasswordRequestWrapper>
        <Row width="100%" height="100%">
          <Col width="100%" height="100vh" align="center" justify="center">
            <Card width="417px" height="425px">
              <Row width="100%" m="2px 0 0 0" p="0px 63px 0px 63px">
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.h4}
                  color={theme.colors.gray950}
                >
                  ¿Did you forget your password?
                </Text>
              </Row>
              <Row width="100%" m="24px 0 0 0" p="0px 18px 0px 18px">
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Indicate your username/email and we'll send the password reset
                  link
                </Text>
              </Row>
              <Row width="100%" m="18px 0 0 0">
                <Input
                  label="Email"
                  id="email"
                  width="100%"
                  weightLabel={theme.fonts.weight.regular}
                  onChange={handleChange}
                  error={error}
                  helper={error ? errorValidation : ""}
                />
              </Row>

              <Row m="22px 0 0 0">
                <Button
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.semibold}
                  onClick={handleSend}
                  width="100%"
                  background={theme.colors.green}
                  loading={isLoading}
                >
                  Send Link
                </Button>
              </Row>
              <Row width="100%" m="16px 0 0 0">
                <Button
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.green}
                  background={theme.colors.green100}
                  border={theme.colors.green}
                  onClick={handleLogin}
                  width="100%"
                >
                  Cancel
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </ResetPasswordRequestWrapper>
    </>
  );
};
