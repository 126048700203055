import styled from "styled-components";

export const BlurredCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 282px;
  height: 173px;
  padding: 22px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  border-radius: 20px;
  opacity: 1;
`;
