import styled from "styled-components";
import { theme } from "../../styles/theme";
import backImage from "../../assets/images/background-login2.png";

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: url(${backImage}) no-repeat;
  background-position: center;
  justify-content: center;
`;

export const PhotoWrapper = styled.div`
  position: absolute;
  width: 528px;
  height: 657px;
  z-index: 1;
  border-radius: 0px 99px;
  left: 0;
`;

export const PhotoInWrapper = styled.div`
  position: absolute;
  width: 508px;
  height: 637px;
  z-index: 2;
  border-radius: 0px 99px;
  border: 1px solid ${theme.colors.white};
  left: 10px;
`;

export const WaveWrapper = styled.div`
  position: absolute;
  width: 528px;
  height: 657px;
  z-index: 3;
  border-radius: 0px 99px;
  left: 0;
`;

export const BlurWrapper = styled.div`
  position: absolute;
  width: 528px;
  height: 657px;
  z-index: 4;
  border-radius: 0px 99px;
  left: 0;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: ${theme.colors.gray100};
  background-position: center;
  justify-content: center;
`;
