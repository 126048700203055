import { useState, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import useParameters from "../../../../../core/hooks/useParameters";
import { Col, Row, Text } from "../../../../../styles/Common";
import { Input } from "../../../../../components/Input";
import { Modal } from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { Select } from "../../../../../components/Select";
import { Tag } from "../../../../../components/Tag";
import { theme } from "../../../../../styles/theme";
import { CloseIcon } from "../../../../../assets/icons";
import { validateItemQuotingForm } from "../../../../../utilities/validations";
import useCategory from "../../../../../core/hooks/useCategory";

const ModalItems = ({
  addItem,
  showModal,
  handleCloseModal,
  selectedClinic,
  actualInfo,
  errorForm,
  setErrorForm,
  quotation,
  setQuotation,
  packageName,
}) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [itemForm, setItemForm] = useState({
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: "",
    custom: "",
  });

  useEffect(() => {
    setItemForm({
      treatmentsCategories: actualInfo.treatmentsCategories,
      treatments: actualInfo.treatments,
      productsCategories: actualInfo.productsCategories,
      products: actualInfo.products,
      packageName: actualInfo.packageName,
    });
  }, [actualInfo]);

  const [categoriesList, setCategoriesList] = useState([]);

  const { isLoading: isLoadingCat } = useCategory({
    onSuccess: (apiData) => {
      setCategoriesList(apiData.data.data);
    },
  });

  const filterCat = useMemo(() => {
    if (categoriesList) {
      const filteredCategories = categoriesList.filter(
        (catEle) => Number(catEle.clinic.id) === Number(selectedClinic?.value)
      );

      const categorizedLists = _.groupBy(filteredCategories, "category_type");

      let productCategoriesList = _.map(categorizedLists[2], (filterEle) => ({
        value: filterEle.id,
        label: filterEle.name,
      }));

      let treatmentCategoriesList = _.map(categorizedLists[1], (filterEle) => ({
        value: filterEle.id,
        label: filterEle.name,
      }));

      productCategoriesList = _.sortBy(productCategoriesList, (item) =>
        item.label.toLowerCase()
      );
      treatmentCategoriesList = _.sortBy(treatmentCategoriesList, (item) =>
        item.label.toLowerCase()
      );

      return {
        productCategoriesList,
        treatmentCategoriesList,
      };
    }
    return {
      productCategoriesList: [],
      treatmentCategoriesList: [],
    };
  }, [selectedClinic, categoriesList]);

  const getProductsTreatments = useParameters("products_treatments");
  const {
    data: dataProductsTreatments,
    isLoading: isLoadingProductsTreatments,
    isFetching: isFetchingProductsTreatments,
  } = getProductsTreatments;

  const [productsList, setProductsList] = useState([]);
  const [treatmentsList, setTreatmentsList] = useState([]);
  const [productsListOptions, setProductsListOptions] = useState([]);
  const [treatmentsListOptions, setTreatmentsListOptions] = useState([]);

  useEffect(() => {
    if (itemForm.treatmentsCategories) {
      const selectedTreatmentCategoryIds = itemForm?.treatmentsCategories.map(
        (category) => category.id
      );

      const filteredTreatments =
        dataProductsTreatments?.data?.data?.products_treatments.filter(
          (treatments) =>
            selectedTreatmentCategoryIds.includes(treatments.categoryId) &&
            treatments.type === "treatment"
        );
      let newTreatmentsList = _.chain(filteredTreatments)
        .groupBy("Category.name")
        .map((treatments, categoryName) => ({
          label: <Text weight={theme.fonts.weight.bold}>{categoryName}</Text>,
          options: _.sortBy(
            treatments.map((treatment) => ({
              label: treatment.name,
              value: treatment.id,
            })),
            "label"
          ),
        }))
        .sortBy((group) => group.label.props.children)
        .value();
      setTreatmentsListOptions(newTreatmentsList);
      setTreatmentsList(filteredTreatments);
    }
    if (itemForm.productsCategories) {
      const selectedProductCategoryIds = itemForm?.productsCategories.map(
        (category) => category.id
      );
      const filteredProducts =
        dataProductsTreatments?.data?.data?.products_treatments.filter(
          (product) =>
            selectedProductCategoryIds.includes(product.categoryId) &&
            product.type === "product"
        );
      let newProductList = _.chain(filteredProducts)
        .groupBy("Category.name")
        .map((products, categoryName) => ({
          label: <Text weight={theme.fonts.weight.bold}>{categoryName}</Text>,
          title: categoryName,
          options: _.sortBy(
            products.map((product) => ({
              label: product.name,
              value: product.id,
            })),
            "label"
          ),
        }))
        .sortBy((group) => group.label.props.children)
        .value();
      setProductsListOptions(newProductList);
      setProductsList(filteredProducts);
    }
  }, [
    itemForm.treatmentsCategories,
    itemForm.productsCategories,
    dataProductsTreatments,
  ]);

  const handleFilter = (valToFilter, type) => {
    if (type === "product") {
      const filteredList = productsList.filter(
        (opt) => opt.name.toLowerCase().indexOf(valToFilter.toLowerCase()) >= 0
      );

      let newProductList = _.chain(filteredList)
        .groupBy("Category.name")
        .map((products, categoryName) => ({
          label: <Text weight={theme.fonts.weight.bold}>{categoryName}</Text>,
          title: categoryName,
          options: _.sortBy(
            products.map((product) => ({
              label: product.name,
              value: product.id,
            })),
            "label"
          ),
        }))
        .sortBy((group) => group.label.props.children)
        .value();
      setProductsListOptions(newProductList);
    }
    if (type === "treatment") {
      const filteredList = treatmentsList.filter(
        (opt) => opt.name.toLowerCase().indexOf(valToFilter.toLowerCase()) >= 0
      );

      let newTreatmentsList = _.chain(filteredList)
        .groupBy("Category.name")
        .map((treatments, categoryName) => ({
          label: <Text weight={theme.fonts.weight.bold}>{categoryName}</Text>,
          options: _.sortBy(
            treatments.map((treatment) => ({
              label: treatment.name,
              value: treatment.id,
            })),
            "label"
          ),
        }))
        .sortBy((group) => group.label.props.children)
        .value();
      setTreatmentsListOptions(newTreatmentsList);
    }
  };

  const handleSelect = (value, item, id) => {
    const newErrorForm = errorForm;
    if (id === "treatments" || id === "products") {
      handleFilter("", "treatment");
      handleFilter("", "product");
      newErrorForm.products.error = false;
      newErrorForm.products.message = "";
      newErrorForm.treatments.error = false;
      newErrorForm.treatments.message = "";
    }
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...itemForm };
    if (id !== "packageName") {
      if (!newItemForm[id].some((ele) => ele.id === item.value)) {
        newItemForm[id] = [
          ...newItemForm[id],
          { id: item.value, name: item.label },
        ];
      }
    } else {
      newItemForm[id] = !value ? null : item.label;
    }
    setItemForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const removeTreatmentCategory = (cat) => {
    const updatedCategories = itemForm.treatmentsCategories.filter(
      (category) => category !== cat
    );
    setItemForm({ ...itemForm, treatmentsCategories: updatedCategories });
  };

  const removeProductCategory = (cat) => {
    const updatedCategories = itemForm.productsCategories.filter(
      (category) => category !== cat
    );
    setItemForm({ ...itemForm, productsCategories: updatedCategories });
  };

  const removeTreatment = (treat) => {
    const updatedTreatments = itemForm.treatments.filter(
      (treatment) => treatment !== treat
    );
    setItemForm({ ...itemForm, treatments: updatedTreatments });
  };

  const removeProduct = (prod) => {
    const updateProducts = itemForm.products.filter(
      (product) => product !== prod
    );
    setItemForm({ ...itemForm, products: updateProducts });
  };

  const handleAddItem = () => {
    toast.remove();

    const validation = validateItemQuotingForm.validate(itemForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        if (ele.type === "any.required" && ele.context.label !== "custom") {
          newErrorForm.products.error = true;
          newErrorForm.products.message = "Product or treatment required";
          newErrorForm.treatments.error = true;
          newErrorForm.treatments.message = "Product or treatment required";
        } else {
          newErrorForm[ele.context.label].error = true;
          newErrorForm[ele.context.label].message =
            "Required field or invalid format";
        }
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      const prevProductTreatments = quotation?.items
        ? Object.values(quotation.items).map((item) => ({
            id: Number(item.productTreatmentId),
            quantity: item.quantity,
            package: packageName,
            discount: item.discount,
          }))
        : [];
      const newProductTreatments = itemForm?.treatments?.map((treatment) => ({
        id: Number(treatment.id),
        quantity: 1,
        package: packageName,
      }));

      const newProducts = itemForm?.products?.map((product) => ({
        id: Number(product.id),
        quantity: 1,
        package: packageName,
      }));

      const productsTreatments = [
        ...prevProductTreatments,
        ...newProductTreatments,
        ...newProducts,
      ];
      addItem.reset();
      addItem.mutate(
        { productsTreatments },
        {
          onSuccess: (res) => {
            const updatedQuotation = {
              ...res.data.data,
              items: res.data.data.items.map((item) => {
                const originalItem = productsTreatments.find(
                  (originalItem) =>
                    originalItem.id === Number(item.productTreatmentId)
                );
                return {
                  ...item,
                  tax: Number(item.tax),
                  discount: originalItem?.discount
                    ? originalItem.discount
                    : item.discount,
                };
              }),
            };
            setQuotation(updatedQuotation);
            handleCloseModal();
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                "Error trying to add an item"
            );
          },
        }
      );
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"405px"}>
      <Modal.Header
        title={"Add new Item"}
        align="-webkit-center"
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="18px 0 0 0"
        maxHeight={"693px"}
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" m="0 0 24px 0">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              Select one or multiple categories and add one or multiple products
              or treatments to your quote.
            </Text>
          </Row>

          <Row width="100%" m="0 0 5px 0">
            <Select
              label={"Treatment Categories*"}
              id="treatmentsCategories"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"335px"}
              defaultValue=""
              value=""
              options={filterCat.treatmentCategoriesList}
              onChange={(val, item) =>
                handleSelect(val, item, "treatmentsCategories")
              }
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              error={errorForm.treatmentsCategories.error}
              helper={
                errorForm.treatmentsCategories.error
                  ? errorForm.treatmentsCategories.message
                  : ""
              }
            />
          </Row>
          <Row
            width="100%"
            m="0 0 5px 0"
            align="left"
            style={{ flexWrap: "wrap" }}
          >
            {itemForm?.treatmentsCategories.map((cat, idx) => (
              <Tag
                key={idx}
                closable
                onClose={() => removeTreatmentCategory(cat)}
                closeIcon={<CloseIcon stroke={theme.colors.gray500} />}
                color={theme.colors.gray50}
                width="150px"
              >
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.gray500}
                >
                  {cat.name}
                </Text>
              </Tag>
            ))}
          </Row>

          <Row width="100%" m="0 0 5px 0">
            <Select
              label={"Treatments"}
              id="treatments"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"335px"}
              defaultValue=""
              value=""
              options={treatmentsListOptions}
              onChange={(val, item) => handleSelect(val, item, "treatments")}
              autoComplete="off"
              optionFilterProp="children"
              onSearch={(val) => handleFilter(val, "treatment")}
              filterOption={() => true}
              showSearch
              error={errorForm.treatments.error}
              helper={
                errorForm.treatments.error ? errorForm.treatments.message : ""
              }
            />
          </Row>
          <Row
            width="100%"
            m="0 0 32px 0"
            align="left"
            style={{ flexWrap: "wrap" }}
          >
            {itemForm?.treatments.map((treat, idx) => (
              <Tag
                key={idx}
                closable
                onClose={() => removeTreatment(treat)}
                closeIcon={<CloseIcon stroke={theme.colors.gray500} />}
                color={theme.colors.gray50}
                width="150px"
              >
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.gray500}
                >
                  {treat.name}
                </Text>
              </Tag>
            ))}
          </Row>

          <Row width="100%" m="0 0 5px 0">
            <Select
              label={"Product Categories*"}
              id="productsCategories"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"335px"}
              defaultValue=""
              value=""
              options={filterCat.productCategoriesList}
              onChange={(val, item) =>
                handleSelect(val, item, "productsCategories")
              }
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              error={errorForm.productsCategories.error}
              helper={
                errorForm.productsCategories.error
                  ? errorForm.productsCategories.message
                  : ""
              }
            />
          </Row>
          <Row
            width="100%"
            m="0 0 5px 0"
            align="left"
            style={{ flexWrap: "wrap" }}
          >
            {itemForm?.productsCategories.map((cat, idx) => (
              <Tag
                key={idx}
                closable
                onClose={() => removeProductCategory(cat)}
                closeIcon={<CloseIcon stroke={theme.colors.gray500} />}
                color={theme.colors.gray50}
                width="150px"
              >
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.gray500}
                >
                  {cat.name}
                </Text>
              </Tag>
            ))}
          </Row>
          <Row width="100%" m="0 0 5px 0">
            <Select
              label={"Products"}
              id="products"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"335px"}
              defaultValue=""
              value=""
              options={productsListOptions}
              onChange={(val, item) => handleSelect(val, item, "products")}
              autoComplete="off"
              optionFilterProp="children"
              onSearch={(val) => handleFilter(val, "product")}
              filterOption={() => true}
              showSearch
              error={errorForm.products.error}
              helper={
                errorForm.products.error ? errorForm.products.message : ""
              }
            />
          </Row>
          <Row
            width="100%"
            m="0 0 32px 0"
            align="left"
            style={{ flexWrap: "wrap" }}
          >
            {itemForm?.products.map((prod, idx) => (
              <Tag
                key={idx}
                closable
                onClose={() => removeProduct(prod)}
                closeIcon={<CloseIcon stroke={theme.colors.gray500} />}
                color={theme.colors.gray50}
                width="150px"
              >
                <Text
                  size={theme.fonts.size.sm}
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.gray500}
                >
                  {prod.name}
                </Text>
              </Tag>
            ))}
          </Row>

          <Row width="100%" p="0px 0px 16px 0px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleAddItem}
              loading={addItem.isLoading}
              width="100%"
              background={theme.colors.green}
            >
              Add items
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalItems;
