import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetSalesByUser = ({ clinicId, userId, days, startDate, endDate }) => {
  let urlString = `/quote/sales-bonus/byperiod?clinicId=${clinicId}&userId=${userId}`;
  if (days) {
    urlString += `&days=${days}`;
  }

  if (!days) {
    urlString += `&startDate=${startDate}&endDate=${endDate}`;
  }

  const data = useQuery(
    ["salesByUserByPeriod", clinicId, userId, days, startDate, endDate],
    () => axiosClient.get(urlString),
    { enabled: !!userId }
  );

  return data || {};
};
export default useGetSalesByUser;
