import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetClinicById = (clinicId) => {
  const data = useQuery(
    ["getClinic", clinicId],
    () => clinicId && axiosClient.get(`/clinic/${clinicId}`),
    { enabled: clinicId !== undefined && clinicId !== null }
  );
  return data;
};
export default useGetClinicById;
