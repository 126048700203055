import { Div, Text } from "../../../styles/Common";
import { Radio } from "antd";
import { theme } from "../../../styles/theme";

const RadioGroup = ({
  name,
  defaultValue,
  gap,
  direction,
  disabled,
  helper,
  error,
  onChange,
  children,
  ...rest
}) => {
  return (
    <div style={{ flexDirection: "column" }}>
      <Radio.Group
        name={name}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      >
        <Div gap={gap} direction={direction}>
          {children}
        </Div>
      </Radio.Group>
      {helper && (
        <Text
          size={theme.fonts.size.xs}
          color={theme.colors.red}
          m="2px 20px 0 20px"
        >
          {helper}
        </Text>
      )}
    </div>
  );
};

RadioGroup.defaultProps = {
  name: "default",
  gap: 8,
  direction: "row",
  disabled: false,
  error: false,
};

export default RadioGroup;
