import React from "react";
import { BlurredCard } from "./styles";

export const BlurCard = ({ children, width, height, ...rest }) => {
  return (
    <BlurredCard width={width} height={height} {...rest}>
      {children}
    </BlurredCard>
  );
};
