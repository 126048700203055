import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetProfile = () => {
  const data = useQuery(
    ["getProfile"],
    () => axiosClient.get(`/profile`),

  );
  return data;
};
export default useGetProfile;