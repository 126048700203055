import { PhotoCard } from "../../styles";
import { AddPhotoIcon } from "../../../../../assets/icons";
import { theme } from "../../../../../styles/theme";
import { Col, Div, Row, Text } from "../../../../../styles/Common";
import { Upload } from "antd";
import { Input } from "../../../../../components/Input";
import { Radio } from "../../../../../components/Radio";

const FormUserCard = ({
  handleUploadLogo,
  imageLogo,
  prevImageLogo,
  handleChangeUser,
  userForm,
  errorForm,
}) => {
  const image = prevImageLogo;

  return (
    <Col m="0px 28px 0px 28px" width="100%">
      <Row
        align="center"
        m="0px 0px 10px 0px"
        style={{ justifyContent: "center" }}
      >
        <Upload
          multiple={false}
          showUploadList={false}
          customRequest={handleUploadLogo}
        >
          {image ? (
            <PhotoCard background={image} />
          ) : (
            <PhotoCard>
              <AddPhotoIcon stroke={theme.colors.gray500} />
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray500}
              >
                Add photo
              </Text>
            </PhotoCard>
          )}
        </Upload>
      </Row>
      <Row align="center" m="0px 0px 15px 0px">
        <Input
          label={"First Name*"}
          id="name"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          value={userForm.name}
          onChange={handleChangeUser}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 16px 0px">
        <Input
          label={"Last Name*"}
          id="lastname"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          value={userForm.lastname}
          onChange={handleChangeUser}
          error={errorForm.lastname.error}
          helper={errorForm.lastname.error ? errorForm.lastname.message : ""}
        />
      </Row>
      <Input
        label={"Phone number*"}
        id="phone"
        weightLabel={theme.fonts.weight.regular}
        sizeLabel={theme.fonts.size.sm}
        border={theme.colors.gray500}
        background={theme.colors.gray600}
        color={theme.colors.gray400}
        width="100%"
        value={userForm.phone}
        onChange={handleChangeUser}
        error={errorForm.phone.error}
        helper={errorForm.phone.error ? errorForm.phone.message : ""}
      />
    </Col>
  );
};

export default FormUserCard;
