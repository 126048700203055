import styled from "styled-components";
import { Table } from "antd";
import { theme } from "../../styles/theme";
import { next, prev } from "../../assets/icons";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray700};
`;

export const CustomTable = styled(Table)`
  background-color: ${theme.colors.gray700};
  border-radius: 22px;

  .table-row-light {
    background-color: ${theme.colors.gray600};
  }
  .table-row-dark {
    background-color: ${theme.colors.gray800};
  }

  .ant-table {
    background-color: transparent;
  }

  &&& {
    .ant-table {
      background: transparent;
      border: ${theme.colors.gray600};
      &-empty {
        background: transparent;
      }

      &-column-sorters {
        height: 45px;
        display: contents;
        .ant-table-column-sorter-inner {
          color: ${theme.colors.gray500};
        }
      }
      &-thead {
        th {
          color: ${theme.colors.gray500};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.medium};
          background-color: ${theme.colors.gray950};
          border: ${theme.colors.gray600};
          font-family: ${({ font }) => font || theme.fonts.family};
          height: 45px;
          &:first-child {
            border-top-left-radius: 22px;
          }
          &:last-child {
            border-top-right-radius: 22px;
          }
          &:before {
            display: none;
          }
        }
      }
      &-tbody {
        td {
          padding: 8px 12px;
          background-color: ${theme.colors.gray600};
          color: ${theme.colors.gray50};
          font-size: ${theme.fonts.size.sm};
          font-weight: ${theme.fonts.weight.medium};
          font-family: ${({ font }) => font || theme.fonts.family};
          max-width: 212px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        tr:nth-child(even) td {
          background-color: ${theme.colors.gray800};
          border: ${theme.colors.gray600};
        }

        tr:nth-child(odd) td {
          background-color: ${theme.colors.gray600};
          border: ${theme.colors.gray600};
        }
        .ant-table-cell-row-hover {
          background-color: ${theme.colors.green};
        }
        .ant-table-cell {
          height: 50px;
        }
        .ant-table-cell-row {
          height: 50px;
        }
      }
      &-container {
        border-radius: 22px;
        overflow: hidden;
      }
      &-column-title {
        flex: auto;
        padding: 16px 0;
      }

      // Filter
      &-filter {
        &-column {
          display: flex;
          align-items: center;
          margin: -16px 0;
          &-title {
            padding-left: 0px;
          }
        }

        &-trigger {
          cursor: pointer;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 55px;
          transform: translateX(-12px);

          i {
            display: flex;
            align-items: center;
          }

          &-container {
            &-open,
            &:hover {
              background: transparent;
            }
          }
        }
      }
      /* ### CHECKBOX ### */
      .ant-checkbox-wrapper {
        .ant-checkbox-inner {
          border-radius: 4px;
          border-color: ${theme.colors.gray100};
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            border-color: ${theme.colors.green};
            background-color: ${theme.colors.green};
          }

          &:hover {
            &:after {
              border-color: transparent;
            }
          }

          &:after {
            border-color: transparent;
          }
        }

        &:hover {
          & .ant-checkbox {
            &:not(.ant-checkbox-disabled):not(.ant-checkbox-checked) {
              .ant-checkbox-inner {
                border-color: ${theme.colors.green};
                background-color: hsl(26, 100%, 50%, 0.1);
              }
            }
          }
        }
      }
    }

    /* ### PAGINATION ### */
    .ant-pagination {
      gap: 8px;
      align-items: center;
      margin-top: 37px;

      &-item,
      &-prev,
      &-next {
        min-width: 35px;
        height: 35px;
        margin: 0;
      }

      /* ### PAGINATION - ITEM ### */
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${theme.colors.white};
        opacity: 1;
        border-radius: 9px;
        a {
          color: ${theme.colors.gray400};
          font-size: ${theme.fonts.size.h6};
          font-weight: ${theme.fonts.weight.medium};
          font-family: ${({ font }) => font || theme.fonts.family};
        }
        &:hover {
          background-color: ${theme.colors.green};
          border: 0;
          a {
            color: ${theme.colors.white};
            font-weight: ${theme.fonts.weight.bold};
            font-size: ${theme.fonts.size.h5};
          }
        }
        &-active {
          background-color: ${theme.colors.green};
          border: none;

          a {
            color: ${theme.colors.white};
            font-weight: ${theme.fonts.weight.bold};
            font-size: ${theme.fonts.size.h5};
          }
        }
      }

      /* ### PAGINATION - PREV & NEXT ### */

      &-prev {
        display: flex;
        align-items: center;
        .anticon {
          color: ${({ theme }) => theme.colors.white};
          font-family: "remixicon";

          svg {
            display: none;
          }
          &:hover {
            background-color: ${theme.colors.green};
            border-radius: 9px;
          }
        }
        &:before {
          content: "${(props) => props.sizerLabels[2]}";
          margin-right: 12px;
          display: inline-block;
          color: ${theme.colors.gray50};
          font-weight: ${theme.fonts.weight.regular};
          font-size: ${theme.fonts.size.sm};
          font-family: ${({ font }) => font || theme.fonts.family};
        }
      }

      &-prev,
      &-next {
        .anticon {
          color: ${({ theme }) => theme.colors.white};
          font-family: "remixicon";

          svg {
            display: none;
          }

          &:after {
            font-style: normal;
            font-size: 24px;
            font-weight: 400;
            line-height: 1;
          }

          &:hover {
            background-color: ${theme.colors.green};
            border-radius: 9px;
          }
        }
      }

      &-prev {
        .anticon {
          &:after {
            content: "\\ea64";
          }
        }
      }

      &-next {
        .anticon {
          &:after {
            content: "\\ea6e";
          }
        }
      }

      &-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 8px;
      }

      /* ### PAGINATION - ITEMS PER PAGE */
      &-options {
        order: 1;
        margin-right: auto;
        margin-left: 10;

        .ant-select {
          cursor: default;
          display: flex;
          align-items: center;
          &:before {
            content: "${(props) => props.sizerLabels[0]}";
            margin-right: 12px;
          }
          &:after {
            content: "${(props) => props.sizerLabels[1]}";
            margin-left: -8px;
          }
          &:before,
          &:after {
            display: inline-block;
            color: ${theme.colors.gray50};
            font-weight: ${theme.fonts.weight.regular};
            font-size: ${theme.fonts.size.sm};
            font-family: ${({ font }) => font || theme.fonts.family};
          }
          &-selector {
            cursor: pointer;
            flex: 1;
            border-radius: 9px;
            border: none;
            height: 30px;
            background: ${theme.colors.gray700};
          }
          &.ant-select-open {
            .ant-select-arrow {
              .anticon {
                transform: rotate(180deg);
              }
            }
          }
          &-arrow {
            position: relative;
            margin-top: 0;
            right: 28px;
            width: initial;
            height: initial;
            .anticon {
              color: ${theme.colors.gray50};
              font-family: "remixicon";
              font-size: 14px;
              line-height: 1;
              &:after {
                content: "\\ea4e";
              }
              svg {
                display: none;
              }
            }
          }
          &-selection-item {
            color: ${theme.colors.gray50};
            font-weight: ${theme.fonts.weight.regular};
            font-size: ${({ theme }) => theme.fonts.size.sm};
            font-family: ${({ font }) => font || theme.fonts.family};
          }
          &-dropdown {
            min-width: 80px !important;

            .ant-select-item-option {
              color: ${theme.colors.gray700};

              &:hover {
                background-color: ${theme.colors.green};
              }

              &-selected {
                background-color: ${theme.colors.gray500};
              }
            }
          }
        }
      }
      &-prev {
        order: 2;
      }
      &-jump-prev {
        order: 3;
      }
      &-jump-next {
        order: 3;
      }
      &-item {
        order: 3;
      }
      &-next {
        order: 4;
      }
    }
  }
`;
