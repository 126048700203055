import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPackagesDropdown = (clinicId) => {
  let urlString = `/package/byclinic/${clinicId}`;

  const data = useQuery(
    ["packagestListDropdown", clinicId],
    () => clinicId && axiosClient.get(urlString)
  );

  return data || {};
};
export default useGetPackagesDropdown;
