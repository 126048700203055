import styled from "styled-components";
import { theme } from "../../styles/theme";

export const CustomCard = styled.div`
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  background: ${theme.colors.white} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.09);
  border-radius: 30px;
  opacity: 1;
  padding: 34px;
`;
