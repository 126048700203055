import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../styles/theme";

export const WrapperLayout = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
`;

export const WrapperContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background: ${theme.colors.gray950} 0% 0% no-repeat padding-box;
  opacity: 1;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06);
  position: relative;
  min-height: 100vh;
`;

export const Sider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 20px 0px 0px;
  height: 100%;
  width: ${(props) => (props.isLayoutOpen ? "295px" : "45px")};
  background: ${theme.colors.gray950} 0% 0% no-repeat padding-box;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.06);
  &::-webkit-scrollbar {
    width: 0px; /* Para navegadores WebKit (Chrome y Safari) */
  }
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Content = styled.div`
  position: relative;
  padding: 32px;

  padding-left: ${(props) =>
    props.isLayoutOpen
      ? "calc(0.5 * (100% - 1345px))"
      : "calc(0.5 * (100% - 1090px))"};
  height: 100%;
  width: ${(props) =>
    props.isLayoutOpen ? "calc(100% - 295px)" : "calc(100% - 45px)"};
  background: ${theme.colors.gray700} 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  justify-content: center;
`;

export const ProfileCard = styled(Link)`
  display: flex;
  align-items: center;
  height: 77px;
  min-height: 77px;
  width: ${(props) => (props.isLayoutOpen ? "240px" : "30px")};
  margin: ${(props) =>
    props.isLayoutOpen ? "26px 20px 20px 20px" : "26px 0px 20px 20px"};
  background: ${theme.colors.gray850} 0% 0% no-repeat padding-box;
  border-radius: ${(props) => (props.isLayoutOpen ? "18px" : "10px")};
  border: 1px solid ${theme.colors.gray750};
  opacity: 1;
  cursor: pointer;
  z-index: 1;
  text-decoration: none;
`;

export const ProfileImage = styled.div`
  height: 45px;
  width: 45px;
  margin: 0 0 0 16px;
  padding: 12px 0 12px 0;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray800} 0% 0% no-repeat padding-box`};
  background-size: cover;
  border-radius: 100%;
  opacity: 1;
  cursor: pointer;
  text-align: center;
`;

export const WrapperLogout = styled.div`
  display: flex;
  width: ${(props) => (props.isLayoutOpen ? "240px" : "50px")};
  justify-content: center;
  margin: ${(props) =>
    props.isLayoutOpen ? "22px 20px 10px 20px" : "22px 0px 10px 10px"};
  gap: ${(props) => (props.isLayoutOpen ? "155px" : "50px")};
  cursor: pointer;
  margin-top: auto;
`;

export const WrapperRights = styled.div`
  display: flex;
  width: 280px;
  justify-content: center;
  margin: 22px 0 5px 0;
  align-items: center;
  gap: 8px;
  margin-top: auto;
`;

export const WaveWrapper = styled.div`
  position: absolute;
  width: 120px;
  height: 650px;
  z-index: 0;
`;
