import styled from "styled-components";
import { Document, Page } from "react-pdf";
export const PDFContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledDocument = styled(Document)`
  /* Estilos para el componente Document */
  margin: 20px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  padding: 10px;
  max-width: 80%;
  width: 600px;
  max-height: 100%;
  overflow-y: scroll;
`;

export const StyledPage = styled(Page)`
  max-width: 100%;
  width: 100%;
  display: block;
  margin: 0 auto;

  canvas.react-pdf__Page__canvas {
    /* Estilos específicos para el canvas */
    border: 1px solid #ddd; /* Ejemplo: Agregar un borde al canvas */
    width: 100% !important;
    height: auto !important;
  }
`;
