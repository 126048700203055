import styled from "styled-components";
import { theme } from "../../../../styles/theme";

const statusColor = (status) => {
  switch (status) {
    case "inProgress":
      return theme.colors.blue40;
    case "overdue":
      return theme.colors.red40;
    case "pending":
      return theme.colors.yellow40;
    case "paid":
      return theme.colors.green40;
    default:
      return theme.colors.gray600;
  }
};

export const QuoteCard = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 24px;
  align-items: center;
  min-height: 102px;
  width: 100%;
  padding: 30px 25px 25px 20px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
`;

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding: 8px 16px 8px 16px;
  background: ${({ status }) => statusColor(status)};
  border-radius: 21px;
`;
