import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 196px;
  width: 1004px;
  padding: 27px 29px 28px 29px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const ContainerCard = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  opacity: 1;
`;
