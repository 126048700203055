import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Row, Text, Div } from "../../../../styles/Common";
import { Drawer } from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { Radio } from "../../../../components/Radio";
import { CloseIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import { isSuperAdmin, userClinic } from "../../../../utilities/helpers";
import { validateCategoryForm } from "../../../../utilities/validations";
import useCreateCategory from "../../hooks/useCreateCategory";
import useEditCategory from "../../hooks/useEditCategory";

const DrawerCategory = ({
  showDrawer,
  closeDrawer,
  handleCloseDrawerSuccess,
  actualInfo,
  optionsClinics,
  setIsEditing,
  setCategoryToEdit,
}) => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const { name: userClinicName, id: userClinicId } = userClinic();

  const createCategory = useCreateCategory();
  const editCategory = useEditCategory();

  const initialErrors = {
    name: {
      error: false,
      message: "",
    },
    type: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
    bonus: {
      error: false,
      message: "",
    },
    bonusType: {
      error: false,
      message: "",
    },
  };

  const [forceUpdate, setForceUpdate] = useState(false);
  const [categoryForm, setCategoryForm] = useState({
    name: actualInfo?.name,
    type: actualInfo?.category_type,
    clinic: actualInfo?.clinic?.id?.toString(),
    bonus: actualInfo?.bonus_value,
    bonusType: actualInfo.bonus_type,
  });
  const [errorForm, setErrorForm] = useState(initialErrors);

  useEffect(() => {
    setCategoryForm({
      name: actualInfo?.name,
      type: actualInfo?.category_type,
      clinic: actualInfo?.clinic?.id?.toString(),
      bonus: actualInfo?.bonus_value,
      bonusType: actualInfo.bonus_type,
    });
  }, [actualInfo]);

  const handleChangeCategory = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newCategoryForm = categoryForm;

    newCategoryForm[id] = value;

    setCategoryForm(newCategoryForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newCategoryForm = categoryForm;
    newCategoryForm[id] = value;

    setCategoryForm(newCategoryForm);
    setForceUpdate(!forceUpdate);
  };

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    const newCategoryForm = categoryForm;
    newCategoryForm[name] = value;
    setCategoryForm(newCategoryForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setErrorForm(initialErrors);
  };

  const handleCreateCategory = () => {
    toast.remove();

    if (!categoryForm["clinic"]) {
      const newCategoryForm = categoryForm;
      newCategoryForm["clinic"] = userClinicId;
      setCategoryForm(newCategoryForm);
    }
    const nameClinic = optionsClinics.find(
      (cl) => Number(cl.value) === Number(categoryForm.clinic)
    );

    const validation = validateCategoryForm.validate(categoryForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      if (actualInfo.id) {
        setCategoryToEdit({
          id: actualInfo.id,
          name: categoryForm.name,
          category_type: categoryForm.type,
          clinicId: categoryForm.clinic,
          bonus_type: categoryForm.bonusType,
          bonus_value: categoryForm.bonus,
          position: actualInfo.position,
        });
        editCategory.reset();
        editCategory.mutate(
          {
            id: actualInfo.id,
            name: categoryForm.name,
            category_type: categoryForm.type,
            clinicId: categoryForm.clinic,
            bonus_type: categoryForm.bonusType,
            bonus_value: categoryForm.bonus,
            position: actualInfo.position,
          },
          {
            onSuccess: () => {
              setIsEditing(true);
              handleCloseDrawerSuccess();
              queryClient.invalidateQueries(["getCategories"]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to edit a category"
              );
            },
          }
        );
      } else {
        createCategory.reset();
        createCategory.mutate(
          {
            id: null,
            name: categoryForm.name,
            category_type: categoryForm.type,
            clinicId: categoryForm.clinic,
            bonus_type: categoryForm.bonusType,
            bonus_value: categoryForm.bonus,
          },
          {
            onSuccess: () => {
              handleCloseDrawerSuccess();
              queryClient.invalidateQueries(["getCategories"]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to create a category"
              );
            },
          }
        );
      }
    }
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      actualInfo={actualInfo}
    >
      <Div
        style={{ borderLeft: `5px solid ${theme.colors.green}` }}
        p="0px 0px 0px 16px"
        weight="5px"
        height="20px"
        align="center"
        m="6px 0px 0px 0px"
      >
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          {actualInfo.id ? "Edit Category" : "New Category"}
        </Text>
      </Div>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          {actualInfo.id
            ? "Complete the following information to create a category."
            : "Complete the following information to create a new category."}
        </Text>
      </Row>
      <Row m="24px 0px 0px 0px">
        {superAdmin ? (
          <Select
            label={actualInfo.id ? "Clinic" : "Select Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            defaultValue=""
            value={categoryForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            disabled={actualInfo.id ? true : false}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Clinic"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            value={userClinicName}
            disabled
          />
        )}
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Category Name"}
          id="name"
          value={categoryForm.name}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          maxLength={50}
          onChange={handleChangeCategory}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Category Type
        </Text>
      </Row>
      <Row m="16px 0px 0px 0px">
        <Radio.Group
          name="type"
          value={categoryForm.type}
          gap={"7px"}
          direction="row"
          onChange={handleRadio}
          error={errorForm.type.error}
          helper={errorForm.type.error ? errorForm.type.message : ""}
        >
          <>
            <Radio value={1} key={1} background={theme.colors.white} />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Treatment
            </Text>
          </>
          <>
            <Radio value={2} key={2} background={theme.colors.white} />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Product
            </Text>
          </>
        </Radio.Group>
      </Row>
      <Row m="40px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.default}
          color={theme.colors.gray800}
        >
          Sales Bonus
        </Text>
      </Row>
      <Row m="16px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          The bonus value you set here will be applied as the default to all
          items in this category. You can customize the bonus for each
          individual item at any time.
        </Text>
      </Row>
      <Row m="19px 0px 0px 0px">
        <Radio.Group
          name="bonusType"
          value={categoryForm.bonusType}
          gap={"7px"}
          direction="row"
          onChange={handleRadio}
          error={errorForm.bonusType.error}
          helper={errorForm.bonusType.error ? errorForm.bonusType.message : ""}
        >
          <>
            <Radio value={1} key={1} background={theme.colors.white} />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Dollar
            </Text>
          </>
          <>
            <Radio value={2} key={2} background={theme.colors.white} />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Percentage
            </Text>
          </>
        </Radio.Group>
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Bonus"}
          id="bonus"
          prefix={categoryForm.bonusType === 1 && "$"}
          suffix={categoryForm.bonusType === 2 && "%"}
          value={categoryForm.bonus}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangeCategory}
          error={errorForm.bonus.error}
          helper={errorForm.bonus.error ? errorForm.bonus.message : ""}
        />
      </Row>
      <Row m="16px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          If there are existing items in the category, you'll be asked whether
          you'd like to update them with the new value or keep their current
          bonuses after saving.
        </Text>
      </Row>
      <Row m="47px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="290px"
          background={theme.colors.green}
          onClick={handleCreateCategory}
          loading={createCategory.isLoading || editCategory.isLoading}
        >
          {actualInfo.id ? "Save Information" : "Create Category"}
        </Button>
      </Row>
      <Row m="0px 0px 16px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          color={theme.colors.green}
          background={theme.colors.green100}
          border={theme.colors.green}
          onClick={handleCloseDrawer}
          width="290px"
        >
          Cancel
        </Button>
      </Row>
    </Drawer>
  );
};

export default DrawerCategory;
