import { useState } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { CancelIcon, CheckCircleIcon } from "../../../../assets/icons";
import useChangeState from "../../hooks/useChangeState";
import { DatePicker } from "../../../../components/DatePicker";

const ModalChangeState = ({ showModal, handleCloseModal }) => {
  const queryClient = useQueryClient();
  const changeState = useChangeState();

  const { packages } = useSelector((state) => state);

  const packageState = packages?.selectedPackage?.state;

  const [dueDate, setDueDate] = useState(
    dayjs(packages?.selectedPackage?.dueDate)
  );

  const handleSelect = (value, option, id) => {
    setDueDate(value);
  };

  const handleChangeState = (state) => {
    changeState.reset();
    changeState.mutate(
      {
        formData: {
          state: state,
          oldState: packages?.selectedPackage?.state,
          dueDate: dueDate,
        },
        packageId: packages?.selectedPackage?.id,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["packagestList"]);
          toast.success("Package updated successfully.");
          handleCloseModal();
        },
        onError: (err) => {
          toast.error(
            err.response.data.error.message ||
              "Error trying to delete the product"
          );
        },
      }
    );
  };
  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"407px"}>
      <Modal.Header
        icon={
          packageState === "active" ? (
            <CancelIcon
              fill={theme.colors.red}
              width={"38px"}
              height={"38px"}
            />
          ) : (
            <CheckCircleIcon
              fill={theme.colors.green}
              width={"40px"}
              height={"40px"}
            />
          )
        }
        iconBorderColor={
          packageState === "active" ? theme.colors.red : theme.colors.green
        }
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        padding={"20px 20px 0px 20px"}
        title={
          packageState === "active" ? "Deactivate Package" : "Active package"
        }
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center">
          <Row width="100%" p="0 27px 0px 27px" justify="center">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              {packageState === "active"
                ? "Are you sure you want to deactivate this package?"
                : "This package will be activated"}
            </Text>
          </Row>
          {packageState !== "active" && (
            <Row width="100%" p="20px 27px 0px 27px" justify="center">
              <DatePicker
                label={"Expiration date"}
                id="dueDate"
                sizeLabel={theme.fonts.size.sm}
                colorLabel={theme.colors.gray500}
                border={theme.colors.gray500}
                background={theme.colors.white}
                color={theme.colors.gray500}
                width={"290px"}
                placeholder={""}
                value={dueDate}
                onChange={(value) => handleSelect(value)}
              />
            </Row>
          )}
          <Row width="100%" p="30px 60px 0px 60px" justify="center">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={() =>
                handleChangeState(
                  packageState === "active" ? "inactive" : "active"
                )
              }
              width="100%"
              background={theme.colors.green}
              loading={changeState.isLoading}
            >
              {packageState === "active" ? "Yes, Deactivate" : "Activate"}
            </Button>
          </Row>

          <Row width="100%" p="16px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleCloseModal}
              width="100%"
              background={theme.colors.green100}
              color={theme.colors.green}
              border={theme.colors.green}
            >
              No, Cancel
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalChangeState;
