import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Div, Text, Container } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Skeleton } from "../../components/Skeleton";
import Table from "../../components/Table";
import { theme } from "../../styles/theme";
import { ClientsIcon, DeleteIcon, EditIcon, EyeIcon } from "../../assets/icons";
import useGetPatients from "./hooks/useGetPatients";
import useDebounce from "../../core/hooks/useDebounce";
import { Link } from "../../components/Link";
import DrawerPatient from "./componenets/DrawerPatient";
import ModalPatient from "./componenets/ModalPatient";
import { setSelectedPatientInformation } from "../../core/store/patients";
import { isRegular, formattedDate } from "../../utilities/helpers";

export const Patients = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regular = isRegular();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};
  const optionsCodes = common?.codesList || {};

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDeliting, setIsDeliting] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(null);

  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [sortOptions, setSortOptions] = useState({
    sortBy: null,
    sortDirection: null,
  });

  const [totalItems, setTotalItems] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const searchValue = useDebounce(searchText, 600);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
    sortDirection: sortOptions.sortDirection,
    sortBy: sortOptions.sortBy,
    searchBy: searchValue,
  });

  const initialValues = {
    id: null,
    email: "",
    name: "",
    lastname: "",
    callingCode: "+1",
    phone: null,
    clinicId: null,
    clinicName: "",
  };
  const getPatients = useGetPatients(payloadOptions);
  const { data, isLoading, isFetching } = getPatients;
  const [patientsList, setPatientsList] = useState([]);

  const [actualInfo, setActualInfo] = useState(initialValues);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      const dataTable = data.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: <EditIcon stroke={theme.colors.gray50} />,
            onClick: (row) => {
              setActualInfo({
                ...row,
              });
              setShowDrawer(true);
            },
          },
          {
            key: `1${i}-view`,
            id: ele.id,
            icon: <EyeIcon stroke={theme.colors.gray50} />,
            onClick: (row) => {
              dispatch(setSelectedPatientInformation(row));
              navigate("view-patient");
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: <DeleteIcon stroke={theme.colors.gray50} />,
            onClick: (row) => {
              setSelectedPatientId(row.id);
              setIsDeliting(true);
              setShowModal(true);
            },
          },
        ],
      }));
      setPatientsList(dataTable);
      setTotalItems(data.data.data.total);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
      sortBy: sortOptions.sortBy,
      sortDirection: sortOptions.sortDirection,
      searchBy: searchValue,
    });
  }, [pages, sortOptions, searchValue]);

  useEffect(() => {}, [actualInfo]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleChangeTable = (pagination, filter, sort) => {
    if (sort && sort.columnKey) {
      setSortOptions({
        sortBy: sort.columnKey,
        sortDirection: sort.order === "ascend" ? "ASC" : "DESC",
      });
    } else {
      setSortOptions({ sortBy: null, sortDirection: null });
    }

    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setForceUpdate(!forceUpdate);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "C. Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 120,
        sorter: true,
        showSorterTooltip: false,
        render: (value) => {
          return `${formattedDate(value)}`;
        },
      },
      {
        title: "Patient Name",
        dataIndex: "name",
        key: "name",
        width: 208,
        sorter: true,
        ellipsis: true,
        showSorterTooltip: false,
        render: (value, item) =>
          `${value ? `${item.name} ${item.lastname}` : ""}`,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 196,
        sorter: false,
        ellipsis: true,
        showSorterTooltip: false,
        render: (value) => `${value}`,
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        width: 162,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => `${item.callingCode} ${value}`,
      },
      {
        title: "Clinic Name",
        dataIndex: "clinicName",
        key: "clinicName",
        sorter: false,
        ellipsis: true,
        showSorterTooltip: false,
        width: 173,
        render: (value) => `${value ? value : ""}`,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 160,
        render: (actions, row) => {
          return (
            <Div gap="10px">
              {actions &&
                actions.map((action, i) =>
                  !regular || !action.key.includes("delete") ? (
                    <Link
                      key={i}
                      onClick={() => action.onClick(row)}
                      color={theme.colors.gray50}
                      icon={action.icon}
                      styles={{
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                    />
                  ) : null
                )}
            </Div>
          );
        },
      },
    ],
    [patientsList]
  );

  const handleCloseDrawerSuccess = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setShowModal(true);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseModal = () => {
    setIsDeliting(false);
    setSelectedPatientId(null);
    setShowModal(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <ModalPatient
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedPatientId={selectedPatientId}
        isDeliting={isDeliting}
      />

      <DrawerPatient
        showDrawer={showDrawer}
        closeDrawer={handleCloseDrawer}
        handleCloseDrawerSuccess={handleCloseDrawerSuccess}
        actualInfo={actualInfo}
        optionsClinics={optionsClinics}
        optionsCodes={optionsCodes}
      />
      <Row
        height="40px"
        justify="space-between"
        align="center"
        m="0px 0px 37px 0px"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Patients
          </Text>
        </Div>

        <Div gap="10px" align="center">
          <Input
            placeholder="Search and filter by name, email or phone number"
            color={theme.colors.gray400}
            width="597px"
            border={theme.colors.gray800}
            onChange={handleChangeSearch}
            background={theme.colors.gray800}
          />

          <Button
            width="183px"
            background={theme.colors.green}
            onClick={() => setShowDrawer(true)}
          >
            <Row width="100%" justify="center">
              <Div gap={"8px"} align={"center"}>
                <ClientsIcon stroke={theme.colors.white} />
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.default}
                  color={theme.colors.white}
                >
                  Create Patient
                </Text>
              </Div>
            </Row>
          </Button>
        </Div>
      </Row>

      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={patientsList}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "patients", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Container>
  );
};
