import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDiscounts = (options) => {
  const data = useQuery(
    ["discountsList"],
    () => axiosClient.get("/discount"),
    options
  );

  return data || {};
};
export default useGetDiscounts;
