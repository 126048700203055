import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { chunk, sumBy } from "lodash";
import { Container } from "../../styles/Common";
import { Row, Div, Text, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { InfoCard, SalesCard } from "./styles";
import { Select } from "../../components/Select";
import { Button } from "../../components/Button";
import {
  isSuperAdmin,
  userClinic,
  isRegular,
  getUserInfo,
  downloadFile,
  isProvider,
} from "../../utilities/helpers";
import { ChevronDownIcon } from "../../assets/icons";
import { reportTimeOptions } from "./helpers";
import { CustomDateModal } from "./components/CustomDateModal";
import { DateRangePicker } from "../../components/DateRangePicker";
import useGetSales from "./hooks/useGetSales";
import useGetCostsProfits from "./hooks/useGetCostsProfits";
import useGetTaxes from "./hooks/useGetTaxes";
import useGetTopSellers from "./hooks/useGetTopSellers";
import { formatPriceToDollar } from "../../utilities/helpers";
import { AreaChart, Area, XAxis, Tooltip } from "recharts";
import { CostProfit } from "./components/CostProfit";
import { GraphTooltip } from "./components/GraphTooltip";
import { Taxes } from "./components/Taxes";
import { TopSellers } from "./components/TopSellers";
import { RegularUser } from "./components/RegularUser";
import toast from "react-hot-toast";
import { axiosClient, getLocalUser } from "../../core/services/axiosInstance";

export const Home = () => {
  const superAdmin = isSuperAdmin();
  const regularUser = isRegular() || isProvider();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const { name: userClinicName, id: userClinicId } = userClinic();
  const { id: userId } = getUserInfo();
  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );

  const [selectedReportTime, setSelectedReportTime] = useState({
    value: 30,
    label: "Last 30 days",
  });
  const [showModal, setShowModal] = useState(false);
  const [customInitialDate, setCustomInitialDate] = useState();
  const [customFinalDate, setCustomFinalDate] = useState();

  const [payloadOptions, setPayloadOptions] = useState({
    clinicId: selectedClinic?.value || 0,
    days: selectedReportTime.value,
    startDate: null,
    endDate: null,
    userId: null,
  });

  const getSales = useGetSales(payloadOptions);
  const { data: dataSales, isLoading } = getSales;
  const [salesList, setSalesList] = useState([]);
  const [groupedSales, setGroupedSales] = useState();

  const getCostsProfits = useGetCostsProfits(payloadOptions);
  const { data: dataCostsProfits, isLoading: isLoadingCostsProfits } =
    getCostsProfits;
  const [costsProfitsList, setCostsProfitsList] = useState([]);
  const [groupedCostProfits, setGropuedCostProfits] = useState();

  const getTaxes = useGetTaxes(payloadOptions);
  const { data: dataTaxes, isLoading: isLoadingTaxes } = getTaxes;
  const [taxesList, setTaxesList] = useState([]);
  const [groupedTaxesList, setGroupedTaxesList] = useState();

  const getTopSellers = useGetTopSellers(payloadOptions);
  const { data: dataTopSellers, isLoading: isLoadingTopSellers } =
    getTopSellers;
  const [topSellersList, setTopSellersList] = useState([]);

  useEffect(() => {
    if (selectedClinic && selectedClinic.value) {
      setPayloadOptions({
        clinicId: selectedClinic.value,
        days: 30,
        startDate: null,
        endDate: null,
        userId: regularUser ? userId : null,
      });
    }
  }, [selectedClinic]);

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "clinic":
        setSelectedClinic(option);
        setSelectedReportTime({ value: 30, label: "Last 30 days" });
        break;
      case "reportTime":
        setCustomInitialDate();
        setCustomFinalDate();
        setSelectedReportTime(option);
        if (option.label === "Customize") {
          setShowModal(true);
        }
        break;
    }
  };

  const handleChangeDate = (value, date) => {
    setCustomInitialDate(value[0]);
    setCustomFinalDate(value[1]);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleGenerateReport = async () => {
    let urlString = `/quote/general-report/byperiod?clinicId=${payloadOptions.clinicId}`;
    if (payloadOptions.days) {
      urlString += `&days=${payloadOptions.days}`;
    }

    if (!payloadOptions.days) {
      urlString += `&startDate=${payloadOptions.startDate}&endDate=${payloadOptions.endDate}`;
    }

    await toast.promise(
      downloadFile(
        urlString,
        `Report-${payloadOptions.clinicId}.xlsx`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const groupItems = (data) => {
    switch (selectedReportTime.value) {
      case 7:
        return chunk(data, 1);
      case 14:
        return chunk(data, 7);
      case 30:
        return chunk(data, 8);
      case 365:
        return chunk(data, 31);
      default:
        return chunk(data, 7);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const groupedSales = groupItems(dataSales?.data?.data);
      setSalesList(dataSales?.data?.data);
      setGroupedSales(groupedSales);
    }
  }, [dataSales, isLoading]);

  useEffect(() => {
    if (!isLoadingCostsProfits) {
      const groupuedCP = groupItems(dataCostsProfits?.data?.data);
      setCostsProfitsList(dataCostsProfits?.data?.data);
      setGropuedCostProfits(groupuedCP);
    }
  }, [dataCostsProfits, isLoadingCostsProfits]);

  useEffect(() => {
    if (!isLoadingTaxes) {
      const groupuedTax = groupItems(dataTaxes?.data?.data);
      setTaxesList(dataTaxes?.data?.data);
      setGroupedTaxesList(groupuedTax);
    }
  }, [dataTaxes, isLoadingTaxes]);

  useEffect(() => {
    if (!isLoadingTopSellers) {
      setTopSellersList(dataTopSellers?.data?.data);
    }
  }, [dataTopSellers, isLoadingTopSellers]);

  useEffect(() => {
    if (selectedClinic?.value) {
      if (selectedReportTime?.value != 0) {
        setPayloadOptions({
          clinicId: selectedClinic.value,
          days: selectedReportTime.value,
          startDate: null,
          endDate: null,
          userId: regularUser ? userId : null,
        });
      } else {
        setPayloadOptions({
          clinicId: selectedClinic.value,
          days: null,
          startDate: !isNaN(new Date(customInitialDate).getTime())
            ? new Date(customInitialDate)
            : new Date(),
          endDate: !isNaN(new Date(customFinalDate).getTime())
            ? new Date(customFinalDate)
            : new Date(),
          userId: regularUser ? userId : null,
        });
      }
    }
  }, [selectedReportTime, customInitialDate, customFinalDate]);

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <CustomDateModal
        showModal={showModal}
        handleClose={handleClose}
        customInitialDate={customInitialDate}
        setCustomInitialDate={setCustomInitialDate}
        customFinalDate={customFinalDate}
        setCustomFinalDate={setCustomFinalDate}
        handleChangeDate={handleChangeDate}
      />
      <Row
        height="40px"
        align="center"
        m="0px 0px 30px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Home
          </Text>
        </Div>
      </Row>
      <InfoCard>
        <Row style={{ justifyContent: "space-between" }}>
          <Row gap="28px">
            <Select
              label={"Clinic"}
              id="clinic"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.gray400}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"249px"}
              defaultValue=""
              value={selectedClinic}
              options={optionsClinics}
              suffixIcon={<ChevronDownIcon stroke={theme.colors.gray400} />}
              onChange={(val, option) => handleSelect(val, option, "clinic")}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              disabled={!superAdmin}
            />

            <Select
              label={"Report Time"}
              id="reportTime"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.gray400}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"196px"}
              defaultValue=""
              value={selectedReportTime}
              options={reportTimeOptions}
              onChange={(val, option) =>
                handleSelect(val, option, "reportTime")
              }
            />
            {selectedReportTime.value === 0 && (
              <DateRangePicker
                label={"Range date"}
                id="rangeDate"
                sizeLabel={theme.fonts.size.sm}
                colorLabel={theme.colors.gray400}
                placeholderColor={theme.colors.red}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"229px"}
                value={[customInitialDate, customFinalDate]}
                onChange={(value, date) => handleChangeDate(value, date)}
              />
            )}
          </Row>
          <Col>
            <Div m="12px" />
            {!regularUser && (
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                color={theme.colors.green}
                background={theme.colors.green100}
                border={theme.colors.green}
                onClick={handleGenerateReport}
                width="189px"
              >
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.default}
                  color={theme.colors.green}
                >
                  Generate reports
                </Text>
              </Button>
            )}
          </Col>
        </Row>
      </InfoCard>
      {!regularUser ? (
        <Div
          direction="row"
          width="1005px"
          height="max-content"
          m="40px 0"
          gap="16px"
        >
          {superAdmin && selectedClinic === null ? (
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
              color={theme.colors.green}
            >
              Select a clinic to get the reports
            </Text>
          ) : (
            <>
              <Col>
                <SalesCard>
                  <Row
                    width="100%"
                    align="center"
                    justify="space-between"
                    p="0 15px"
                  >
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.h4}
                      color={theme.colors.white}
                    >
                      Sales
                    </Text>

                    <Button
                      size={theme.fonts.size.default}
                      weight={theme.fonts.weight.semibold}
                      color={theme.colors.green}
                      background={theme.colors.green100}
                      border={theme.colors.green}
                      onClick={() => console.log("details")}
                      width="144px"
                    >
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.default}
                        color={theme.colors.green}
                      >
                        Details
                      </Text>
                    </Button>
                  </Row>
                  <Div
                    height="235px"
                    width="454px"
                    m="25px 0"
                    radius="20px"
                    background={theme.colors.gray950}
                    direction="column"
                  >
                    <Row m="21px 21px 0 21px" justify="space-between">
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.h4}
                        color={theme.colors.white}
                      >
                        {`${sumBy(salesList, "amount")} Sales`}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.h4}
                        color={theme.colors.green}
                      >
                        {formatPriceToDollar(sumBy(salesList, "value"))}
                      </Text>
                    </Row>
                    <AreaChart
                      width={454}
                      height={200}
                      data={salesList || []}
                      margin={{ top: 0, right: 12, left: 12, bottom: 0 }}
                    >
                      <defs>
                        <linearGradient
                          id="salesGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="0%"
                            stopColor={theme.colors.green}
                            stopOpacity={1}
                          />
                          <stop
                            offset="100%"
                            stopColor={theme.colors.green}
                            stopOpacity={0.2}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke={theme.colors.green}
                        fill={`url(#salesGradient)`}
                      />
                      <Tooltip content={<GraphTooltip />} />
                      <XAxis dataKey="date" tick={false} />
                    </AreaChart>
                  </Div>
                  {groupedSales?.map((salesGroup, idx) => {
                    const totalSales = salesGroup.reduce(
                      (acc, sale) => acc + sale.amount,
                      0
                    );
                    const totalValue = salesGroup.reduce(
                      (acc, sale) => acc + sale.value,
                      0
                    );
                    return (
                      <Div
                        key={idx}
                        height="57px"
                        width="454px"
                        m="0 0 10px 0"
                        p="0 21px 0 26px"
                        radius="12px"
                        background="rgba(141, 141, 141, 0.18)"
                      >
                        <Row
                          width="100%"
                          align="center"
                          justify="space-between"
                        >
                          <Text
                            weight={theme.fonts.weight.light}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                            style={{ width: "100px", textAlign: "left" }}
                          >
                            {selectedReportTime.value === 7
                              ? `Day ${idx + 1}`
                              : selectedReportTime.value === 365
                              ? `Month ${idx + 1}`
                              : `Week ${idx + 1}`}
                          </Text>
                          <Text
                            weight={theme.fonts.weight.regular}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                          >
                            {`${totalSales} sales`}
                          </Text>
                          <Text
                            weight={theme.fonts.weight.semibold}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                            style={{ width: "100px", "text-align": "right" }}
                          >
                            {formatPriceToDollar(totalValue)}
                          </Text>
                        </Row>
                      </Div>
                    );
                  })}
                </SalesCard>
                <Taxes
                  taxesList={taxesList}
                  groupedTaxesList={groupedTaxesList}
                  selectedReportTime={selectedReportTime}
                />
              </Col>
              <Col>
                <CostProfit
                  groupedCostProfits={groupedCostProfits}
                  selectedReportTime={selectedReportTime}
                />
                <TopSellers topSellersList={topSellersList} />
              </Col>
            </>
          )}
        </Div>
      ) : (
        <RegularUser
          selectedReportTime={selectedReportTime}
          groupItems={groupItems}
          payloadOptions={payloadOptions}
        />
      )}
    </Container>
  );
};
