import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Skeleton } from "antd";
import { useSelector } from "react-redux";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { Link } from "../../../components/Link";
import {
  ClinicIcon,
  PhoneIcon,
  EmailOpenedIcon,
  ChevronLeftIcon,
  DeleteIcon,
} from "../../../assets/icons";
import { theme } from "../../../styles/theme";
import { InfoCard, ContainerCard } from "./styles";
import DrawerPatient from "../componenets/DrawerPatient";
import useGetQuotesByPatient from "../hooks/useGetQuotesByPatient";
import QuoteWrapper from "../componenets/QuoteWrapper";
import { formatPhoneNumber, formattedDate } from "../../../utilities/helpers";

export const Patient = () => {
  const navigate = useNavigate();
  const showCreateQuoteButton = false;
  const { patients, common } = useSelector((state) => state);
  const patient = patients?.selectedPatient || {};
  const optionsClinics = common?.clinicsList || {};
  const optionsCodes = common?.codesList || {};

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [listQuotes, setListQuotes] = useState([]);

  const { isLoading } = useGetQuotesByPatient(patient?.id, {
    enabled: !!patient?.id,
    onSuccess: (apiData) => {
      setListQuotes(apiData.data.data);
    },
  });

  const initialValues = {
    id: patient?.id,
    email: patient?.email,
    name: patient?.name,
    lastname: patient?.lastname,
    callingCode: patient?.callingCode,
    phone: patient?.phone,
    clinicId: patient?.clinicId,
    clinicName: patient?.clinicName,
  };

  const [actualInfo, setActualInfo] = useState(initialValues);

  useEffect(() => {
    if (Object.keys(patient).length === 0) {
      navigate("/patients");
    }
    setActualInfo({
      id: patient?.id,
      email: patient?.email,
      name: patient?.name,
      lastname: patient?.lastname,
      callingCode: patient?.callingCode,
      phone: patient?.phone,
      clinicId: patient?.clinicId,
      clinicName: patient?.clinicName,
    });
  }, [patient]);

  const handleCloseDrawerSuccess = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <DrawerPatient
        showDrawer={showDrawer}
        closeDrawer={handleCloseDrawer}
        handleCloseDrawerSuccess={handleCloseDrawerSuccess}
        actualInfo={actualInfo}
        optionsClinics={optionsClinics}
        optionsCodes={optionsCodes}
      />

      <Row height="18px" align="center" m="0px 0px 37px 0px">
        <Link
          onClick={() => navigate(-1)}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ gap: "5px" }}
        >
          <ChevronLeftIcon stroke={theme.colors.green} />
          Go back
        </Link>
      </Row>
      <Row
        height="40px"
        align="center"
        m="0px 0px 37px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Patient Information
          </Text>
        </Div>
        {showCreateQuoteButton && (
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            width="226px"
            background={theme.colors.green}
            onClick={() => console.log("new quote")}
          >
            Create new quote
          </Button>
        )}
      </Row>
      <InfoCard>
        <Row m="0 0 40px 0" gap="24px">
          <Col width={"530px"}>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h4}
              color={theme.colors.white}
              style={{ "white-space": "nowrap" }}
            >
              {patient?.name} {patient?.lastname}
            </Text>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.gray500}
            >
              Creation date: {formattedDate(patient?.createdAt)}
            </Text>
          </Col>
          <Button
            width="189px"
            color={theme.colors.green}
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={() => console.log("delete")}
          >
            Delete Patient
          </Button>
          <Button
            width="189px"
            color={theme.colors.green}
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            background={theme.colors.green100}
            border={theme.colors.green}
            onClick={() => setShowDrawer(true)}
          >
            Edit Patient
          </Button>
        </Row>

        <Row width="100%" justify={"space-between"}>
          <ContainerCard>
            <Div
              height={"52px"}
              background={theme.colors.gray700}
              radius={"100%"}
              align={"center"}
              justify={"center"}
              style={{ "min-width": "52px" }}
            >
              <ClinicIcon
                stroke={theme.colors.white}
                width={"30px"}
                height={"30px"}
              />
            </Div>
            <Col width={"180px"}>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.green}
              >
                Clinic
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
                color={theme.colors.white}
                align={"left"}
                mt={"6px"}
                style={{ "white-space": "nowrap" }}
              >
                {patient?.clinicName}
              </Text>
            </Col>
          </ContainerCard>
          <ContainerCard>
            <Div
              width={"52px"}
              height={"52px"}
              background={theme.colors.gray700}
              radius={"100%"}
              align={"center"}
              justify={"center"}
            >
              <EmailOpenedIcon
                stroke={theme.colors.white}
                width={"30px"}
                height={"30px"}
              />
            </Div>
            <Col width={"180px"}>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.green}
              >
                Email
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
                color={theme.colors.white}
                align={"left"}
                mt={"6px"}
              >
                {patient?.email}
              </Text>
            </Col>
          </ContainerCard>
          <ContainerCard>
            <Div
              width={"52px"}
              height={"52px"}
              background={theme.colors.gray700}
              radius={"100%"}
              align={"center"}
              justify={"center"}
            >
              <PhoneIcon
                stroke={theme.colors.white}
                width={"30px"}
                height={"30px"}
              />
            </Div>
            <Col width={"180px"}>
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.green}
              >
                Phone Number
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
                color={theme.colors.white}
                align={"left"}
                mt={"6px"}
              >
                {patient?.callingCode} {formatPhoneNumber(patient?.phone)}
              </Text>
            </Col>
          </ContainerCard>
        </Row>
      </InfoCard>
      <Row justify="space-between" m="50px 0 32px 0" align="center">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          Quote History
        </Text>
        <Link
          onClick={() => {}}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ padding: 0 }}
          icon={<DeleteIcon stroke={theme.colors.green} />}
        >
          Delete selected
        </Link>
      </Row>
      {!isLoading && (
        <Div direction="column" gap="20px">
          {listQuotes.map((quote) => (
            <QuoteWrapper quoteInformation={quote} patient={patient} />
          ))}
        </Div>
      )}
    </Container>
  );
};
