import { useDrag, useDrop } from "react-dnd";
import { CategoryCard } from "../../styles";
import { Col, Text } from "../../../../styles/Common";
import { Link } from "../../../../components/Link";
import { theme } from "../../../../styles/theme";
import { EditIcon, DeleteIcon, MoveDropIcon } from "../../../../assets/icons";
import { Tooltip } from "antd";

const DraggableCategoryCard = ({
  setShowDrawer,
  setActualInfo,
  category,
  handleDrop,
  setShowModalDelete,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: { position: category.position, id: category.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    drop: (item) => {
      handleDrop(item.position, category.position, item.id, category.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <CategoryCard
      ref={(node) => drag(drop(node))}
      isDragging={isDragging}
      isOver={isOver}
    >
      <Col width="210px">
        <Text
          weight={theme.fonts.weight.light}
          size={theme.fonts.size.xs}
          color={theme.colors.gray400}
        >
          Category Name
        </Text>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.default}
          color={theme.colors.gray50}
        >
          {category.name.length > 30 ? (
            <Tooltip title={category.name}>{`${category.name.slice(
              0,
              30
            )}...`}</Tooltip>
          ) : (
            category.name
          )}
        </Text>
      </Col>
      <Col width="130px">
        <Text
          weight={theme.fonts.weight.light}
          size={theme.fonts.size.xs}
          color={theme.colors.gray400}
        >
          Type
        </Text>
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.default}
          color={theme.colors.gray50}
        >
          {category.category_type === 1 ? "Treatment" : "Product"}
        </Text>
      </Col>
      <Link
        onClick={() => {
          setActualInfo({
            ...category,
          });
          setShowDrawer(true);
        }}
      >
        <EditIcon stroke={theme.colors.gray50} />
      </Link>
      <Link
        onClick={() => {
          setActualInfo({
            ...category,
          });
          setShowModalDelete(true);
        }}
      >
        <DeleteIcon stroke={theme.colors.gray50} />
      </Link>
      <Link>
        <MoveDropIcon stroke={theme.colors.gray50} />
      </Link>
    </CategoryCard>
  );
};
export default DraggableCategoryCard;
