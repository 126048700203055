import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useEditProduct = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put(`/treatment-plan/${formData.id}`, formData);
  });
  return mutation;
};
export default useEditProduct;
