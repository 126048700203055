import React from "react";
import { Text, Div } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import { formatPriceToDollar } from "../../../../utilities/helpers";

export const GraphTooltip = ({ label, payload }) => {
  return (
    <Div
      background={theme.colors.gray300}
      radius="15px"
      direction="column"
      p="10px"
      style={{ border: `1px solid ${theme.colors.gray50}` }}
    >
      <Text color={theme.colors.gray50} size={theme.fonts.size.default}>
        {`${label}`}
      </Text>
      {payload.map((value, idx) => (
        <Text
          key={idx}
          color={theme.colors.green}
          size={theme.fonts.size.default}
        >
          {`${value.name}: ${formatPriceToDollar(value.value)}`}
        </Text>
      ))}
    </Div>
  );
};
