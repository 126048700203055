import { useQuery } from "react-query";
import { axiosClient } from "../../core/services/axiosInstance";

const useGetUserByRoles = (role, clinicId) => {
  let urlString = `/users/role`;

  if (role.length === 4) {
    urlString += `?role=${role[0]}&role=${role[1]}&role=${role[2]}&role=${role[3]}`;
  } else if (role.length === 3) {
    urlString += `?role=${role[0]}&role=${role[1]}&role=${role[2]}`;
  } else if (role.length === 2) {
    urlString += `?role=${role}&role=${role[1]}`;
  } else {
    urlString += `?role=${role}`;
  }

  if (clinicId) {
    urlString += `&clinicId=${clinicId}`;
  }

  const data = useQuery(["getUserByRoles", role, clinicId], () =>
    axiosClient.get(urlString)
  );
  return data || {};
};
export default useGetUserByRoles;
