import styled from "styled-components";
import { Radio } from "antd";
import { theme } from "../../styles/theme";

export const AntRadio = styled(Radio)`
  margin: ${({ m }) => m || "0 0 0 0"};

  /* Override Ant Styles */
  && {
    &.ant-radio-wrapper {
      &:hover {
        & .ant-radio {
          &:not(.ant-radio-disabled):not(.ant-radio-checked) {
            .ant-radio-inner {
              border-color: ${theme.colors.gray800};
              background-color: ${theme.colors.green};
            }
          }
        }
      }

      &-disabled {
        .ant-radio {
          & + span {
            color: ${theme.colors.gray200};
          }
        }
      }
    }

    .ant-radio {
      .ant-radio-inner {
        border-width: 2px;
        border-color: ${(props) =>
          props.error ? theme.colors.red : theme.colors.gray400};
      }

      &-checked {
        .ant-radio-inner {
          border-color: ${theme.colors.green};
          background-color: ${({ background }) =>
            background || theme.colors.gray800};

          &:after {
            background-color: ${theme.colors.green};
          }
        }
      }

      & + span {
        color: ${theme.colors.gray800};
        font-family: ${theme.fonts.family};
      }
    }

    .ant-radio:not(.ant-radio-checked) .ant-radio-inner {
      border-color: ${theme.colors.green};
      background-color: ${({ background }) =>
        background || theme.colors.gray800};
    }
  }
`;
