import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPatientsByClinicId = (clinicId) => {
  const data = useQuery(
    ["getPatientsByClinicId", clinicId],
    () => clinicId && axiosClient.get(`/patients/clinic/${clinicId}`)
  );
  return data;
};
export default useGetPatientsByClinicId;
