import { Outlet } from "react-router-dom";
import "../styles/App.css";
import { useAuth } from "../core/hooks/useAuth";
import { Layout } from "../components/Layout";

const App = () => {
  const { isAuth } = useAuth();
  return (
    <section id="app" className="App">
      {isAuth ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <main className="main">
          <Outlet />
        </main>
      )}
    </section>
  );
};

export { App };
