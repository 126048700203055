import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPackages = ({ page, pageSize }) => {
  let urlString = `/packages?page=${page}&size=${pageSize}`;

  const data = useQuery(["packagestList", page, pageSize], () =>
    axiosClient.get(urlString)
  );

  return data || {};
};
export default useGetPackages;
