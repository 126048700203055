import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const ClinicCard = styled.div`
  display: flex;
  align-items: center;
  height: 531px;
  width: 324px;
  margin: 0px 16px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;

export const InfoCard = styled.div`
  display: flex;
  align-items: center;
  height: 531px;
  width: 663px;
  margin: 0px 16px 0px 0px;
  background: ${theme.colors.gray800} 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
`;
``;
export const LogoCard = styled.div`
  height: 130px;
  width: 177px;
  padding: 40px 0px 40px 0px;
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.gray700} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: 22px;
  opacity: 1;
  cursor: pointer;
`;
