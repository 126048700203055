import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const usePayQuoteById = () => {
  const mutation = useMutation(({ formData, quoteId }) => {
    return axiosClient.post(`/quote/${quoteId}/pay`, formData);
  });
  return mutation;
};
export default usePayQuoteById;
