import React from "react";
import { CustomSwitch } from "./styles";

export const Switch = ({ background, ...props }) => {
  return (
    <div>
      <CustomSwitch background={background} {...props} />
    </div>
  );
};
