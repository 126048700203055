import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useEditUser = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put(`/user/${formData.id}`, formData);
  });
  return mutation;
};
export default useEditUser;
