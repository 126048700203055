import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetTreatments = ({
  page,
  pageSize,
  sortDirection,
  sortBy,
  searchBy,
  clinicId,
  categoryId,
}) => {
  let urlString = `/products-treatments?filter=treatment&page=${page}&size=${pageSize}`;
  if (searchBy) {
    urlString += `&searchBy=${searchBy}`;
  }

  if (sortDirection && sortDirection.length > 1) {
    urlString += `&orderType=${sortDirection}`;
  }
  if (sortBy && sortBy.length > 1) {
    urlString += `&orderBy=${sortBy}`;
  }
  if (clinicId) {
    urlString += `&clinicId=${clinicId}`;
  }
  if (categoryId) {
    urlString += `&categoryId=${categoryId}`;
  }
  const data = useQuery(
    [
      "treatmentList",
      page,
      pageSize,
      sortDirection,
      sortBy,
      searchBy,
      clinicId,
      categoryId,
    ],
    () => axiosClient.get(urlString)
  );

  return data || {};
};
export default useGetTreatments;
