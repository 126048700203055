import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeletePackage = () => {
  const mutation = useMutation((packageId) => {
    return axiosClient.delete(`/package/${packageId}`);
  });
  return mutation;
};
export default useDeletePackage;
