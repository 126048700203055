import React from "react";
import { Divider } from "antd";
import { StyledDiscountCard } from "./styles";
import { Div, Row, Text } from "../../../../styles/Common";
import { theme } from "../../../../styles/theme";
import {
  DeleteIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "../../../../assets/icons";

import { Select } from "../../../../components/Select";
import { Radio } from "../../../../components/Radio";
import { Button } from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { Link } from "../../../../components/Link";

const DiscountCard = ({
  item,
  index,
  handleDelete,
  isOpen,
  catName,
  catList,
  handleDiscountType,
  handleSave,
  handleCancel,
  handleOpenCard,
  handleChangeCat,
  handleAddDiscountType,
  handleChangeInput,
  handleRemoveItemDiscount,
  handleChangeName,
}) => {
  const amountArray = item.details.filter((ele) => ele.type === "amount");
  const quantityArray = item.details.filter(
    (ele) => ele.type === "quantity_products"
  );
  return (
    <StyledDiscountCard>
      <Row justify="space-between">
        <Div direction="column" gap="4px">
          {isOpen ? (
            <Input
              id="name"
              color={theme.colors.gray400}
              width={"100%"}
              border={theme.colors.gray800}
              value={item.name || `Discount ${index}`}
              onChange={(event) => handleChangeName(event, item.id)}
              background={theme.colors.gray600}
            />
          ) : (
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.gray50}
            >
              {item.name || `Discount ${index}`}
            </Text>
          )}
          <Text
            weight={theme.fonts.weight.light}
            size={theme.fonts.size.xs}
            color={theme.colors.gray400}
          >
            {catName}
          </Text>
        </Div>
        <Div gap={"16px"}>
          <Link onClick={() => handleDelete(item.id)}>
            <DeleteIcon stroke={theme.colors.gray50} />
          </Link>
          {isOpen ? (
            <Link onClick={() => handleOpenCard(item.id)}>
              <ChevronUpIcon fill={theme.colors.gray50} />
            </Link>
          ) : (
            <Link onClick={() => handleOpenCard(item.id)}>
              <ChevronDownIcon stroke={theme.colors.gray50} />
            </Link>
          )}
        </Div>
      </Row>
      {isOpen && (
        <Div direction="column" gap="24px">
          <Divider
            style={{
              height: "1px",
              backgroundColor: theme.colors.gray600,
              margin: 0,
            }}
          />
          <Row gap="24px" align="center">
            <Select
              label={"Category"}
              id="Category"
              options={catList}
              onChange={(val) => handleChangeCat(val, item.id)}
              value={item.categoryId ? item.categoryId.toString() : null}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"249px"}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
            />
            <Div direction="column" gap="24px" align="start" height="100%">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.sm}
                color={theme.colors.gray500}
              >
                Discount Type
              </Text>
              <Radio.Group
                name="bonusType"
                value={item.type}
                key={item.type}
                onChange={(event) => handleDiscountType(event, item.id)}
                gap={"15px"}
                direction="row"
              >
                <Radio value="dollar" background={theme.colors.gray600} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Dollar
                </Text>
                <Radio value="percentage" background={theme.colors.gray600} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Percentage
                </Text>
              </Radio.Group>
            </Div>
          </Row>
          <Row>
            <Div direction="column" gap="10px">
              {quantityArray.map((detail, index) => (
                <Div gap="23px" key={index} align="center">
                  <Input
                    id="amount"
                    label="# Products"
                    prefix={"["}
                    color={theme.colors.gray400}
                    width={"186px"}
                    border={theme.colors.gray800}
                    value={detail.amount}
                    type="number"
                    onChange={(event) =>
                      handleChangeInput(event, detail.idDetail, item.id)
                    }
                    background={theme.colors.gray600}
                  />
                  <Input
                    id="value"
                    label="Discount"
                    prefix={item.type === "dollar" && "$"}
                    suffix={item.type === "percentage" && "%"}
                    color={theme.colors.gray400}
                    width={"132px"}
                    type="number"
                    border={theme.colors.gray800}
                    value={detail.discount}
                    onChange={(event) =>
                      handleChangeInput(event, detail.idDetail, item.id)
                    }
                    background={theme.colors.gray600}
                  />
                  {quantityArray.length === index + 1 ? (
                    <Button
                      onClick={() =>
                        handleAddDiscountType(item.id, "quantity_products")
                      }
                      width="150px"
                      height="50px"
                      color={theme.colors.green}
                      weight={theme.fonts.weight.semibold}
                      background={theme.colors.green100}
                      border={theme.colors.green}
                      m="20px 0 0 0"
                    >
                      Add Discount
                    </Button>
                  ) : (
                    <Link
                      m="20px 0 0 0"
                      onClick={() =>
                        handleRemoveItemDiscount(detail.idDetail, item.id)
                      }
                    >
                      <DeleteIcon stroke={theme.colors.gray50} />
                    </Link>
                  )}
                </Div>
              ))}
            </Div>
          </Row>
          <Row>
            <Div direction="column" gap="10px">
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h5}
                color={theme.colors.gray50}
              >
                Amount discount
              </Text>
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.regular}
                color={theme.colors.gray50}
              >
                Add amount-based discounts, you can define an amount and apply
                discounts
              </Text>
            </Div>
            <Div direction="column" gap="24px" align="start" height="100%">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.sm}
                color={theme.colors.gray500}
              >
                Discount Type
              </Text>
              <Radio.Group
                name="bonusTypeAmount"
                value={item.type_amount}
                key={item.type_amount}
                onChange={(event) => handleDiscountType(event, item.id)}
                gap={"15px"}
                direction="row"
              >
                <Radio value="dollar" background={theme.colors.gray600} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Dollar
                </Text>
                <Radio value="percentage" background={theme.colors.gray600} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Percentage
                </Text>
              </Radio.Group>
            </Div>
          </Row>
          <Row>
            <Div direction="column" gap="10px">
              {amountArray.map((detail, index) => (
                <Div gap="23px" key={index} align="center">
                  <Input
                    id="amount"
                    label="Amount"
                    prefix={"[ $"}
                    type="number"
                    color={theme.colors.gray400}
                    width={"186px"}
                    border={theme.colors.gray800}
                    value={detail.amount}
                    onChange={(event) =>
                      handleChangeInput(event, detail.idDetail, item.id)
                    }
                    background={theme.colors.gray600}
                  />
                  <Input
                    id="value"
                    label="Discount"
                    type="number"
                    prefix={item.type_amount === "dollar" && "$"}
                    suffix={item.type_amount === "percentage" && "%"}
                    color={theme.colors.gray400}
                    value={detail.discount}
                    width={"132px"}
                    border={theme.colors.gray800}
                    onChange={(event) =>
                      handleChangeInput(event, detail.idDetail, item.id)
                    }
                    background={theme.colors.gray600}
                  />
                  {amountArray.length === index + 1 ? (
                    <Button
                      onClick={() => handleAddDiscountType(item.id, "amount")}
                      width="150px"
                      height="50px"
                      color={theme.colors.green}
                      weight={theme.fonts.weight.semibold}
                      background={theme.colors.green100}
                      border={theme.colors.green}
                      m="20px 0 0 0"
                    >
                      Add Discount
                    </Button>
                  ) : (
                    <Link
                      m="20px 0 0 0"
                      onClick={() =>
                        handleRemoveItemDiscount(detail.idDetail, item.id)
                      }
                    >
                      <DeleteIcon stroke={theme.colors.gray50} />
                    </Link>
                  )}
                </Div>
              ))}
            </Div>
          </Row>
          <Row gap="10px">
            <Button
              onClick={() => handleSave(item.id)}
              width="119px"
              height="50px"
              color={theme.colors.green}
              weight={theme.fonts.weight.semibold}
              background={theme.colors.green100}
              border={theme.colors.green}
            >
              Save
            </Button>
            <Button
              onClick={() => handleCancel(item.id)}
              width="119px"
              height="50px"
              background={theme.colors.green}
            >
              Cancel
            </Button>
          </Row>
        </Div>
      )}
    </StyledDiscountCard>
  );
};

export default DiscountCard;
