import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useArchiveUser = () => {
  const mutation = useMutation((userId) => {
    return axiosClient.post(`/user/disable/${userId}`);
  });
  return mutation;
};
export default useArchiveUser;
