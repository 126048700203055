import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useEditQuote = () => {
  const mutation = useMutation(({ formData, quoteId }) => {
    return axiosClient.put(`/quote/${quoteId}`, formData);
  });
  return mutation;
};
export default useEditQuote;
