import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Row, Div, Text } from "../../styles/Common";
import { Button } from "../../components/Button";
import { Skeleton } from "../../components/Skeleton";
import Table from "../../components/Table";
import { theme } from "../../styles/theme";
import {
  ArrawRightLeftIcon,
  DeleteIcon,
  EditIcon,
  FolderIcon,
} from "../../assets/icons";
import useGetPackages from "./hooks/useGetPackages";
import { Link } from "../../components/Link";
import { formattedDate, isSuperAdmin } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import { setSelectedPackageInformation } from "../../core/store/packages";
import ModalChangeState from "./components/ModalChangeState";
import ModalDelete from "./components/ModalDelete";
import { StatusMark, statusColor } from "./styles";
import { capitalize } from "lodash";

export const Packages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });

  const [totalItems, setTotalItems] = useState(10);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
  });

  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selectedPackageId, setSelectedPackageId] = useState(null);

  const getPackages = useGetPackages(payloadOptions);
  const { data, isLoading, isFetching } = getPackages;
  const [packagesList, setPackagesList] = useState([]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      const dataTable = data?.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-change`,
            id: ele.id,
            icon: (
              <ArrawRightLeftIcon
                stroke={theme.colors.gray50}
                width={"20px"}
                height={"20px"}
              />
            ),
            onClick: (row) => {
              dispatch(setSelectedPackageInformation(row));
              setShowModal(true);
            },
          },
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: <EditIcon stroke={theme.colors.gray50} />,
            onClick: (row) => {
              dispatch(setSelectedPackageInformation(row));
              navigate("edit-package");
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: <DeleteIcon stroke={theme.colors.gray50} />,
            onClick: (row) => {
              setSelectedPackageId(row.id);
              setShowModalDelete(true);
            },
          },
        ],
      }));
      setPackagesList(dataTable);
      setTotalItems(data.data.data.total);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
    });
  }, [pages]);

  const handleChangeTable = (pagination, filter, sort) => {
    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setForceUpdate(!forceUpdate);
  };

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: "Creation Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 100,
        render: (value, item) => `${formattedDate(value)}`,
      },
      {
        title: "Clinic Name",
        dataIndex: "clinicName",
        key: "clinicName",
        width: 100,
        sorter: false,
        showSorterTooltip: false,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 100,
        sorter: false,
        showSorterTooltip: false,
      },
      {
        title: "Qty Products",
        dataIndex: "quantityProducts",
        key: "quantityProducts",
        width: 90,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => (
          <Text
            color={theme.colors.white}
            size={theme.fonts.size.sm}
            weight={theme.fonts.weight.medium}
            align={"center"}
          >
            {value}
          </Text>
        ),
      },
      {
        title: "Qty Treatments",
        dataIndex: "quantityTreatments",
        key: "quantityTreatments",
        width: 90,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => (
          <Text
            color={theme.colors.white}
            size={theme.fonts.size.sm}
            weight={theme.fonts.weight.medium}
            align={"center"}
          >
            {value}
          </Text>
        ),
      },
      {
        title: "Expiration Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: 90,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => ` ${formattedDate(value)}`,
      },
      {
        title: "Status",
        dataIndex: "state",
        key: "state",
        width: 80,
        sorter: false,
        showSorterTooltip: false,

        render: (value) =>
          value ? (
            <Row gap="5px" align="center">
              <StatusMark status={value} />
              <Text color={statusColor(value)}>{capitalize(value)}</Text>{" "}
            </Row>
          ) : (
            ""
          ),
      },

      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (actions, row) => {
          return (
            <Div gap="10px">
              {actions &&
                actions.map((action, i) => (
                  <Link
                    key={i}
                    onClick={() => action.onClick(row)}
                    color={theme.colors.gray50}
                    icon={action.icon}
                    styles={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  />
                ))}
            </Div>
          );
        },
      },
    ];

    return columns;
  }, [packagesList]);

  const handleCloseModal = () => {
    dispatch(setSelectedPackageInformation({}));
    setSelectedPackageId(null);
    setShowModalDelete(false);
    setShowModal(false);
  };

  return (
    <Div height="max-content" width="1070px" direction="column">
      <ModalChangeState
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      <ModalDelete
        showModal={showModalDelete}
        handleCloseModal={handleCloseModal}
        selectedPackageId={selectedPackageId}
      />
      <Row
        height="40px"
        justify="space-between"
        align="center"
        m={isSuperAdmin() ? "0px 0px 20px 0px" : "0px 0px 37px 0px"}
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Packages
          </Text>
        </Div>

        <Div gap="10px" align="center">
          <Button
            width="211px"
            background={theme.colors.green}
            onClick={() => navigate("create-package")}
          >
            <Row width="100%" justify="center">
              <Div gap={"8px"} align={"center"}>
                <FolderIcon />
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.default}
                  color={theme.colors.white}
                >
                  Create Package
                </Text>
              </Div>
            </Row>
          </Button>
        </Div>
      </Row>

      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={packagesList}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "packages", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Div>
  );
};
