import { useQuery } from "react-query";
import { axiosClient } from "../services/axiosInstance";

const useParameters = (param, options, clinicId) => {
  const getOptions = options || {};
  const data = useQuery(
    ["params", param, clinicId],
    () =>
      axiosClient.get(
        clinicId
          ? `parametro?clinicId=${clinicId}&opts=${param}`
          : `parametro?opts=${param}`
      ),
    getOptions
  );
  return data;
};
export default useParameters;
