import { useMutation } from "react-query";
import { axiosClient } from "../../../../core/services/axiosInstance";

const useEditProfile = () => {
  const mutation = useMutation((formData) => {
    return axiosClient.put(`/profile/${formData.id}`, formData);
  });
  return mutation;
};
export default useEditProfile;
