import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useDeleteDiscount = () => {
  const mutation = useMutation((discountId) => {
    return axiosClient.delete(`/discount/${discountId}`);
  });
  return mutation;
};
export default useDeleteDiscount;
